import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";
import { errorToast } from "../utils/ToasterUtils";

const GlobalMedicineSlice = createSlice({
  name: "GlobalMedicine",
  initialState: [],
  reducers: {
    setGlobalMedicine(state, action) {
      return (state = action.payload);
    },
    setInitialGlobalMedicine(state, action) {
      return (state = action.payload);
    },
  },
});

const { setGlobalMedicine, setInitialGlobalMedicine } =
  GlobalMedicineSlice.actions;
export default GlobalMedicineSlice.reducer;

export function serachMedicine() {
  return async function serachMedicineThunk(dispatch, getState) {
    try {
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/medicine/global/medicinedetails/selectall";

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            // const data = response.data.data;
            var data = response.data.data;
            data.forEach(object => {
              object.buydate = null;
              object.expiredate = null;
            });
            dispatch(setGlobalMedicine(data));
          }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              window.location.replace("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
            errorToast(err.response.data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}

export function setInitGlobalMedFunc() {
  return async function setInitGlobalMedFuncThunk(dispatch, getState) {
    try {
      dispatch(setInitialGlobalMedicine([]));
    } catch (err) {
      console.log(err);
    }
  };
}
