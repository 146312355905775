import { Divider } from "@mui/material";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import { errorToast, successToast, warnToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import "./ScheduleMedicine.css";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../utils/Loader";
import DatePicker from "react-datepicker";
import Cookie from "js-cookie";
import { timeZonecountries } from "../../utils/data";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import ReactDatePicker from "react-multi-date-picker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function ScheduleMedicine() {
  //language Variable start
  const [text_current_med_schedule, setText_current_med_schedule] = useState(
    "Current Medicine Schedule"
  );
  const [text_select_medicine, setText_select_medicine] =
    useState("Select Medicine");
  const [text_medicine_schedule, setText_medicine_schedule] =
    useState("Medicine Schedule");
  const [text_schedule_of_med, setText_schedule_of_med] = useState(
    "Schedule of Medicine"
  );
  const [text_as_needed, setText_as_needed] = useState("As Needed");
  const [text_start_date, setText_start_date] = useState("Start Date");
  const [text_end_date, setText_end_date] = useState("End Date");
  const [text_timezone, setText_timezone] = useState("TimeZone");
  const [text_morning, setText_morning] = useState("Morning");
  const [text_afternoon, setText_afternoon] = useState("Afternoon");
  const [text_evening, setText_evening] = useState("Evening");
  const [text_night, setText_night] = useState("Night");
  const [text_add_time, setText_add_time] = useState("Add time");
  const [text_monday, setText_monday] = useState("Monday");
  const [text_tuesday, setText_tuesday] = useState("Tuesday");
  const [text_wednesday, setText_wednesday] = useState("Wednesday");
  const [text_thursday, setText_thursday] = useState("Thursday");
  const [text_friday, setText_friday] = useState("Friday");
  const [text_saturday, setText_saturday] = useState("Saturday");
  const [text_sunday, setText_sunday] = useState("Sunday");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_save, setBtn_save] = useState("Save");
  const [text_add_medicine_first, setText_add_medicine_first] = useState(
    "Add Your Medicine First"
  );
  const [text_err_select_medicine, setText_err_select_medicine] = useState(
    "Select Any Medicine"
  );
  const [text_err_select_any_one, setText_err_select_any_one] = useState(
    "Please Select Any One"
  );
  const [text_select_time, setText_select_time] = useState("Select Time");
  const [text_timing, setText_timing] = useState("Timing");
  const [text_dose, setText_dose] = useState("Dose/Puff");
  const [text_number_of_puff_dose, setText_number_of_puff_dose] = useState(
    "Number of Puff/Dose"
  );
  const [text_schedule_name, setText_schedule_name] = useState("Schedule Name");
  const [text_new_med_schedule, setText_new_med_schedule] = useState(
    "New Medicine Schedule"
  );
  //language Variable end
  const data = useLocation();
  const navigate = useNavigate();
  const DeviceData = useLocation();
  const MedicineName =
    data.state !== null || data.state !== "" ? data.state.data.Genericname : "";
  const MedicineId =
    data.state !== null || data.state !== "" ? data.state.data : "";
  const userID = sessionStorage.getItem("user_id");
  const [asNeeded, setAsNeeded] = useState(false);

  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [allMedicine, setAllMedicine] = useState([]);
  //TimeZone
  var timezones = timeZonecountries[0];
  const FinalData = timezones;
  const [selectedTimeZone, setSelectedTimeZone] = useState(FinalData[0]?.utc);
  //day active variable
  const [isMonActive, setisMonActive] = useState(false);
  const [isTueActive, setisTueActive] = useState(false);
  const [isWedActive, setisWedActive] = useState(false);
  const [isThuActive, setisThuActive] = useState(false);
  const [isFriActive, setisFriActive] = useState(false);
  const [isSatActive, setisSatActive] = useState(false);
  const [isSunActive, setisSunActive] = useState(false);
  const [errMonTimeselect, seterrMonTimeselect] = useState("");
  const [errTueTimeselect, seterrTueTimeselect] = useState("");
  const [errWedTimeselect, seterrWedTimeselect] = useState("");
  const [errThuTimeselect, seterrThuTimeselect] = useState("");
  const [errFriTimeselect, seterrFriTimeselect] = useState("");
  const [errSatTimeselect, seterrSatTimeselect] = useState("");
  const [errSunTimeselect, seterrSunTimeselect] = useState("");
  const [errSelectOne, setErrSelectOne] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  //days Dose
  const [MonmorningDose, setMonmorningDose] = useState("1");
  const [MonafternoonDose, setMonafternoonDose] = useState("1");
  const [MoneveningDose, setMoneveningDose] = useState("1");
  const [MonnightDose, setMonninghtDose] = useState("1");
  const [TuemorningDose, setTuemorningDose] = useState("1");
  const [TueafternoonDose, setTueafternoonDose] = useState("1");
  const [TueeveningDose, setTueeveningDose] = useState("1");
  const [TuenightDose, setTuenightDose] = useState("1");
  const [WedmorningDose, setWedmorningDose] = useState("1");
  const [WedafternoonDose, setWedafternoonDose] = useState("1");
  const [WedeveningDose, setWedeveningDose] = useState("1");
  const [WednightDose, setWednightDose] = useState("1");
  const [ThumorningDose, setThumorningDose] = useState("1");
  const [ThuafternoonDose, setThuafternoonDose] = useState("1");
  const [ThueveningDose, setThueveningDose] = useState("1");
  const [ThunightDose, setThunightDose] = useState("1");
  const [FrimorningDose, setFrimorningDose] = useState("1");
  const [FriafternoonDose, setFriafternoonDose] = useState("1");
  const [FrieveningDose, setFrieveningDose] = useState("1");
  const [FrinightDose, setFrinightDose] = useState("1");
  const [SatmorningDose, setSatmorningDose] = useState("1");
  const [SatafternoonDose, setSatafternoonDose] = useState("1");
  const [SateveningDose, setSateveningDose] = useState("1");
  const [SatnightDose, setSatnightDose] = useState("1");
  const [SunmorningDose, setSunmorningDose] = useState("1");
  const [SunafternoonDose, setSunafternoonDose] = useState("1");
  const [SuneveningDose, setSuneveningDose] = useState("1");
  const [SunnightDose, setSunnightDose] = useState("1");
  const [scheduleName, setScheduleName] = useState("");

  //Morning,Afternoon,evening checkbox variable
  const [monMorningCheck, setMonMorningcheck] = useState(false);
  const [tueMorningCheck, setTueMorningcheck] = useState(false);
  const [wedMorningCheck, setWedMorningcheck] = useState(false);
  const [thuMorningCheck, setThuMorningcheck] = useState(false);
  const [friMorningCheck, setFriMorningcheck] = useState(false);
  const [satMorningCheck, setSatMorningcheck] = useState(false);
  const [sunMorningCheck, setSunMorningcheck] = useState(false);

  const [monAfternoonCheck, setMonAfternooncheck] = useState(false);
  const [tueAfternoonCheck, setTueAfternooncheck] = useState(false);
  const [wedAfternoonCheck, setWedAfternooncheck] = useState(false);
  const [thuAfternoonCheck, setThuAfternooncheck] = useState(false);
  const [friAfternoonCheck, setFriAfternooncheck] = useState(false);
  const [satAfternoonCheck, setSatAfternooncheck] = useState(false);
  const [sunAfternoonCheck, setSunAfternooncheck] = useState(false);

  const [monEveningCheck, setMonEveningcheck] = useState(false);
  const [tueEveningCheck, setTueEveningcheck] = useState(false);
  const [wedEveningCheck, setWedEveningcheck] = useState(false);
  const [thuEveningCheck, setThuEveningcheck] = useState(false);
  const [friEveningCheck, setFriEveningcheck] = useState(false);
  const [satEveningCheck, setSatEveningcheck] = useState(false);
  const [sunEveningCheck, setSunEveningcheck] = useState(false);

  const [monNightCheck, setMonNightcheck] = useState(false);
  const [tueNightCheck, setTueNightcheck] = useState(false);
  const [wedNightCheck, setWedNightcheck] = useState(false);
  const [thuNightCheck, setThuNightcheck] = useState(false);
  const [friNightCheck, setFriNightcheck] = useState(false);
  const [satNightCheck, setSatNightcheck] = useState(false);
  const [sunNightCheck, setSunNightcheck] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const handleMonCheck = (e) => {
    if (e.target.checked === true) {
      setisMonActive(true);
      setMonMorningcheck(true);
      setMonAfternooncheck(true);
      setMonEveningcheck(true);
      setMonNightcheck(true);
    } else {
      setisMonActive(false);
      setMonMorningcheck(false);
      setMonAfternooncheck(false);
      setMonEveningcheck(false);
      setMonNightcheck(false);
    }
  };

  const handleTueCheck = (e) => {
    if (e.target.checked === true) {
      setisTueActive(true);
      setTueMorningcheck(true);
      setTueAfternooncheck(true);
      setTueEveningcheck(true);
      setTueNightcheck(true);
    } else {
      setisTueActive(false);
      setTueMorningcheck(false);
      setTueAfternooncheck(false);
      setTueEveningcheck(false);
      setTueNightcheck(false);
    }
  };
  const handleWedCheck = (e) => {
    if (e.target.checked === true) {
      setisWedActive(true);
      setWedMorningcheck(true);
      setWedAfternooncheck(true);
      setWedEveningcheck(true);
      setWedNightcheck(true);
    } else {
      setisWedActive(false);
      setWedMorningcheck(false);
      setWedAfternooncheck(false);
      setWedEveningcheck(false);
      setWedNightcheck(false);
    }
  };
  const handleThuCheck = (e) => {
    if (e.target.checked === true) {
      setisThuActive(true);
      setThuMorningcheck(true);
      setThuAfternooncheck(true);
      setThuEveningcheck(true);
      setThuNightcheck(true);
    } else {
      setisThuActive(false);
      setThuMorningcheck(false);
      setThuAfternooncheck(false);
      setThuEveningcheck(false);
      setThuNightcheck(false);
    }
  };
  const handleFriCheck = (e) => {
    if (e.target.checked === true) {
      setisFriActive(true);
      setFriMorningcheck(true);
      setFriAfternooncheck(true);
      setFriEveningcheck(true);
      setFriNightcheck(true);
    } else {
      setisFriActive(false);
      setFriMorningcheck(false);
      setFriAfternooncheck(false);
      setFriEveningcheck(false);
      setFriNightcheck(false);
    }
  };
  const handleSatCheck = (e) => {
    if (e.target.checked === true) {
      setisSatActive(true);
      setSatMorningcheck(true);
      setSatAfternooncheck(true);
      setSatEveningcheck(true);
      setSatNightcheck(true);
    } else {
      setisSatActive(false);
      setSatMorningcheck(false);
      setSatAfternooncheck(false);
      setSatEveningcheck(false);
      setSatNightcheck(false);
    }
  };
  const handleSunCheck = (e) => {
    if (e.target.checked === true) {
      setisSunActive(true);
      setSunMorningcheck(true);
      setSunAfternooncheck(true);
      setSunEveningcheck(true);
      setSunNightcheck(true);
    } else {
      setisSunActive(false);
      setSunMorningcheck(false);
      setSunAfternooncheck(false);
      setSunEveningcheck(false);
      setSunNightcheck(false);
    }
  };

  const DeviceMedicineDetail =
    DeviceData?.state?.deviceID === undefined
      ? false
      : DeviceData?.state?.deviceID;
  console.log(DeviceMedicineDetail);

  const [MonMorningarr, setMonMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [MonAfternoonarr, setMonAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [MonEveningarr, setMonEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [MonNightarr, setMonNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [tueMorningarr, settueMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [tueAfternoonarr, settueAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [tueEveningarr, settueEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [tueNightarr, settueNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [wedMorningarr, setwedMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [wedAfternoonarr, setwedAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [wedEveningarr, setwedEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [wedNightarr, setwedNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [thuMorningarr, setthuMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [thuAfternoonarr, setthuAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [thuEveningarr, setthuEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [thuNightgarr, setthuNightgArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [friMorningarr, setfriMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [friAfternoonarr, setfriAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [friEveningarr, setfriEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [friNightarr, setfriNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [satMorningarr, setsatMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [satAfternoonarr, setsatAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [satEveningarr, setsatEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [satNightarr, setsatNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );
  const [sunMorningarr, setsunMorningArr] = useState(
    setHours(setMinutes(new Date(), 0), 8)
  );
  const [sunAfternoonarr, setsunAfternoonArr] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [sunEveningarr, setsunEveningArr] = useState(
    setHours(setMinutes(new Date(), 0), 17)
  );
  const [sunNightarr, setsunNightArr] = useState(
    setHours(setMinutes(new Date(), 0), 22)
  );

  console.log(selectedMedicine);
  const getmedicinename = () => {
    setIsLoading(true);
    if (
      selectedMedicine === "" ||
      selectedMedicine === null ||
      selectedMedicine === undefined
    ) {
      console.log(selectedMedicine);
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/medicine/addmedicine/select?_id=" +
        selectedMedicine;
      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          console.log("medicine res***", res?.data?.data.Genericname);
          var value = res.data.data._schedule_Id;
          var data = res?.data?.data.Genericname;
          setIsLoading(false);
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            setIsLoading(false);
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
            errorToast(err.response.data.message);
          }
        });
    }
  };
  useEffect(() => {
    getmedicinename();
  }, [selectedMedicine]);

  // Schedule validation
  const scheduleValidation = () => {
    let scheduleData = true;

    if (
      asNeeded === false &&
      isMonActive === false &&
      isTueActive === false &&
      isWedActive === false &&
      isThuActive === false &&
      isFriActive === false &&
      isSatActive === false &&
      isSunActive === false
    ) {
      scheduleData = false;
      setErrSelectOne(<>{text_err_select_any_one}!</>);
    }
    if (isMonActive === true) {
      if (
        monMorningCheck === false &&
        monAfternoonCheck === false &&
        monEveningCheck === false &&
        monNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isTueActive === true) {
      if (
        tueMorningCheck === false &&
        tueAfternoonCheck === false &&
        tueEveningCheck === false &&
        tueNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isWedActive === true) {
      if (
        wedMorningCheck === false &&
        wedAfternoonCheck === false &&
        wedEveningCheck === false &&
        wedNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isThuActive === true) {
      if (
        thuMorningCheck === false &&
        thuAfternoonCheck === false &&
        thuEveningCheck === false &&
        thuNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isFriActive === true) {
      if (
        friMorningCheck === false &&
        friAfternoonCheck === false &&
        friEveningCheck === false &&
        friNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isSatActive === true) {
      if (
        satMorningCheck === false &&
        satAfternoonCheck === false &&
        satEveningCheck === false &&
        satNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    if (isSunActive === true) {
      if (
        sunMorningCheck === false &&
        sunAfternoonCheck === false &&
        sunEveningCheck === false &&
        sunNightCheck === false
      ) {
        // MonAfternoonarr[0].dateTime === ""
        scheduleData = false;
        // seterrMonTimeselect("*Please select Time First!");
        setErrSelectOne(<>{text_err_select_any_one}!</>);
      }
    }
    setBtnDisable(false);
    return scheduleData;
  };
  // console.log(MonMorningarr[0].Time);
  var monArr = [];
  var tueArr = [];
  var wedArr = [];
  var thuArr = [];
  var friArr = [];
  var satArr = [];
  var sunArr = [];
  useEffect(() => {
    monArr.push({
      monMorningTime: monMorningCheck
        ? moment(new Date(MonMorningarr).getTime()).format("hh:mm a")
        : "",
      monAfternoonTime: monAfternoonCheck
        ? moment(new Date(MonAfternoonarr).getTime()).format("hh:mm a")
        : "",
      monEveningTime: monEveningCheck
        ? moment(new Date(MonEveningarr).getTime()).format("hh:mm a")
        : "",
      monNightTime: monNightCheck
        ? moment(new Date(MonNightarr).getTime()).format("hh:mm a")
        : "",
    });
    tueArr.push({
      tueMorningTime: tueMorningCheck
        ? moment(new Date(tueMorningarr).getTime()).format("hh:mm a")
        : "",
      tueAfternoonTime: tueAfternoonCheck
        ? moment(new Date(tueAfternoonarr).getTime()).format("hh:mm a")
        : "",
      tueEveningTime: tueEveningCheck
        ? moment(new Date(tueEveningarr).getTime()).format("hh:mm a")
        : "",
      tueNightTime: tueNightCheck
        ? moment(new Date(tueNightarr).getTime()).format("hh:mm a")
        : "",
    });
    wedArr.push({
      wedMorningTime: wedMorningCheck
        ? moment(new Date(wedMorningarr).getTime()).format("hh:mm a")
        : "",
      wedAfternoonTime: wedAfternoonCheck
        ? moment(new Date(wedAfternoonarr).getTime()).format("hh:mm a")
        : "",
      wedEveningTime: wedEveningCheck
        ? moment(new Date(wedEveningarr).getTime()).format("hh:mm a")
        : "",
      wedNightTime: wedNightarr
        ? moment(new Date(wedNightarr).getTime()).format("hh:mm a")
        : "",
    });
    thuArr.push({
      thuMorningTime: thuMorningCheck
        ? moment(new Date(thuMorningarr).getTime()).format("hh:mm a")
        : "",
      thuAfternoonTime: thuAfternoonCheck
        ? moment(new Date(thuAfternoonarr).getTime()).format("hh:mm a")
        : "",
      thuEveningTime: thuEveningCheck
        ? moment(new Date(thuEveningarr).getTime()).format("hh:mm a")
        : "",
      thuNightTime: thuNightCheck
        ? moment(new Date(thuNightgarr).getTime()).format("hh:mm a")
        : "",
    });
    friArr.push({
      friMorningTime: friMorningCheck
        ? moment(new Date(friMorningarr).getTime()).format("hh:mm a")
        : "",
      friAfternoonTime: friAfternoonCheck
        ? moment(new Date(friAfternoonarr).getTime()).format("hh:mm a")
        : "",
      friEveningTime: friEveningCheck
        ? moment(new Date(friEveningarr).getTime()).format("hh:mm a")
        : "",
      friNightTime: friNightCheck
        ? moment(new Date(friNightarr).getTime()).format("hh:mm a")
        : "",
    });
    satArr.push({
      satMorningTime: satMorningCheck
        ? moment(new Date(satMorningarr).getTime()).format("hh:mm a")
        : "",
      satAfternoonTime: satAfternoonCheck
        ? moment(new Date(satAfternoonarr).getTime()).format("hh:mm a")
        : "",
      satEveningTime: satEveningCheck
        ? moment(new Date(satEveningarr).getTime()).format("hh:mm a")
        : "",
      satNightTime: satNightCheck
        ? moment(new Date(satNightarr).getTime()).format("hh:mm a")
        : "",
    });
    sunArr.push({
      sunMorningTime: sunMorningCheck
        ? moment(new Date(sunMorningarr).getTime()).format("hh:mm a")
        : "",
      sunAfternoonTime: sunAfternoonCheck
        ? moment(new Date(sunAfternoonarr).getTime()).format("hh:mm a")
        : "",
      sunEveningTime: sunEveningCheck
        ? moment(new Date(sunEveningarr).getTime()).format("hh:mm a")
        : "",
      sunNightTime: sunNightCheck
        ? moment(new Date(sunNightarr).getTime()).format("hh:mm a")
        : "",
    });
  }, [monArr, tueArr, wedArr, thuArr, friArr, satArr, sunArr]);
  console.log(sunArr);
  const handleScheduleMedicine = () => {
    setBtnDisable(true);
    var FirstName = sessionStorage.getItem("firstname");
    var LastName = sessionStorage.getItem("lastname");
    var UserName = FirstName + LastName;
    var getHours = selectedTimeZone.slice(1, 3);
    var getMinitus = selectedTimeZone.slice(4, 6);
    var hourtoMinitus = Math.floor(getHours * 60);
    var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));
    if (
      selectedMedicine === "NA" ||
      selectedMedicine === undefined ||
      selectedMedicine === null
    ) {
      warnToast(<>{text_err_select_medicine}!</>);
    } else {
      if (scheduleValidation()) {
        setBtnDisable(true);
        var api_url =
          GlobalConstants.Cdomain +
          "/api/breatheasy/v1/medicine/schedulemedicine";

        var scheduleMedicine = {
          _userId: userID,
          _medicineId: selectedMedicine,
          Asneeded: asNeeded,
          startdate: moment(startDate).format("YYYY-MM-DD"),
          enddate: moment(endDate).format("YYYY-MM-DD"),
          ismonday: isMonActive,
          istuesday: isTueActive,
          iswednesday: isWedActive,
          isthursday: isThuActive,
          isfriday: isFriActive,
          issaturday: isSatActive,
          issunday: isSunActive,
          createdby: UserName,
          updatedby: UserName,
          timezoneoffset: FinalTimezoneOffset,
          isMondayMorning: monMorningCheck,
          isMondayAfternoon: monAfternoonCheck,
          isMondayEvening: monEveningCheck,
          isMondayNight: monNightCheck,

          isTuesdayMorning: tueMorningCheck,
          isTuesdayAfternoon: tueAfternoonCheck,
          isTuesdayEvening: tueEveningCheck,
          isTuesdayNight: tueNightCheck,

          isWednesdayMorning: wedMorningCheck,
          isWednesdayAfternoon: wedAfternoonCheck,
          isWednesdayEvening: wedEveningCheck,
          isWednesdayNight: wedNightCheck,

          isThursdayMorning: thuMorningCheck,
          isThursdayAfternoon: thuAfternoonCheck,
          isThursdayEvening: thuEveningCheck,
          isThursdayNight: thuNightCheck,

          isFridayMorning: friMorningCheck,
          isFridayAfternoon: friAfternoonCheck,
          isFridayEvening: friEveningCheck,
          isFridayNight: friNightCheck,

          isSaturdayMorning: satMorningCheck,
          isSaturdayAfternoon: satAfternoonCheck,
          isSaturdayEvening: satEveningCheck,
          isSaturdayNight: satNightCheck,

          isSundayMorning: sunMorningCheck,
          isSundayAfternoon: sunAfternoonCheck,
          isSundayEvening: sunEveningCheck,
          isSundayNight: sunNightCheck,

          mondayMorningDoseCount: monMorningCheck ? MonmorningDose : "",
          mondayAfternoonDoseCount: monAfternoonCheck ? MonafternoonDose : "",
          mondayEveningDoseCount: monEveningCheck ? MoneveningDose : "",
          mondayNightDoseCount: monNightCheck ? MonnightDose : "",

          tuesdayMorningDoseCount: tueMorningCheck ? TuemorningDose : "",
          tuesdayAfternoonDoseCount: tueAfternoonCheck ? TueafternoonDose : "",
          tuesdayEveningDoseCount: tueEveningCheck ? TueeveningDose : "",
          tuesdayNightDoseCount: tueNightCheck ? TuenightDose : "",

          wednesdayMorningDoseCount: wedMorningCheck ? WedmorningDose : "",
          wednesdayAfternoonDoseCount: wedAfternoonCheck
            ? WedafternoonDose
            : "",
          wednesdayEveningDoseCount: wedEveningCheck ? WedeveningDose : "",
          wednesdayNightDoseCount: wedNightCheck ? WednightDose : "",

          thursdayMorningDoseCount: thuMorningCheck ? ThumorningDose : "",
          thursdayAfternoonDoseCount: thuAfternoonCheck ? ThuafternoonDose : "",
          thursdayEveningDoseCount: thuEveningCheck ? ThueveningDose : "",
          thursdayNightDoseCount: thuNightCheck ? ThunightDose : "",

          fridayMorningDoseCount: friMorningCheck ? FrimorningDose : "",
          fridayAfternoonDoseCount: friAfternoonCheck ? FriafternoonDose : "",
          fridayEveningDoseCount: friEveningCheck ? FrieveningDose : "",
          fridayNightDoseCount: friNightCheck ? FrinightDose : "",

          saturdayMorningDoseCount: satMorningCheck ? SatmorningDose : "",
          saturdayAfternoonDoseCount: satAfternoonCheck ? SatafternoonDose : "",
          saturdayEveningDoseCount: satEveningCheck ? SateveningDose : "",
          saturdayNightDoseCount: satNightCheck ? SatnightDose : "",

          sundayMorningDoseCount: sunMorningCheck ? SunmorningDose : "",
          sundayAfternoonDoseCount: sunAfternoonCheck ? SunafternoonDose : "",
          sundayEveningDoseCount: sunEveningCheck ? SuneveningDose : "",
          sundayNightDoseCount: sunNightCheck ? SunnightDose : "",
          mondayData: isMonActive === true ? monArr[0] : [],
          tuesdayData: isTueActive === true ? tueArr[0] : [],
          wednesdayData: isWedActive === true ? wedArr[0] : [],
          thursdayData: isThuActive === true ? thuArr[0] : [],
          fridayData: isFriActive === true ? friArr[0] : [],
          saturdayData: isSatActive === true ? satArr[0] : [],
          sundayData: isSunActive === true ? sunArr[0] : [],
          schedule_name: scheduleName,
        };
        const headerConfig = {
          headers: {
            accept: "application/json",
            "auth-token": sessionStorage.getItem("token"),
          },
        };

        axios
          .post(api_url, scheduleMedicine, headerConfig)
          .then(function (response) {
            console.log(response);
            successToast(response.data.message);
            if (response.status === 200) {
              setBtnDisable(false);
            }
            navigate("/dashboard");
            setErrSelectOne("");
          })
          .catch(function (err) {
            setBtnDisable(false);
            errorToast(err.response.data.message);
          });
      }
    }
  };

  const getAllMedicine = () => {
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/medicine/addmedicine/selectall?_userId=${userID}`;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        console.log("all medicine res***", res?.data?.data);
        var value = res?.data?.data;
        if (value.length > 0) {
          setAllMedicine(value);
          if (
            MedicineId === "" ||
            MedicineId === null ||
            MedicineId === undefined
          ) {
            setSelectedMedicine(value[0]?._id);
          } else {
            var Medicine = value.filter((e) => e._id === MedicineId);
            console.log(Medicine[0]._id);
            setSelectedMedicine(Medicine[0]._id);
          }
        }
      })
      .catch(function (err) {
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("all medicine err******", err);
        }
      });
  };

  useEffect(() => {
    getAllMedicine();
  }, [1000]);

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_current_med_schedule(
      xpath.select1("/resources/string[@name='text_current_med_schedule']", doc)
        .firstChild.data
    );
    setText_select_medicine(
      xpath.select1("/resources/string[@name='text_select_medicine']", doc)
        .firstChild.data
    );
    setText_medicine_schedule(
      xpath.select1("/resources/string[@name='text_medicine_schedule']", doc)
        .firstChild.data
    );
    setText_schedule_of_med(
      xpath.select1("/resources/string[@name='text_schedule_of_med']", doc)
        .firstChild.data
    );
    setText_as_needed(
      xpath.select1("/resources/string[@name='text_as_needed']", doc).firstChild
        .data
    );
    setText_start_date(
      xpath.select1("/resources/string[@name='text_start_date']", doc)
        .firstChild.data
    );
    setText_end_date(
      xpath.select1("/resources/string[@name='text_end_date']", doc).firstChild
        .data
    );
    setText_timezone(
      xpath.select1("/resources/string[@name='text_timezone']", doc).firstChild
        .data
    );
    setText_morning(
      xpath.select1("/resources/string[@name='text_morning']", doc).firstChild
        .data
    );
    setText_afternoon(
      xpath.select1("/resources/string[@name='text_afternoon']", doc).firstChild
        .data
    );
    setText_evening(
      xpath.select1("/resources/string[@name='text_evening']", doc).firstChild
        .data
    );
    setText_add_time(
      xpath.select1("/resources/string[@name='text_add_time']", doc).firstChild
        .data
    );
    setText_monday(
      xpath.select1("/resources/string[@name='text_monday']", doc).firstChild
        .data
    );
    setText_tuesday(
      xpath.select1("/resources/string[@name='text_tuesday']", doc).firstChild
        .data
    );
    setText_wednesday(
      xpath.select1("/resources/string[@name='text_wednesday']", doc).firstChild
        .data
    );
    setText_thursday(
      xpath.select1("/resources/string[@name='text_thursday']", doc).firstChild
        .data
    );
    setText_friday(
      xpath.select1("/resources/string[@name='text_friday']", doc).firstChild
        .data
    );
    setText_saturday(
      xpath.select1("/resources/string[@name='text_saturday']", doc).firstChild
        .data
    );
    setText_sunday(
      xpath.select1("/resources/string[@name='text_sunday']", doc).firstChild
        .data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_save(
      xpath.select1("/resources/string[@name='btn_save']", doc).firstChild.data
    );
    setText_add_medicine_first(
      xpath.select1("/resources/string[@name='text_add_medicine_first']", doc)
        .firstChild.data
    );
    setText_err_select_medicine(
      xpath.select1("/resources/string[@name='text_err_select_medicine']", doc)
        .firstChild.data
    );
    setText_err_select_any_one(
      xpath.select1("/resources/string[@name='text_err_select_any_one']", doc)
        .firstChild.data
    );
    setText_select_time(
      xpath.select1("/resources/string[@name='text_select_time']", doc)
        .firstChild.data
    );
    setText_timing(
      xpath.select1("/resources/string[@name='text_timing']", doc).firstChild
        .data
    );
    setText_number_of_puff_dose(
      xpath.select1("/resources/string[@name='text_number_of_puff_dose']", doc)
        .firstChild.data
    );
    setText_night(
      xpath.select1("/resources/string[@name='text_night']", doc).firstChild
        .data
    );
    setText_schedule_name(
      xpath.select1("/resources/string[@name='text_schedule_name']", doc)
        .firstChild.data
    );
    setText_new_med_schedule(
      xpath.select1("/resources/string[@name='text_new_med_schedule']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="px-4 py-4">
        {IsLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader />
          </div>
        ) : (
          <>
            {" "}
            {allMedicine.length > 0 ? (
              <>
                {MedicineId !== "" ||
                MedicineId !== undefined ||
                MedicineId !== null ? (
                  <>
                    <h4 className="text-center">{text_new_med_schedule}</h4>
                    <Divider />{" "}
                    <h5 className="text-center mt-4">{text_select_medicine}</h5>
                    <div className="d-flex justify-content-center flex-wrap ">
                      <select
                        // className=" medicine-select"
                        onChange={(e) => setSelectedMedicine(e.target.value)}
                        value={selectedMedicine}
                      >
                        <option disabled selected value="NA">
                          {text_select_medicine}
                        </option>

                        {allMedicine.map((val, index) => {
                          return (
                            <>
                              <option value={val._id} key={index}>
                                {val.Genericname}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="text-center">{text_medicine_schedule}</h4>
                    <Divider />
                    <h5 className="mt-3 text-center">{MedicineName}</h5>
                  </>
                )}
                <div className="row mt-4">
                  <h5 className="text-center">{text_schedule_of_med}</h5>
                  <div className="text-center">
                    <input
                      type="checkbox"
                      className="me-2"
                      onChange={(e) => setAsNeeded(e.target.checked)}
                    />
                    <label>{text_as_needed}</label>
                  </div>
                </div>
                {asNeeded === false ? (
                  <>
                    {" "}
                    <center>
                      <div className="schedulebox mt-4">
                        <div className=" d-flex justify-content-center gap-4 flex-wrap">
                          <div className="mx-5 mt-2 sm-mobile-timezone">
                            <h6 className="text-start">{text_schedule_name}</h6>
                            <input
                              onChange={(e) => setScheduleName(e.target.value)}
                              value={scheduleName}
                              placeholder={text_schedule_name}
                            />
                          </div>
                          <div className="mt-2 mx-5 sm-mobile-startdate">
                            <h6 className="text-start mob-text-startdate">
                              {text_start_date}
                            </h6>
                            <DatePicker
                              className="scheduleinput mob-startdate"
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MMM dd, yyyy"
                              selected={startDate}
                              minDate={new Date()}
                            />
                          </div>
                          <div className=" mt-2 sm-mobile-enddate">
                            <h6 className="text-start mob-text-startdate">
                              {text_end_date}
                            </h6>
                            <DatePicker
                              className="scheduleinput mob-enddate"
                              onChange={(date) => setEndDate(date)}
                              dateFormat="MMM dd, yyyy"
                              selected={endDate}
                              minDate={startDate}
                            />
                          </div>
                          <div className="mx-5 mt-2 sm-mobile-timezone">
                            <h6 className="text-start">{text_timezone}</h6>
                            <select
                              onChange={(e) =>
                                setSelectedTimeZone(e.target.value)
                              }
                            >
                              {FinalData?.map((val, i) => {
                                return (
                                  <>
                                    <option value={val?.utc}>
                                      {val?.label}{" "}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="row justify-content-md-center mt-5 ">
                          {/* ---------------------Monday------------------------- */}
                          <hr className="monhrfirst mob-hr" />
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleMonCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  monMorningCheck === false &&
                                  monAfternoonCheck === false &&
                                  monEveningCheck === false &&
                                  monNightCheck === false
                                    ? false
                                    : isMonActive
                                }
                              />
                              <label>{text_monday}</label>
                            </div>
                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setMonMorningcheck(e.target.checked),
                                      setisMonActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={monMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className="mx-3 days-box-inner ">
                                  {/* {MonMorningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setMonMorningArr(date),
                                          seterrMonTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        // selected={MonMorningarr[i].dateTime}
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          MonMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  {/* //   );
                                  // })} */}
                                  <br />
                                  <div className="mobdose-dose">
                                    <h6 className="dose-text">
                                      {text_number_of_puff_dose}
                                    </h6>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setMonmorningDose(e.target.value)
                                      }
                                      value={MonmorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setMonAfternooncheck(e.target.checked),
                                      setisMonActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={monAfternoonCheck}
                                  />
                                  <div>
                                    <h5 className="afternoon-text">
                                      {text_afternoon}
                                    </h5>
                                  </div>
                                </div>
                                <div className="afternoonInput days-box-inner">
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setMonAfternoonArr(date),
                                        seterrMonTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        MonAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setMonafternoonDose(e.target.value)
                                      }
                                      value={MonafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setMonEveningcheck(e.target.checked),
                                      setisMonActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={monEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4 afternoonInput">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setMonEveningArr(date),
                                        seterrMonTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(MonEveningarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty  evening-dose-mob"
                                      onChange={(e) =>
                                        setMoneveningDose(e.target.value)
                                      }
                                      value={MoneveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setMonNightcheck(e.target.checked),
                                      setisMonActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={monNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5 afternoonInput">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setMonNightArr(date),
                                        seterrMonTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(MonNightarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setMonninghtDose(e.target.value)
                                      }
                                      value={MonnightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errMonTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* ------------------------------------------------- */}
                          {/* ---------------------Tuesday----------------------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleTueCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  tueMorningCheck === false &&
                                  tueAfternoonCheck === false &&
                                  tueEveningCheck === false &&
                                  tueNightCheck === false
                                    ? false
                                    : isTueActive
                                }
                              />
                              <label>{text_tuesday}</label>
                            </div>

                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setTueMorningcheck(e.target.checked),
                                      setisTueActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={tueMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className=" mx-3 days-box-inner">
                                  {/* {tueMorningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          settueMorningArr(date),
                                          seterrTueTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        // selected={MonMorningarr[i].dateTime}
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          tueMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setTuemorningDose(e.target.value)
                                      }
                                      value={TuemorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setTueAfternooncheck(e.target.checked),
                                      setisTueActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={tueAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="  afternoonInput days-box-inner">
                                  {/* {tueAfternoonarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        settueAfternoonArr(date),
                                        seterrTueTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        tueAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setTueafternoonDose(e.target.value)
                                      }
                                      value={TueafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setTueEveningcheck(e.target.checked),
                                      setisTueActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={tueEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  {/* {tueEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        settueEveningArr(date),
                                        seterrTueTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(tueEveningarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setTueeveningDose(e.target.value)
                                      }
                                      value={TueeveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setTueNightcheck(e.target.checked),
                                      setisTueActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={tueNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  {/* {tueEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        settueNightArr(date),
                                        seterrTueTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(tueNightarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setTuenightDose(e.target.value)
                                      }
                                      value={TuenightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errTueTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* -------------------------------------------- */}
                          {/* ------------Wednsday-------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleWedCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  wedMorningCheck === false &&
                                  wedAfternoonCheck === false &&
                                  wedEveningCheck === false &&
                                  wedNightCheck === false
                                    ? false
                                    : isWedActive
                                }
                              />
                              <label>{text_wednesday}</label>
                            </div>

                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setWedMorningcheck(e.target.checked),
                                      setisWedActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={wedMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className=" mx-3 days-box-inner">
                                  {/* {wedMorningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h5 className="dose-text">
                                        {text_timing}
                                      </h5>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setwedMorningArr(date),
                                          seterrWedTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        // selected={MonMorningarr[i].dateTime}
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          wedMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setWedmorningDose(e.target.value)
                                      }
                                      value={WedmorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setWedAfternooncheck(e.target.checked),
                                      setisWedActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={wedAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="  afternoonInput days-box-inner">
                                  {/* {wedAfternoonarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setwedAfternoonArr(date),
                                        seterrWedTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        wedAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setWedafternoonDose(e.target.value)
                                      }
                                      value={WedafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setWedEveningcheck(e.target.checked),
                                      setisWedActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={wedEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  {/* {wedEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setwedEveningArr(date),
                                        seterrWedTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(wedEveningarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setWedeveningDose(e.target.value)
                                      }
                                      value={WedeveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setWedNightcheck(e.target.checked),
                                      setisWedActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={wedNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  {/* {wedEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setwedNightArr(date),
                                        seterrWedTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(wedNightarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setWednightDose(e.target.value)
                                      }
                                      value={WednightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errWedTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* -------------------------- */}
                          {/* ------------Thursday--------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleThuCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  thuMorningCheck === false &&
                                  thuAfternoonCheck === false &&
                                  thuEveningCheck === false &&
                                  thuNightCheck === false
                                    ? false
                                    : isThuActive
                                }
                              />
                              <label>{text_thursday}</label>
                            </div>

                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setThuMorningcheck(e.target.checked),
                                      setisThuActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={thuMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className=" mx-3 days-box-inner">
                                  {/* {thuMorningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setthuMorningArr(date),
                                          seterrThuTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        // selected={MonMorningarr[i].dateTime}
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          thuMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setThumorningDose(e.target.value)
                                      }
                                      value={ThumorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setThuAfternooncheck(e.target.checked),
                                      setisThuActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={thuAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="  afternoonInput days-box-inner">
                                  {/* {thuAfternoonarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setthuAfternoonArr(date),
                                        seterrThuTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        thuAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setThuafternoonDose(e.target.value)
                                      }
                                      value={ThuafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setThuEveningcheck(e.target.checked),
                                      setisThuActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={thuEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  {/* {thuEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setthuEveningArr(date),
                                        seterrThuTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(thuEveningarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    {" "}
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setThueveningDose(e.target.value)
                                      }
                                      value={ThueveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>

                                  {/* )} */}
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setThuNightcheck(e.target.checked),
                                      setisThuActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={thuNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  {/* {thuEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setthuNightgArr(date),
                                        seterrThuTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(thuNightgarr).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    {" "}
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setThunightDose(e.target.value)
                                      }
                                      value={ThunightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>

                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errThuTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* -------------------------- */}
                          {/* -------------Friday-------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleFriCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  friMorningCheck === false &&
                                  friAfternoonCheck === false &&
                                  friEveningCheck === false &&
                                  friNightCheck === false
                                    ? false
                                    : isFriActive
                                }
                              />
                              <label>{text_friday}</label>
                            </div>

                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setFriMorningcheck(e.target.checked),
                                      setisFriActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={friMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className=" mx-3 days-box-inner">
                                  {/* {friMorningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setfriMorningArr(date),
                                          seterrFriTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        // selected={MonMorningarr[i].dateTime}
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          friMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setFrimorningDose(e.target.value)
                                      }
                                      value={FrimorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setFriAfternooncheck(e.target.checked),
                                      setisFriActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={friAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="afternoonInput days-box-inner">
                                  {/* {friAfternoonarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setfriAfternoonArr(date),
                                        seterrFriTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        friAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>
                                  {/* );
                                  })} */}
                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setFriafternoonDose(e.target.value)
                                      }
                                      value={FriafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setFriEveningcheck(e.target.checked),
                                      setisFriActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={friEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  {/* {friEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setfriEveningArr(date),
                                        seterrFriTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(friEveningarr).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className=" evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setFrieveningDose(e.target.value)
                                      }
                                      value={FrieveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setFriNightcheck(e.target.checked),
                                      setisFriActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={friNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  {/* {friEveningarr.map((item, i) => {
                                    return ( */}
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setfriNightArr(date),
                                        seterrFriTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      // selected={MonMorningarr[i].dateTime}
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(friNightarr).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setFrinightDose(e.target.value)
                                      }
                                      value={FrinightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errFriTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* -------------------------- */}
                          {/* ------------Saturday--------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleSatCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  satMorningCheck === false &&
                                  satAfternoonCheck === false &&
                                  satEveningCheck === false &&
                                  satNightCheck === false
                                    ? false
                                    : isSatActive
                                }
                              />
                              <label>{text_saturday}</label>
                            </div>
                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSatMorningcheck(e.target.checked),
                                      setisSatActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={satMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className=" mx-3 days-box-inner">
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setsatMorningArr(date),
                                          seterrSatTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          satMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>

                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text ">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese "
                                      onChange={(e) =>
                                        setSatmorningDose(e.target.value)
                                      }
                                      value={SatmorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setSatAfternooncheck(e.target.checked),
                                      setisSatActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={satAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="afternoonInput days-box-inner">
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsatAfternoonArr(date),
                                        seterrSatTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        satAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setSatafternoonDose(e.target.value)
                                      }
                                      value={SatafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSatEveningcheck(e.target.checked),
                                      setisSatActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={satEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsatEveningArr(date),
                                        seterrSatTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(satEveningarr).getTime()}
                                    />
                                  </div>
                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setSateveningDose(e.target.value)
                                      }
                                      value={SateveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSatNightcheck(e.target.checked),
                                      setisSatActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={satNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsatNightArr(date),
                                        seterrSatTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(satNightarr).getTime()}
                                    />
                                  </div>
                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setSatnightDose(e.target.value)
                                      }
                                      value={SatnightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errSatTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />
                          {/* -------------------------- */}
                          {/* ----------------Sunday----------------- */}
                          <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                            <div className="check-day-sty">
                              <input
                                type="checkbox"
                                className="me-2"
                                onChange={(e) => [
                                  handleSunCheck(e),
                                  setErrSelectOne(""),
                                ]}
                                checked={
                                  sunMorningCheck === false &&
                                  sunAfternoonCheck === false &&
                                  sunEveningCheck === false &&
                                  sunNightCheck === false
                                    ? false
                                    : isSunActive
                                }
                              />
                              <label>{text_sunday}</label>
                            </div>
                            <div className="selecttimeDiv">
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSunMorningcheck(e.target.checked),
                                      setisSunActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-morning-mob"
                                    checked={sunMorningCheck}
                                  />
                                  <h5 className="mt-4 morning-text-mob">
                                    {text_morning}
                                  </h5>
                                </div>
                                <div className="mx-3  days-box-inner">
                                  <div className="monarrinput mx-1">
                                    <div className="timing-mob">
                                      <h6 className="dose-text">
                                        {text_timing}
                                      </h6>
                                      <ReactDatePicker
                                        className="frequencyInput "
                                        onChange={(date, id) => [
                                          setsunMorningArr(date),
                                          seterrSunTimeselect(""),
                                        ]}
                                        disableDayPicker
                                        format="hh:mm a"
                                        plugins={[<TimePicker hideSeconds />]}
                                        value={new Date(
                                          sunMorningarr
                                        ).getTime()}
                                      />
                                    </div>
                                  </div>
                                  <br />
                                  <div className="mobdose-dose">
                                    <h5 className="dose-text ">
                                      {text_number_of_puff_dose}
                                    </h5>
                                    <select
                                      className="select-doese"
                                      onChange={(e) =>
                                        setSunmorningDose(e.target.value)
                                      }
                                      value={SunmorningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    className="mb-3 checkbox-mob"
                                    onChange={(e) => [
                                      setSunAfternooncheck(e.target.checked),
                                      setisSunActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    checked={sunAfternoonCheck}
                                  />
                                  <h5 className="afternoon-text">
                                    {text_afternoon}
                                  </h5>
                                </div>
                                <div className="afternoonInput days-box-inner">
                                  <div className="monarrinput mx-1 afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsunAfternoonArr(date),
                                        seterrSunTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(
                                        sunAfternoonarr
                                      ).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="select-doese mx-4 afternoon-dose-mob"
                                      onChange={(e) =>
                                        setSunafternoonDose(e.target.value)
                                      }
                                      value={SunafternoonDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSunEveningcheck(e.target.checked),
                                      setisSunActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={sunEveningCheck}
                                  />
                                  <h5 className="mt-2">{text_evening}</h5>
                                </div>
                                <div className="days-box-inner mx-4">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsunEveningArr(date),
                                        seterrSunTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(sunEveningarr).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setSuneveningDose(e.target.value)
                                      }
                                      value={SuneveningDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="days-box-outer">
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => [
                                      setSunNightcheck(e.target.checked),
                                      setisSunActive(true),
                                      setErrSelectOne(""),
                                    ]}
                                    className="checkbox-mob"
                                    checked={sunNightCheck}
                                  />
                                  <h5 className="mt-2">{text_night}</h5>
                                </div>
                                <div className="days-box-inner mx-5">
                                  <div className="monarrinput afternoon-time-mob">
                                    <ReactDatePicker
                                      className="frequencyInput "
                                      onChange={(date, id) => [
                                        setsunNightArr(date),
                                        seterrSunTimeselect(""),
                                      ]}
                                      disableDayPicker
                                      format="hh:mm a"
                                      plugins={[<TimePicker hideSeconds />]}
                                      value={new Date(sunNightarr).getTime()}
                                    />
                                  </div>

                                  <br />
                                  <div>
                                    <select
                                      className="evening-sty evening-dose-mob"
                                      onChange={(e) =>
                                        setSunnightDose(e.target.value)
                                      }
                                      value={SunnightDose}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="ErrMessage err-day-select">
                            {errSunTimeselect}
                          </p>
                          <hr className="monhr mob-hr" />{" "}
                        </div>
                        {/* ########################################## */}

                        {/* #####################################*/}
                      </div>
                    </center>
                  </>
                ) : (
                  ""
                )}

                <div>
                  <p className="ErrMessage  text-center mt-3">{errSelectOne}</p>
                </div>
                <div className="d-flex justify-content-center gap-4">
                  <button
                    className="cancelbtn"
                    onClick={() => navigate("/dashboard")}
                  >
                    {btn_cancel}
                  </button>
                  <button
                    className="savebtn"
                    onClick={() => handleScheduleMedicine()}
                    disabled={btnDisable}
                  >
                    {btn_save}
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className="text-center">{text_medicine_schedule}</h4>
                <Divider />
                <center>
                  <div className="mt-5 item-align-center  ">
                    <h4 className="searchText">{text_add_medicine_first}!</h4>{" "}
                    <button
                      className="cancelbtn mt-4"
                      onClick={() => navigate("/dashboard")}
                    >
                      {btn_cancel}
                    </button>
                  </div>
                </center>
              </>
            )}
          </>
        )}
      </div>

      <ToastContainer />
    </>
  );
}

export default ScheduleMedicine;
