import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";
import { errorToast } from "../utils/ToasterUtils";

const DashboardSlice = createSlice({
  name: "Dashboardpairdevice",
  initialState: [],

  reducers: {
    setAllMedicinesInPopup(state, action) {
      return (state = action.payload);
    },
    setMedicinepopupLogout(state, action) {
      return (state = action.payload);
    },
  },
});

const { setAllMedicinesInPopup, setMedicinepopupLogout } =
  DashboardSlice.actions;
export default DashboardSlice.reducer;

export function getAllMedicineInPopup() {
  return async function getAllMedicineInPopupThunk(dispatch, getState) {
    const userId = sessionStorage.getItem("user_id");

    try {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/medicine/addmedicine/selectall?_userId=${userId}`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          console.log("all medicine res***", res);
          dispatch(setAllMedicinesInPopup(res?.data?.data));
        })
        .catch(function (err) {
          errorToast(err.response.data.message);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              window.location.replace("/");
            }, 2000);
          } else {
            console.log("all medicine err******", err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}
export function medicinePopupFunc() {
  return async function medicinePopupFuncThunk(dispatch, getState) {
    try {
      dispatch(setMedicinepopupLogout([]));
    } catch (err) {
      console.log(err);
    }
  };
}
