import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";

const DeviceListSlice = createSlice({
  name: "DeviceList",
  initialState: [],
  reducers: {
    setAllDevicesInPopup(state, action) {
      return (state = action.payload);
    },
    setdevicelistLogout(state, action) {
      return (state = action.payload);
    },
  },
});

const { setAllDevicesInPopup, setdevicelistLogout } = DeviceListSlice.actions;
export default DeviceListSlice.reducer;

export function getAllDevicesInPopup() {
  return async function getAllDevicesInPopupThunk(dispatch, getState) {
    const userId = sessionStorage.getItem("user_id");
    try {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/device/adddevicemanual/selectall?_userId=${userId}`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          console.log("all devices res***", res);
          dispatch(setAllDevicesInPopup(res?.data?.data));
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              window.location.replace("/");
            }, 2000);
          } else {
            console.log("all devices err***", err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}
export function deviceListLogout() {
  return async function deviceListLogoutThunk(dispatch, getState) {
    try {
      dispatch(setdevicelistLogout([]));
    } catch (err) {
      console.log(err);
    }
  };
}
