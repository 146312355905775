import React, { useState, useEffect } from "react";
import loginImg from "../../assets/img/Login.png";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import { useNavigate } from "react-router-dom";
import "../VerifyPage/VerifyPage.css";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { errorToast } from "../../utils/ToasterUtils";
import { PASSWORD_REGEX } from "../../utils/Regex";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { ToastContainer } from "react-toastify";

function VerifyPage() {
  //Language start
  const [text_password, setText_password] = useState("Password");
  const [text_ph_password, setText_ph_password] = useState("Enter Password");
  //Language end
  const navigate = useNavigate();
  const [showRegPassword, setShowRegPassword] = useState(false);
  const [verified, setVerified] = useState(false);
  const [visPasswordreset, setVisPasswordreset] = useState(false);
  const [notVerifiedmsg, setNotVerifiedmsg] = useState("");
  const [successPass, setSuccessPass] = useState(false);
  const [Password, setPassword] = useState("");
  const [ErrPassword, setErrPassword] = useState("");
  const handleClickShowRegPassword = () => setShowRegPassword(!showRegPassword);

  //Password Validation
  const newPasswordValidation = () => {
    let formValid = true;
    if (!PASSWORD_REGEX.test(Password) === true) {
      formValid = false;
      if (Password === "") {
        formValid = false;
        setErrPassword("*Please Enter Password!");
      } else {
        setErrPassword("*Please Enter Strong Password!");
      }
    }
    return formValid;
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("key");
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/user/verifylink?key=" + key;
    let headerConfig = {
      headers: {
        accept: "application/json",
      },
    };
    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        console.log(response);
        let res = response.data;
        if (res.status) {
          // infoToast(res.message);
          console.log(response);
          if (res.message === "please send new password to reset") {
            //visiable password reste page
            setVisPasswordreset(true);
            setNotVerifiedmsg("");
          } else {
            setVerified(true);
          }
        }
      })
      .catch(function (error) {
        sessionStorage.clear();
        localStorage.clear();
        console.log("Error! Try Again.");
        errorToast(error);
        setVerified(false);
        if (
          error.response.data.message === "Your verification link has expired"
        ) {
          setNotVerifiedmsg(error.response.data.message);
        } else if (error.response.data.message === "key missing") {
          errorToast(error.response.data.message);
          setNotVerifiedmsg(error.response.data.message);
        }else if( error.response.data.message==="key missing or link expired"){
          setNotVerifiedmsg(error.response.data.message);
        } else {
          errorToast(error.response.data.message);
        }
      });
  }, []);

  const handleResetPassword = (password) => {
    if (newPasswordValidation()) {
      const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("key");
      var api_url =
        GlobalConstants.Cdomain +
        '/api/breatheasy/v1/user/verifylink?key='+key+
        "&password=" +
        password;;
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          console.log(response);
          let res = response.data;
          // setSuccessPass(true);
          // setVisPasswordreset(false);
          if (res.message === "please send new password to reset") {
            //visiable password reste page
            setVisPasswordreset(true);
          } else {
            setSuccessPass(true);
            setVerified(false);
            setVisPasswordreset(false);
          }
        })
        .catch(function (err) {
          sessionStorage.clear();
          localStorage.clear();
          console.log("Error! Try Again.");
          errorToast(err);
        });
    }
  };
  return (
    <>
      <div className="row loginMain">
        <div className="col-md-6" id="mobilebg">
          <img className="imgstyle" src={loginImg} alt="" />
        </div>
        <div className="col-md-6">
          <div className="LoginHalfBG">
            <div className="LoginBox">
              {verified ? (
                <>
                  {/* Account Verified */}
                  <center>
                    <img src={LogoImg} alt="" className="LoginLogoSty mb-3" />
                    {/* Reset Password */}
                    <div className="thumb-icon-sty">
                      <FaRegThumbsUp className="text-center thumb-sty" />
                    </div>
                    <h4 className="mt-3 ">Account Verified</h4>{" "}
                    <p className="HeadlineText">
                      Your account was successfully verified.
                      <br /> Please proceed to the sign in page.
                    </p>
                    <button
                      className="verifyBTN mt-3 btn-width-verify"
                      onClick={() => navigate("/login")}
                    >
                      Go back to sign in
                    </button>
                  </center>
                </>
              ) : (
                <>
                  {visPasswordreset ? (
                    <>
                      {" "}
                      <center>
                        <img
                          src={LogoImg}
                          alt=""
                          className="LoginLogoSty mb-3"
                        />
                        {/* Reset Password */}
                        <h5 className="text-sty">Reset Your Password</h5>
                      </center>{" "}
                      <div className="LoginForm">
                        <div className="form-input-login mt-4">
                          <TextField
                            id="outlined-textarea"
                            type={showRegPassword ? "text" : "password"}
                            label={text_password}
                            placeholder={text_ph_password}
                            className="header-signup-inputs login-input"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowRegPassword}
                                  >
                                    {showRegPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => [
                              setPassword(e.target.value),
                              setErrPassword(""),
                            ]}
                          />
                          <p className="ErrMessage mx-5">{ErrPassword}</p>
                        </div>
                        <center>
                          <button
                            className="Cancelbtnforgot mt-4 me-3 w-25"
                            onClick={() => navigate("/login")}
                          >
                            cancel
                          </button>
                          <button className="verifyBTN btn-width-verify mt-3" onClick={()=>handleResetPassword(Password)}>
                            Reset Password
                          </button>
                        </center>
                      </div>
                    </>
                  ):(
                    <>
                      {" "}
                      {successPass === true && (
                        <>
                          <center>
                            <img
                              src={LogoImg}
                              alt=""
                              className="LoginLogoSty mb-3"
                            />
                          {" "}
                            </center>
                            <center>
                          {/* <div className="thumb-icon-sty"> */}
                            <FcApproval className="text-center reset-pass-sty resetpassword" />
                          {/* </div> */}
                          <h4 className="mt-3">
                            Your password has been successfully reset!
                          </h4>
                          <button
                            className="verifyBTN mt-3 btn-width-verify"
                            onClick={() => navigate("/login")}
                          >
                            Back to Sign in
                          </button>
                          </center>
                        </>
                      )}{" "}
                      {successPass === false && (
                        <>
                          <center>
                            <img
                              src={LogoImg}
                              alt=""
                              className="LoginLogoSty mb-3"
                            />
                          </center>{" "}
                          <div className="thumb-icon-sty-notverified">
                            <FaRegThumbsDown className="text-center thumb-sty-notverify" />
                          </div>
                          <center>
                            <h4 className="mt-3 text-center">
                              {notVerifiedmsg}
                            </h4>
                            <p>
                              Click below to request a new verification link.
                            </p>
                            <button
                              className="verifyBTN mt-3 btn-width-verify"
                              onClick={() => navigate("/support")}
                            >
                              Back to Resend
                            </button>
                          </center>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default VerifyPage;
