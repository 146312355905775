import { useEffect, useState } from "react";
import Header from "../Header/Header";
import "../RegisterDeviceMedicine/RegisterDeviceMedicine.css";
import { AiOutlineSearch } from "react-icons/ai";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import {
  errorToast,
  infoToast,
  successToast,
  warnToast,
} from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-multi-date-picker";
import DeviceLogo from "../../assets/img/inhaler.png";
import handStep from "../../assets/img/hand.png";
import { Divider } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { serachMedicine } from "../../redux/GlobalMedicineSlice";
import { timeZonecountries } from "../../utils/data";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import { event } from "jquery";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const _ = require('lodash');

const RegisterDeviceMedicine = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const locationData = location?.state;
  var medicineSearch = useSelector((state) => state.globalMedicine); // search medicine api

  // Language Variables
  const [text_hello, setText_hello] = useState("Hello");

  const [text_wc_braetheasy, setText_wc_braetheasy] = useState(
    "Welcome to BreathEasy"
  );
  const [text_reg_device_medicine, setText_reg_device_medicine] = useState(
    "Register Your Device And Medicine"
  );
  const [text_btn_skip, setText_btn_skip] = useState("Skip");
  const [text_step, setText_step] = useState("Step");
  const [text_enter_device_detail, setText_enter_device_detail] = useState(
    "Enter Device Details"
  );
  const [text_device_custom_name, setText_device_custom_name] =
    useState("Device Custom Name");
  const [text_ph_custom_name, setText_ph_custom_name] =
    useState("e.g. Mom's Device");
  const [text_serial_no, setText_serial_no] = useState("Serial Number");
  const [text_ph_serial_no, setText_ph_serial_no] = useState(
    "Enter Device Serial Number"
  );
  const [text_add_device, setText_add_device] = useState("Add Device");
  const [text_dont_device, setText_dont_device] = useState(
    " I don't have any Device"
  );
  const [text_device_name, setText_device_name] = useState("Device Name");
  const [text_enter_medicine_detail, setText_enter_medicine_detail] = useState(
    "Enter Medicine Details"
  );
  const [text_buy_date, setText_buy_date] = useState("Buy Date");
  const [text_medicine_later, setText_medicine_later] =
    useState("Add medicine later");
  const [text_upc_code, setText_upc_code] = useState("UPC Code");
  const [text_product_name, setText_product_name] = useState("Product Name");
  const [text_search_upc_product, setText_search_upc_product] = useState(
    "Search UPC Code or Product name.."
  );
  const [text_btn_search, setText_btn_search] = useState("Search");
  const [text_add_manually, setText_add_manually] = useState(
    "want to add manually?"
  );
  const [text_company_name, setText_company_name] = useState("Company Name");
  const [text_ph_company_name, setText_ph_company_name] =
    useState("Enter Company Name");
  const [text_brand_name, setText_brand_name] = useState("Brand Name");
  const [text_ph_brand_name, setText_ph_brand_name] =
    useState("Enter Brand Name");
  const [text_generic_name, setText_generic_name] = useState("Generic Name");
  const [text_ph_generic_name, setText_ph_generic_name] =
    useState("Enter Generic Name");
  const [text_strength, setText_strength] = useState("Strength");
  const [text_ph_strength, setText_ph_strength] = useState("Enter Strength");
  const [text_doses, setText_doses] = useState("Doses");
  const [text_ph_doses, setText_ph_doses] = useState("Enter Doses");
  const [text_tag, setText_tag] = useState("Tag");
  const [text_expiry_date, setText_expiry_date] = useState("Expiry Date");
  const [text_search_medicine, setText_search_medicine] = useState(
    "Search Your Medicine!"
  );
  const [text_no_medicine, setText_no_medicine] = useState("No Medicine Found");
  const [text_btn_select, setText_btn_select] = useState("Select");
  const [text_add_medicine, setText_add_medicine] = useState("Add Medicine");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_pair, setBtn_pair] = useState("Pair");
  const [btn_save, setBtn_save] = useState("Save");
  const [text_warn_medicine_detail, setText_warn_medicine_detail] = useState(
    "Please Add Any Medicine Detail!"
  );
  const [text_warn_device_detail, setText_warn_device_detail] = useState(
    "Add Any Device First!"
  );
  const [text_warn_device_medicine, setText_warn_device_medicine] = useState(
    "Please Add Device or Medicine"
  );
  const [text_selected, setText_selected] = useState("Selected");
  const [text_succ_medicine_selected, setText_succ_medicine_selected] =
    useState("Medicine Selected!");
  const [text_warn_medicine_name, setText_warn_medicine_name] = useState(
    "Enter Medicine Name!"
  );
  const [text_warn_search_medicine, setText_warn_search_medicine] = useState(
    "Please Search Any Medicine!"
  );
  const [Text_select_expiry_date, setText_select_expiry_date] =
    useState("Select Expiry Date");
  const [text_select_buy_date, setText_select_buy_date] =
    useState("Select Buy Date");
  const [text_timezone, setText_timezone] = useState("TimeZone");
  const [text_medicine_expiry, setText_medicine_expiry] = useState(
    "Medicine Expiry Alert"
  );
  const [text_alert_befor_expiry, setText_alert_befor_expiry] = useState(
    "Alert Before Expiry"
  );
  const [text_alert_time, setText_alert_time] = useState("Alert Time");
  //language variable end

  const [addmedicineManually, setAddmedicineManually] = useState(false);
  const [deviceCustomName, setDeviceCustomName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [addlater, setAddlater] = useState(false);
  const [NoDevice, setNoDevice] = useState(false);
  const [MedCmpName, setMedCmpName] = useState("");
  const [MedBrandName, setMedBrandName] = useState("");
  const [MedGenricName, setMedGenricName] = useState("");
  const [MedStrength, setMedStrength] = useState("");
  const [MedDose, setMedDose] = useState("");
  const [MedTag, setMedTag] = useState("");
  const [addMedLater, setAddMedLater] = useState(false);
  const [GlobalMedData, setGlobalMedData] = useState([]);
  const [SearchedMedName, setSearchedMedName] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [deviceDetail, setDeviceDetail] = useState([]);
  const [MedicineDetail, setMedicineDetail] = useState([]);
  const [buyDate, setBuyDate] = useState(new Date());
  const [addDevicebtn, setAddDeviceBtn] = useState(false);
  const [addMedicinebtn, setAddMedicinebtn] = useState(false);
  const [addBtnShow, setAddBtnShow] = useState(false);
  const [errAddMedicine, setErrAddMedicine] = useState("");
  const [errSearchMedicine, setErrSerachMedicine] = useState("");
  const [showAlertDate, setShowAlertData] = useState(false);
  const [alertDays, setAlertDays] = useState("5");
  const [AlertTime, setAlertTime] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  var ttp = medicineSearch.filter((e) => {
    return Object.values(e.Genericname)
      .join("")
      .toLowerCase()
      .includes(SearchedMedName.toLocaleLowerCase());
  });
  const [searchFilterTemp, setsearchFilterTemp] = useState([]);
  useEffect(() => {
    if (medicineSearch) {
      dispatch(serachMedicine());
      setsearchFilterTemp([...ttp]);
    }
  }, [SearchedMedName]);
  //TimeZone
  var timezones = timeZonecountries[0];
  const FinalData = timezones;
  const [selectedTimeZone, setSelectedTimeZone] = useState(FinalData[0]?.utc);
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("token") === ""
    ) {
      sessionStorage.setItem("token", locationData?.tokenInCookie);
      sessionStorage.setItem("user_email", locationData?.user_emailInCookie);
      sessionStorage.setItem("firstname", locationData?.firstnameInCookie);
      sessionStorage.setItem("lastname", locationData?.lastnameInCookie);
      sessionStorage.setItem("user_id", locationData?.user_idInCookie);
    }
  }, []);
  const navigate = useNavigate();

  const handleGetDevice = (ID) => {
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/adddevicemanual/select?_id=" +
      ID;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data.data;
          console.log(data);
          var deviceDetailData = [];
          if (data.Havedevice === false) {
            setDeviceDetail([]);
          } else {
            deviceDetailData.push(data);
            setDeviceDetail(deviceDetailData);
          }
        }
      })
      .catch(function (err) {
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };
  const handleGetMedicine = (ID) => {
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/medicine/addmedicine/select?_id=" +
      ID;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data.data;
          var MedicineData = [];
          if (data.AddmedicineLater === false) {
            MedicineData.push(data);
            setMedicineDetail(MedicineData);
          } else {
            setMedicineDetail([]);
          }
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };
  const handleMedicine = () => {
    setAddMedicinebtn(false);
    if (
      MedCmpName !== "" ||
      MedBrandName !== "" ||
      MedDose !== "" ||
      MedTag !== "" ||
      MedStrength !== "" ||
      MedGenricName !== "" ||
      addMedLater === true
    ) {
      setAddMedicinebtn(true);
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/medicine/addmedicine?type=insert";
      var MedicineData = {
        _userId: sessionStorage.getItem("user_id"),
        Companyname: MedCmpName,
        Brandname: MedBrandName,
        Genericname: MedGenricName,
        Strength: MedStrength,
        Dose: Number(MedDose),
        Tag: MedTag,
        Addmanually: addmedicineManually,
        AddmedicineLater: addMedLater,
        medicine_expiry:
          expiryDate === "" || expiryDate === null || expiryDate === undefined
            ? ""
            : moment(expiryDate).format("YYYY-MM-DD"),
        unitofmasurement: "MD",
        buydate: moment(buyDate).format("YYYY-MM-DD"),
      };

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .post(api_url, MedicineData, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            if (
              response.data.data.medicine_expiry === undefined ||
              response.data.data.medicine_expiry === null ||
              response.data.data.medicine_expiry === ""
            ) {
              setShowAlertData(false);
            } else {
              setShowAlertData(true);
            }
            setAddMedicinebtn(false);
            setAddBtnShow(false);
            // successToast(response.data.message);
            var MedicineData = response.data.data;
            console.log(MedicineData);
            sessionStorage.setItem("MedicineID", MedicineData._id);
            handleGetMedicine(MedicineData._id);
            setGlobalMedData([]);
            setMedBrandName("");
            setMedGenricName("");
            setBuyDate(new Date());
            setExpiryDate();
            setMedCmpName("");
            setAddmedicineManually(false);
            setMedStrength("");
            setMedTag("");
            setMedDose("");
            setAddMedLater(false);
            setSearchedMedName("");
            setErrAddMedicine("");
            setErrSerachMedicine("");
          }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            errorToast(err.response.data.message);
            console.log(err);
            setGlobalMedData([]);
            setMedBrandName("");
            setMedGenricName("");
            setBuyDate(new Date());
            setExpiryDate();
            setMedCmpName("");
            setAddmedicineManually(false);
            setMedStrength("");
            setMedTag("");
            setMedDose("");
            setAddMedLater(false);
            setSearchedMedName("");
            setAddMedicinebtn(false);
          }
        });
    } else {
      setErrAddMedicine(<>{text_warn_medicine_detail}</>);
    }
  };

  const handleDeviceDetail = () => {
    // setAddDeviceBtn(false);
    setAddDeviceBtn(true);
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/adddevicemanual?type=insert";
    var DeviceData = {
      _userId: sessionStorage.getItem("user_id"),
      paired_medicineId: null,
      Devicecustomname: deviceCustomName === "" ? "Device-1" : deviceCustomName,
      Serialnumber: serialNumber,
      Havedevice: !NoDevice,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, DeviceData, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setAddDeviceBtn(false);
          successToast(response.data.message);
          var DeviceData = response.data.data;
          console.log(response.data.data);
          sessionStorage.setItem("deviceID", response.data.data._id);
          handleGetDevice(response.data.data._id);
          setDeviceCustomName("");
          setSerialNumber("");
          setNoDevice(false);
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          setAddDeviceBtn(false);
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          errorToast(err.response.data.message);
          setAddDeviceBtn(false);
        }
      });
  };

  useEffect(() => {
    var DeviceID = sessionStorage.getItem("deviceID");
    console.log(DeviceID);
    if (DeviceID === "" || DeviceID === null) {
      console.log(DeviceID);
    } else {
      handleGetDevice(DeviceID);
    }
  }, [deviceDetail.length]);

  useEffect(() => {
    var MedicineID = sessionStorage.getItem("MedicineID");
    console.log(MedicineID);
    if (MedicineID === "" || MedicineID === null) {
      console.log(MedicineID);
    } else {
      handleGetMedicine(MedicineID);
    }
  }, [MedicineDetail.length]);

  const handlePairDevicetoMedicine = () => {
    var deviceId = sessionStorage.getItem("deviceID");
    var MedicineId = sessionStorage.getItem("MedicineID");
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/device/pairdevice";
    var PairingData = {
      _deviceId: deviceId,
      _medicineId: MedicineId,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, PairingData, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          sessionStorage.removeItem("selectedDeviceID");
          navigate("/schedule-medicine", {
            state: { data: MedicineDetail[0]._id },
          });
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          errorToast(err.response.data.message);
        }
      });
  };

  const handleSubmitData = () => {
    sessionStorage.removeItem("selectedDeviceID");
    if (MedicineDetail.length === 0 && deviceDetail.length === 0) {
      warnToast(<>{text_warn_device_medicine}</>);
    } else {
      if (MedicineDetail.length !== 0) {
        navigate("/schedule-medicine", {
          state: { data: MedicineDetail[0]._id },
        });
        console.log(MedicineDetail[0]._schedule_Id);
        sessionStorage.removeItem("deviceID");
        sessionStorage.removeItem("MedicineID");
      } else {
        sessionStorage.removeItem("deviceID");
        sessionStorage.removeItem("MedicineID");
        navigate("/dashboard");
      }
    }
  };

  const selectedmedicine = (data, index) => {
    console.log(data);
    setMedCmpName(data.Companyname);
    // setNoDevice(data.AddmedicineLater);
    setMedBrandName(data.Brandname);
    setMedDose(data.Dose);
    setMedStrength(data.Strength);
    setMedGenricName(data.Genericname);
    setMedTag(data.Tag);
    setExpiryDate(data.expiredate);
    setBuyDate(data.buydate === null ? new Date() : data.buydate);
    console.log(data.buydate);
    console.log(data.expiredate);
    console.log(buyDate);
    document.getElementById("SelectBtn" + index).disabled = true;
    document.getElementById("SelectBtn" + index).innerText = text_selected;
    document
      .getElementById("SelectBtn" + index)
      .classList.replace("searchBtn", "SelectedBtn");
    setAddBtnShow(true);

    setErrAddMedicine("");
    for (let i = 0; i < searchFilterTemp.length; i++) {
      if (searchFilterTemp[i]._id !== data._id) {
        document.getElementById("SelectBtn" + i).disabled = false;
        document.getElementById("SelectBtn" + i).innerText = text_btn_select;
        document
          .getElementById("SelectBtn" + i)
          .classList.replace("SelectedBtn", "searchBtn");
      }
    }
  };

  const buyDateChange = (date, index) => {
    var temp = [...searchFilterTemp];
    var temp2 = _.cloneDeep(temp);
    temp2[index]["buydate"] = new Date(date);
    setsearchFilterTemp(temp2);
  };
  const expireDateChange = (date, index) => {
    console.log(date);
    var temp = [...searchFilterTemp];
    var temp2 = _.cloneDeep(temp);

    temp2[index]["expiredate"] = new Date(date);
    setsearchFilterTemp(temp2);
  };

  const skipBtn = () => {
    setDeviceCustomName("");
    setSerialNumber("");
    setMedCmpName("");
    setMedBrandName("");
    setMedGenricName("");
    setMedStrength("");
    setMedDose("");
    setSearchedMedName("");
    setDeviceCustomName("");
    sessionStorage.removeItem("deviceID");
    sessionStorage.removeItem("MedicineID");
    navigate("/dashboard");
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_hello(
      xpath.select1("/resources/string[@name='text_hello']", doc).firstChild
        .data
    );
    setText_wc_braetheasy(
      xpath.select1("/resources/string[@name='text_wc_braetheasy']", doc)
        .firstChild.data
    );
    setText_reg_device_medicine(
      xpath.select1("/resources/string[@name='text_reg_device_medicine']", doc)
        .firstChild.data
    );
    setText_btn_skip(
      xpath.select1("/resources/string[@name='text_btn_skip']", doc).firstChild
        .data
    );
    setText_step(
      xpath.select1("/resources/string[@name='text_step']", doc).firstChild.data
    );

    setText_enter_device_detail(
      xpath.select1("/resources/string[@name='text_enter_device_detail']", doc)
        .firstChild.data
    );
    setText_device_custom_name(
      xpath.select1("/resources/string[@name='text_device_custom_name']", doc)
        .firstChild.data
    );
    setText_ph_custom_name(
      xpath.select1("/resources/string[@name='text_ph_custom_name']", doc)
        .firstChild.data
    );
    setText_serial_no(
      xpath.select1("/resources/string[@name='text_serial_no']", doc).firstChild
        .data
    );
    setText_ph_serial_no(
      xpath.select1("/resources/string[@name='text_ph_serial_no']", doc)
        .firstChild.data
    );
    setText_add_device(
      xpath.select1("/resources/string[@name='text_add_device']", doc)
        .firstChild.data
    );
    setText_dont_device(
      xpath.select1("/resources/string[@name='text_dont_device']", doc)
        .firstChild.data
    );
    setText_device_name(
      xpath.select1("/resources/string[@name='text_device_name']", doc)
        .firstChild.data
    );
    setText_enter_medicine_detail(
      xpath.select1(
        "/resources/string[@name='text_enter_medicine_detail']",
        doc
      ).firstChild.data
    );
    setText_buy_date(
      xpath.select1("/resources/string[@name='text_buy_date']", doc).firstChild
        .data
    );
    setText_medicine_later(
      xpath.select1("/resources/string[@name='text_medicine_later']", doc)
        .firstChild.data
    );
    setText_upc_code(
      xpath.select1("/resources/string[@name='text_upc_code']", doc).firstChild
        .data
    );
    setText_product_name(
      xpath.select1("/resources/string[@name='text_product_name']", doc)
        .firstChild.data
    );
    setText_search_upc_product(
      xpath.select1("/resources/string[@name='text_search_upc_product']", doc)
        .firstChild.data
    );
    setText_btn_search(
      xpath.select1("/resources/string[@name='text_btn_search']", doc)
        .firstChild.data
    );
    setText_add_manually(
      xpath.select1("/resources/string[@name='text_add_manually']", doc)
        .firstChild.data
    );
    setText_company_name(
      xpath.select1("/resources/string[@name='text_company_name']", doc)
        .firstChild.data
    );
    setText_ph_company_name(
      xpath.select1("/resources/string[@name='text_ph_company_name']", doc)
        .firstChild.data
    );
    setText_brand_name(
      xpath.select1("/resources/string[@name='text_brand_name']", doc)
        .firstChild.data
    );
    setText_ph_brand_name(
      xpath.select1("/resources/string[@name='text_ph_brand_name']", doc)
        .firstChild.data
    );
    setText_generic_name(
      xpath.select1("/resources/string[@name='text_generic_name']", doc)
        .firstChild.data
    );
    setText_ph_generic_name(
      xpath.select1("/resources/string[@name='text_ph_generic_name']", doc)
        .firstChild.data
    );
    setText_strength(
      xpath.select1("/resources/string[@name='text_strength']", doc).firstChild
        .data
    );
    setText_ph_strength(
      xpath.select1("/resources/string[@name='text_ph_strength']", doc)
        .firstChild.data
    );
    setText_doses(
      xpath.select1("/resources/string[@name='text_doses']", doc).firstChild
        .data
    );
    setText_ph_doses(
      xpath.select1("/resources/string[@name='text_ph_doses']", doc).firstChild
        .data
    );
    setText_tag(
      xpath.select1("/resources/string[@name='text_tag']", doc).firstChild.data
    );
    setText_expiry_date(
      xpath.select1("/resources/string[@name='text_expiry_date']", doc)
        .firstChild.data
    );
    setText_search_medicine(
      xpath.select1("/resources/string[@name='text_search_medicine']", doc)
        .firstChild.data
    );
    setText_no_medicine(
      xpath.select1("/resources/string[@name='text_no_medicine']", doc)
        .firstChild.data
    );
    setText_btn_select(
      xpath.select1("/resources/string[@name='text_btn_select']", doc)
        .firstChild.data
    );
    setText_add_medicine(
      xpath.select1("/resources/string[@name='text_add_medicine']", doc)
        .firstChild.data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_pair(
      xpath.select1("/resources/string[@name='btn_pair']", doc).firstChild.data
    );
    setBtn_save(
      xpath.select1("/resources/string[@name='btn_save']", doc).firstChild.data
    );
    setText_warn_medicine_detail(
      xpath.select1("/resources/string[@name='text_warn_medicine_detail']", doc)
        .firstChild.data
    );
    setText_warn_device_detail(
      xpath.select1("/resources/string[@name='text_warn_device_detail']", doc)
        .firstChild.data
    );
    setText_warn_device_medicine(
      xpath.select1("/resources/string[@name='text_warn_device_medicine']", doc)
        .firstChild.data
    );
    setText_selected(
      xpath.select1("/resources/string[@name='text_selected']", doc).firstChild
        .data
    );
    setText_succ_medicine_selected(
      xpath.select1(
        "/resources/string[@name='text_succ_medicine_selected']",
        doc
      ).firstChild.data
    );
    setText_warn_medicine_name(
      xpath.select1("/resources/string[@name='text_warn_medicine_name']", doc)
        .firstChild.data
    );
    setText_warn_search_medicine(
      xpath.select1("/resources/string[@name='text_warn_search_medicine']", doc)
        .firstChild.data
    );
    setText_select_expiry_date(
      xpath.select1("/resources/string[@name='Text_select_expiry_date']", doc)
        .firstChild.data
    );
    setText_select_buy_date(
      xpath.select1("/resources/string[@name='text_select_buy_date']", doc)
        .firstChild.data
    );
    setText_timezone(
      xpath.select1("/resources/string[@name='text_timezone']", doc).firstChild
        .data
    );
    setText_medicine_expiry(
      xpath.select1("/resources/string[@name='text_medicine_expiry']", doc)
        .firstChild.data
    );
    setText_alert_befor_expiry(
      xpath.select1("/resources/string[@name='text_alert_befor_expiry']", doc)
        .firstChild.data
    );
    setText_alert_time(
      xpath.select1("/resources/string[@name='text_alert_time']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clearSelectedData = () => {
    setMedBrandName("");
    setMedCmpName("");
    setMedDose("");
    setMedGenricName("");
    setExpiryDate();
    setBuyDate(new Date());
    setMedTag("");
    setMedStrength("");
  };

  const handleAlertSetting = () => {
    var medicineID = sessionStorage.getItem("MedicineID");
    var getHours = selectedTimeZone.slice(1, 3);
    var getMinitus = selectedTimeZone.slice(4, 6);
    var hourtoMinitus = Math.floor(getHours * 60);
    var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/alert/insertalert";
    var alertData = {
      _medicineId: medicineID,
      alertdatetime: "",
      alertTimeInDays: alertDays,
      alerttime: moment(AlertTime).format("hh:mm a"),
      timezoneoffset: FinalTimezoneOffset,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, alertData, headerConfig)
      .then(function (response) {
        console.log(response);
        setShowAlertData(false);
        successToast(response.data.message);
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          errorToast(err.response.data.message);
          console.log(err);
        }
      });
  };

  return (
    <>
      <Header />
      <div className={showAlertDate ? "blurBg p-3" : "p-3"}>
        <div className="px-3">
          <nav className="mt-2 text-center" id="DashboardMainPage">
            <h3>
              {text_hello}👋,
              {sessionStorage.getItem("firstname") === null
                ? "User"
                : sessionStorage.getItem("firstname").charAt(0).toUpperCase() +
                  sessionStorage.getItem("firstname").slice(1) +
                  " " +
                  sessionStorage.getItem("lastname").charAt(0).toUpperCase() +
                  sessionStorage.getItem("lastname").slice(1)}
            </h3>
            <p>{text_wc_braetheasy}</p>
          </nav>
          <div>
            <div className="row">
              <div className="col-md-4"> </div>
              <div className="col-md-4">
                <h3 className="text-center">{text_reg_device_medicine}</h3>
              </div>

              <div className="col-md-3 text-end">
                <button className="cancelbtn skipbtn" onClick={skipBtn}>
                  {text_btn_skip}
                </button>{" "}
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-center flex-wrap">
            <h5>
              {text_step}: 1 <img src={handStep} className="stepImage" alt="" />
            </h5>
            <h4 className="mx-4">
              <b>{text_enter_device_detail}</b>
            </h4>
          </div>
          {NoDevice === false && (
            <>
              {" "}
              <div className="addDeviceFormBox ">
                <div className="addDeviceInput">
                  <h5>{text_device_custom_name}</h5>
                  <input
                    type="text"
                    value={deviceCustomName}
                    className="inputaddDevice"
                    placeholder={text_ph_custom_name}
                    onChange={(e) => setDeviceCustomName(e.target.value)}
                  />
                </div>
                <div className="addDeviceInput">
                  <h5>{text_serial_no}</h5>
                  <input
                    type="number"
                    onKeyDown={(evt) =>
                      evt.which !== 8 &&
                      evt.which !== 0 &&
                      (evt.which < 48 || evt.which > 57) &&
                      evt.preventDefault()
                    }
                    min={0}
                    value={serialNumber}
                    className="inputaddDevice"
                    placeholder={text_ph_serial_no}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  />
                </div>
                <div className="addDeviceInput ">
                  <h5>&nbsp;</h5>
                  <button
                    className="savebtn btn-width "
                    onClick={handleDeviceDetail}
                    disabled={addDevicebtn}
                  >
                    {text_add_device}
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="mt-2 text-center mb-5">
            <input
              type="checkbox"
              checked={NoDevice}
              onChange={(e) => [
                setNoDevice(e.target.checked),
                setDeviceCustomName(""),
                setSerialNumber(""),
                // setAddlater(false),
              ]}
            />{" "}
            <span> {text_dont_device}</span>
          </div>
          {/* {NoDevice === false && ( */}
          <>
            {deviceDetail.length > 0 &&
              deviceDetail.map((val) => {
                return (
                  <>
                    <div className="d-flex justify-content-center  mt-2">
                      <div className="row DeviceBox">
                        <div className="col-md-4 DeviceLogo">
                          <img src={DeviceLogo} className="imgSty" />
                        </div>
                        <br />
                        <div className="col-md-8">
                          <div className="d-flex flex-wrap">
                            <p className="medicinetext">{text_device_name}: </p>
                            &nbsp;&nbsp;
                            <p className="deviceNameText">
                              {val.Devicecustomname}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="medicinetext">{text_serial_no}:</p>
                            &nbsp;&nbsp;
                            <p className="deviceNameText">{val.Serialnumber}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </>
          {/* )} */}

          <hr className="mt-3" />
          <div className="d-flex justify-content-center flex-wrap">
            <h5>
              {text_step}: 2 <img src={handStep} className="stepImage" />
            </h5>
            <h4 className="mx-4">
              <b>{text_enter_medicine_detail}</b>
            </h4>
          </div>
          <div className="text-center">
            <input
              type="checkbox"
              checked={addMedLater}
              onChange={(e) => setAddMedLater(e.target.checked)}
            />{" "}
            <span>{text_medicine_later}!</span>
          </div>
          {addMedLater === false && (
            <>
              {" "}
              {addMedLater === false && (
                <>
                  {addmedicineManually === false && (
                    <>
                      <div className="mt-3 searchbox gap-3 flex-wrap">
                        <div className="mt-1">
                          <h5>
                            {text_upc_code}/{text_product_name}
                          </h5>
                        </div>{" "}
                        <div>
                          <input
                            type="text"
                            className="searchMedicineInput"
                            placeholder={text_search_upc_product}
                            value={SearchedMedName}
                            onChange={(e) => [
                              setSearchedMedName(e.target.value),
                              setAddBtnShow(false),
                              setErrSerachMedicine(""),
                            ]}
                          />
                          <p className="ErrMessage">{errSearchMedicine}</p>
                        </div>{" "}
                        <div className="searchboxbtn">
                          <button
                            className="searchBtn"
                            onClick={() => {
                              SearchedMedName === ""
                                ? setErrSerachMedicine(
                                    text_warn_search_medicine
                                  )
                                : dispatch(serachMedicine());
                            }}
                          >
                            <AiOutlineSearch className="me-2" />
                            {text_btn_search}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-center mt-4">
                    <input
                      type="checkbox"
                      onChange={() => [
                        setAddmedicineManually(!addmedicineManually),
                        clearSelectedData(),
                      ]}
                      checked={addmedicineManually}
                    />{" "}
                    <span>{text_add_manually}</span>
                  </div>
                  {/* medicine card */}
                  {addmedicineManually === true ? (
                    <>
                      <div className=" mt-3 manuallymedicineBox">
                        <div className=" addMedicineInput">
                          <h5>{text_company_name}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder={text_ph_company_name}
                            value={MedCmpName}
                            onChange={(e) => [
                              setMedCmpName(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                        <div className=" addMedicineInput">
                          <h5>{text_brand_name}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder={text_ph_brand_name}
                            value={MedBrandName}
                            onChange={(e) => [
                              setMedBrandName(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                        <div className=" addMedicineInput">
                          <h5>{text_generic_name}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder={text_ph_generic_name}
                            value={MedGenricName}
                            onChange={(e) => [
                              setMedGenricName(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                        <div className="  addMedicineInput">
                          <h5>{text_strength}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder={text_ph_strength}
                            value={MedStrength}
                            onChange={(e) => [
                              setMedStrength(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                      </div>
                      <div className=" mt-3  manuallymedicineBox">
                        <div className=" addMedicineInput">
                          <h5>{text_doses}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder={text_ph_doses}
                            value={MedDose}
                            onChange={(e) => [
                              setMedDose(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                        <div className="  addMedicineInput">
                          <h5>{text_tag}</h5>
                          <input
                            type="text"
                            className="inputaddDevice"
                            placeholder="e.g. B.P."
                            value={MedTag}
                            onChange={(e) => [
                              setMedTag(e.target.value),
                              setErrAddMedicine(""),
                            ]}
                          />
                        </div>
                        <div className="  addMedicineInput sharetodataDatepicker">
                          <h5>{text_buy_date}</h5>
                          <DatePicker
                            dateFormat="dd MMM, yyyy"
                            className="sharedtoinputdatepicker size-reg-page-date"
                            onChange={(date) => setBuyDate(date)}
                            selected={buyDate}
                            maxDate={buyDate}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete='off'
                            // dropdownMode="select"
                          />
                        </div>
                        <div className="  addMedicineInput sharetodataDatepicker">
                          <h5>{text_expiry_date}</h5>
                          <DatePicker
                            dateFormat="dd MMM, yyyy"
                            className=" sharedtoinputdatepicker size-reg-page-date"
                            onChange={(date) => setExpiryDate(date)}
                            selected={expiryDate}
                            placeholderText={Text_select_expiry_date}
                            dropdownMode="select"
                            minDate={new Date()}
                            showMonthDropdown
                            autoComplete='off'
                            showYearDropdown
                          />
                        </div>
                      </div>

                      <div className="text-center mt-2">
                        <p className="ErrMessage">{errAddMedicine}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      {MedicineDetail.length === 0 && (
                        <>
                          {SearchedMedName === "" && (
                            <h4 className="mt-3 searchText text-center">
                              {text_search_medicine}
                            </h4>
                          )}
                        </>
                      )}
                      <div className="mainBox">
                        <div className=" scroll_medicine">
                          {/* {console.log(first)} */}
                          {SearchedMedName !== "" &&
                          searchFilterTemp.length === 0 ? (
                            <h5 className="mt-3 ">{text_no_medicine}!</h5>
                          ) : (
                            ""
                          )}
                          {SearchedMedName !== "" && (
                            <>
                              {searchFilterTemp.length !== 0 &&
                                searchFilterTemp.map((d, index) => {
                                  return (
                                    <>
                                      <div className="medicineBox" key={d._id}>
                                        <p className="medicinetext">
                                          {d.Brandname}
                                        </p>
                                        <p className="medicinetext">
                                          {d.Companyname}
                                        </p>
                                        <p className="medicinetext">
                                          {d.Genericname}
                                        </p>
                                        <p className="medicinetext">
                                          {d.Strength}
                                        </p>
                                        <p className="medicinetext">{d.Dose}</p>
                                        <div className="addMedicineInput sharetodataDatepicker d-flex gap-2 mt-2">
                                          <p className="medicinetext">
                                            {text_buy_date}
                                          </p>
                                          <DatePicker
                                            dateFormat="dd MMM, yyyy"
                                            className="  registermedicine-date registermedicine-buy"
                                            onChange={(date) =>
                                              buyDateChange(date, index)
                                            }
                                            placeholderText={
                                              text_select_buy_date
                                            }
                                            selected={
                                              d.buydate === "" ||
                                              d.buydate === null ||
                                              d.buydate === undefined
                                                ? new Date()
                                                : d.buydate
                                            }
                                            maxDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                          />
                                        </div>
                                        <div className="addMedicineInput sharetodataDatepicker d-flex gap-2 mt-2">
                                          <p className="medicinetext">
                                            {text_expiry_date}
                                          </p>
                                          <DatePicker
                                            selected={d.expiredate}
                                            onChange={(date) =>
                                              expireDateChange(date, index)
                                            }
                                            autoComplete='off'
                                            dateFormat="dd MMM, yyyy"
                                            className="  registermedicine-date "
                                            id={"expiry-date" + index}
                                            placeholderText={
                                              Text_select_expiry_date
                                            }
                                            minDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                          />
                                        </div>
                                        <center>
                                          <button
                                            className="searchBtn selectByClass mt-3"
                                            id={"SelectBtn" + index}
                                            onClick={(e) => [
                                              selectedmedicine(d, index),
                                              console.log(index),
                                            ]}
                                          >
                                            {text_btn_select}
                                          </button>
                                        </center>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {SearchedMedName === "" && addmedicineManually === false && (
                <>
                  {MedicineDetail.length > 0 &&
                    MedicineDetail.map((val) => {
                      return (
                        <>
                          <div className="d-flex justify-content-center  mt-2">
                            <div className="row medicineBox">
                              {/* <div className="col-md-4 DeviceLogo">
                        <img src={DeviceLogo} className="imgSty" />
                      </div> */}
                              <br />
                              <div className="col-md-12">
                                <div className="d-flex flex-wrap">
                                  <p className="medicinetext">
                                    {text_company_name}:{" "}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.Companyname}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p className="medicinetext">
                                    {text_brand_name}:
                                  </p>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.Brandname}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p className="medicinetext">
                                    {text_generic_name}:
                                  </p>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.Genericname}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p className="medicinetext">{text_doses}:</p>
                                  &nbsp;&nbsp;
                                  <p className="deviceNameText">{val.Dose}</p>
                                </div>
                                <div className="d-flex">
                                  <p className="medicinetext">
                                    {text_strength}:
                                  </p>
                                  &nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.Strength}
                                  </p>
                                </div>

                                <div className="d-flex">
                                  <p className="medicinetext">
                                    {text_expiry_date}:
                                  </p>
                                  &nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.medicine_expiry === null
                                      ? ""
                                      : moment(val.medicine_expiry).format(
                                          "MMM DD, yyyy"
                                        )}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p className="medicinetext">
                                    {text_buy_date}:
                                  </p>
                                  &nbsp;&nbsp;
                                  <p className="deviceNameText">
                                    {val.buydate === null
                                      ? ""
                                      : moment(val.buydate).format(
                                          "MMM DD, yyyy"
                                        )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              )}
            </>
          )}

          <div className="text-center mt-2">
            <p className="ErrMessage">{errAddMedicine}</p>
          </div>

          {(addBtnShow === true ||
            addmedicineManually === true ||
            MedBrandName !== "") && (
            <>
              <div className="d-flex justify-content-center mt-4 gap-4 flex-wrap ">
                <button
                  className="savebtn btn-width-med mb-3"
                  onClick={handleMedicine}
                  disabled={addMedicinebtn}
                >
                  {text_add_medicine}
                </button>
              </div>{" "}
            </>
          )}

          <Divider />
          <div className="d-flex justify-content-center flex-wrap mt-3">
            <h5 className="step3Sty">
              {text_step}: 3 <img src={handStep} className="stepImage" />
            </h5>
            <div className="d-flex justify-content-center flex-wrap gap-5 mx-4">
              <button
                className="cancelbtn"
                onClick={() => [
                  navigate("/dashboard"),
                  sessionStorage.removeItem("deviceID"),
                  sessionStorage.removeItem("MedicineID"),
                ]}
              >
                {btn_cancel}
              </button>
              {MedicineDetail.length !== 0 && deviceDetail.length !== 0 ? (
                <button
                  className="savebtn "
                  onClick={handlePairDevicetoMedicine}
                >
                  {btn_pair}
                </button>
              ) : (
                <button className="savebtn " onClick={() => handleSubmitData()}>
                  {btn_save}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showAlertDate && (
        <>
          <div className="Popup addmedicinePopup pairRefillPopup">
            <div className="text-end">
              <CgCloseO size={25} onClick={() => setShowAlertData(false)} />
            </div>
            <h3 className="text-center">
              <>{text_medicine_expiry}</>
            </h3>
            <Divider />
            <h5 className="text-center mt-3">
              {MedicineDetail.map((v) => v.Genericname)}
            </h5>
            <div className="d-flex gap-3 justify-content-left mt-2"></div>

            <div className="text-center mt-2">
              <h5>{text_alert_befor_expiry}</h5>
              <div className="d-flex justify-content-center">
                <input className=" input-number-in-days" value="In Days:" />
                <input
                  type="number"
                  onKeyDown={(evt) =>
                    evt.which !== 8 &&
                    evt.which !== 0 &&
                    (evt.which < 48 || evt.which > 57) &&
                    evt.preventDefault()
                  }
                  className="input-number-alert"
                  max={10}
                  min={0}
                  onChange={(e) => setAlertDays(e.target.value)}
                  value={alertDays}
                />
              </div>
            </div>
            <div className="d-flex gap-5 mx-4">
              <div className=" mt-2 mb-3 mx-3 " id="alert-time">
                <h5>{text_alert_time}</h5>
                <ReactDatePicker
                  className="frequencyInput "
                  onChange={(date, id) => setAlertTime(date)}
                  disableDayPicker
                  format="hh:mm a"
                  plugins={[<TimePicker hideSeconds />]}
                  value={new Date(AlertTime).getTime()}
                />
              </div>
              <div className="mt-1 mb-2 mx-4">
                <h5>{text_timezone} </h5>
                <select
                  className="timezone-addmedicine timezone-sty"
                  onChange={(e) => setSelectedTimeZone(e.target.value)}
                >
                  {FinalData?.map((val, i) => {
                    return (
                      <>
                        <option value={val?.utc}>{val?.label} </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            <Divider />
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                className="cancelbtn"
                onClick={() => setShowAlertData(false)}
              >
                {btn_cancel}
              </button>
              <button className="savebtn" onClick={() => handleAlertSetting()}>
                {btn_save}
              </button>
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default RegisterDeviceMedicine;
