import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GuardedRoutes = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    var isValidUser = sessionStorage.getItem("token");
    if (
      isValidUser === null ||
      isValidUser === "undefined" ||
      isValidUser === ""
    ) {
      navigate("/");
      sessionStorage.clear();
      localStorage.clear();
    }
  });
  return (
    <div>
      <Component />
    </div>
  );
};

export default GuardedRoutes;
