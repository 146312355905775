import React, { useEffect, useState } from "react";
import "./MyMedicine.css";
import Header from "../Header/Header";
import DataTable from "react-data-table-component";
import { customTableStyles } from "../../utils/DataTableStyle";
import { Divider } from "@mui/material";
import Cookie from "js-cookie";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import Loader from "../../utils/Loader";
import { errorToast, warnToast } from "../../utils/ToasterUtils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getPriorMedicine } from "../../redux/MyMedicineSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function MyMedicine() {
  const dispatch = useDispatch();
  const PriorMedicineData = useSelector((state) => state.mymedicineprior);
  //language Variable start
  const [text_schedule_id, setText_schedule_id] = useState("Schedule ID");
  const [text_brand_name, setText_brand_name] = useState("Brand Name");
  const [text_product_name, setText_product_name] = useState("Product Name");
  const [text_generic_name, setText_generic_name] = useState("Generic Name");
  const [text_strength, setText_strength] = useState("Strength");
  const [text_doses, setText_doses] = useState("Doses");
  const [text_expiry_date, setText_expiry_date] = useState("Expiry Date");
  const [text_start_date, setText_start_date] = useState("Start Date");
  const [text_end_date, setText_end_date] = useState("End Date");
  const [text_view_schedule, setText_view_schedule] = useState("View Schedule");
  const [text_current_medicine, setText_current_medicine] =
    useState("Current Medicine");
  const [text_prior_medicine, setText_prior_medicine] = useState(
    "Prior Medicine History"
  );
  const [text_as_needed, setText_as_needed] = useState("As Needed");
  const [text_medicine_schedule, setText_medicine_schedule] =
    useState("Medicine Schedule");
  const [text_morning, setText_morning] = useState("Morning");
  const [text_afternoon, setText_afternoon] = useState("Afternoon");
  const [text_evening, setText_evening] = useState("Evening");
  const [text_monday, setText_monday] = useState("Monday");
  const [text_tuesday, setText_tuesday] = useState("Tuesday");
  const [text_wednesday, setText_wednesday] = useState("Wednesday");
  const [text_thursday, setText_thursday] = useState("Thursday");
  const [text_friday, setText_friday] = useState("Friday");
  const [text_saturday, setText_saturday] = useState("Saturday");
  const [text_sunday, setText_sunday] = useState("Sunday");
  const [text_close, setText_close] = useState("Close");
  const [text_buy_date, setText_buy_date] = useState("Buy Date");
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_warntoast, setText_warntoast] = useState(
    "No Schedule For This Medicine"
  );
  const [text_current_dose, setText_current_dose] = useState(
    "Current Available Dose"
  );
  const [Text_medicine_pair_with, setText_medicine_pair_with] = useState(
    "Medicine Pair With Device"
  );
  const [text_Total_doses, setText_total_doses] = useState("Total Doses");
  const [text_number_of_puff_dose, setText_number_of_puff_dose] = useState(
    "Number of Puff/Dose"
  );
  const [text_timing, setText_timing] = useState("Timing");
  const [text_viewusage, setText_viewusage] = useState("View Usage");
  const [text_night, setText_night] = useState("Night");
  const [text_schedule_name, setText_schedule_name] = useState("Schedule Name");

  //language Varible end
  const UserID = sessionStorage.getItem("user_id");
  const navigate = useNavigate();
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [medicineScheduleAllData, setMedicineScheduleAllData] = useState([]);
  const [scheduleID, setScheduleID] = useState("");
  const [currentMedicineId, setCurrentMedicineId] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [showMorning, setShowMorning] = useState(false);
  const [showAfternoon, setShowAfternoon] = useState(false);
  const [showEvening, setShowEvening] = useState(false);

  const currentMedicineStyle = {
    rows: {
      style: {
        fontSize: "16px",
        paddingInlineStart: "5px",
        cursor: "pointer",
        backgroundColor: "#d1eccf",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "uppercase",
        backgroundColor: "#bfd8ef",
        color: "#303847",
      },
    },
    title: {
      style: {
        overflow: "none",
      },
    },
  };
  const [filterText, setFilterText] = useState("");
  // const [PriorMedicineData, setPriorMedicineData] = useState([]);
  const [CurrentMedicineData, setCurrentMedicineData] = useState([]);

  const getCurrentMedicine = () => {
    var MedicineID = sessionStorage.getItem("medicineID");
    if (MedicineID === "" || MedicineID === null) {
      setIsLoading(false);
      console.log("No Medicine Added");
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/medicine/addmedicine/select?_id=" +
        MedicineID;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            setIsLoading(false);
            const data = response.data.data;
            var FinalData = [];
            FinalData.push(data);
            setCurrentMedicineData(FinalData);
            setCurrentMedicineId(FinalData[0]._id);
          }
        })
        .catch(function (err) {
          errorToast(err.response.data.message);
          if (err.response.status === 427) {
            setIsLoading(false);
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
          }
        });
    }
  };
  useEffect(() => {
    if (PriorMedicineData) {
      dispatch(getPriorMedicine());
    }
  }, [currentMedicineId]);

  useEffect(() => {
    getCurrentMedicine();
  }, []);

  const filterDataOfMedicine = PriorMedicineData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const columnsOfMedicine = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_schedule_id}</>,
      selector: (row) => (
        <>
          {row._schedule_Id === "" || row._schedule_Id === null
            ? "-"
            : row._schedule_Id}
        </>
      ),
      width: "150px",
    },
    {
      name: <>{text_brand_name}</>,
      selector: (row) => row.Companyname,
      width: "150px",
    },
    {
      name: <>{text_product_name}</>,
      selector: (row) => row.Brandname,
    },
    {
      name: <>{text_generic_name}</>,
      selector: (row) => row.Genericname,
      width: "185px",
    },
    {
      name: <>{text_strength}</>,
      selector: (row) => row.Strength,
      width: "150px",
    },
    {
      name: <>{text_buy_date}</>,
      selector: (row) => (
        <>
          {row.buydate === "" || row.buydate === null
            ? "-"
            : moment(row.buydate).format("MMM DD, yyyy")}
        </>
      ),
      width: "150px",
    },
    {
      name: <>{text_expiry_date}</>,
      selector: (row) =>
        row.medicine_expiry === "" || row.medicine_expiry === null
          ? "-"
          : moment(row.medicine_expiry).format("MMM DD, yyyy"),
      width: "150px",
    },
    {
      name: <>{text_start_date}</>,
      selector: (row) => (
        <span>
          {row.startdate === "" ||
          row.startdate === null ||
          row.startdate === undefined
            ? "-"
            : moment(row.startdate).format("MMM DD, yyyy")}
        </span>
      ),
      width: "150px",
    },
    {
      name: <>{text_end_date}</>,
      selector: (row) => (
        <>
          {row.enddate === "" ||
          row.enddate === null ||
          row.enddate === undefined
            ? "-"
            : moment(row.enddate).format("MMM DD, yyyy")}
        </>
      ),
    },
    {
      name: <>{text_Total_doses}</>,
      selector: (row) => row.Dose,
      width: "120px",
    },
    {
      name: <>{text_current_dose}</>,
      selector: (row) => <span className="text-danger">{row.doseleft}</span>,
      width: "120px",
    },
    {
      name: <>{text_medicine_schedule}</>,
      width: "173px",
      selector: (row) => (
        <button
          className="viewschedulebtn"
          onClick={() => [
            getScheduledata(row._schedule_Id),
            setScheduleID(row._schedule_Id),
          ]}
        >
          {text_view_schedule}
        </button>
      ),
    },
    {
      name: <>{text_viewusage}</>,
      width: "173px",
      selector: (row) => (
        <button
          className="viewschedulebtn"
          onClick={() =>
            navigate("/priormedicine/history", {
              state: {
                data: row._id,
                MedicineName: row.Genericname,
                deviceName: row.paired_devicename,
              },
            })
          }
        >
          <>{text_viewusage}</>
        </button>
      ),
    },
  ];
  const isDayTimingValidation = () => {
    var DayTiming = medicineScheduleAllData.doc0;
    if (DayTiming.isMondayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isTuesdayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isWednesdayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isThursdayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isFridayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isSaturdayMorning === true) {
      setShowMorning(true);
    }
    if (DayTiming.isSundayMorning === true) {
      setShowMorning(true);
    }
    if (
      DayTiming.isMondayAfternoon === true ||
      DayTiming.isTuesdayAfternoon === true ||
      DayTiming.isWednesdayAfternoon === true ||
      DayTiming.isThursdayAfternoon === true ||
      DayTiming.isFridayAfternoon === true ||
      DayTiming.isSaturdayAfternoon === true ||
      DayTiming.isSundayAfternoon === true
    ) {
      setShowAfternoon(true);
    }
    if (
      DayTiming.isMondayEvening === true ||
      DayTiming.isTuesdayEvening === true ||
      DayTiming.isWednesdayEvening === true ||
      DayTiming.isThursdayEvening === true ||
      DayTiming.isFridayEvening === true ||
      DayTiming.isSaturdayEvening === true ||
      DayTiming.isSundayEvening === true
    ) {
      setShowEvening(true);
    }
  };
  const getScheduledata = (Id) => {
    if (Id === "" || Id === null) {
      warnToast(<>{text_warntoast}</>);
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/medicine/schedulemedicine/select?_userId=${UserID}&_id=${Id}`;
      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          var FinalData = [];
          var value = res.data.data;
          FinalData.push(value);
          var DayTiming = value.doc0;
          console.log(FinalData);
          setSchedulePopup(!schedulePopup);
          setMedicineScheduleAllData(FinalData);
          // isDayTimingValidation();
          console.log(value.tuesday[0].length);
          if (value.monday[0].length > 0) {
            if (DayTiming.isMondayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isMondayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isMondayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.tuesday[0].length > 0) {
            if (DayTiming.isTuesdayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isTuesdayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isTuesdayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.wednesday[0].length > 0) {
            if (DayTiming.isWednesdayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isWednesdayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isWednesdayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.thursday[0].length > 0) {
            if (DayTiming.isThursdayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isThursdayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isThursdayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.friday[0].length > 0) {
            if (DayTiming.isFridayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isFridayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isFridayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.saturday[0].length > 0) {
            if (DayTiming.isSaturdayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isSaturdayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isSaturdayEvening === true) {
              setShowEvening(true);
            }
          }
          if (value.sunday[0].length > 0) {
            if (DayTiming.isSundayMorning === true) {
              setShowMorning(true);
            }
            if (DayTiming.isSundayAfternoon === true) {
              setShowAfternoon(true);
            }
            if (DayTiming.isSundayEvening === true) {
              setShowEvening(true);
            }
          }
          // if((value.monday[0]).length!==0){
          //   setShowMorning(true);
          //   setShowAfternoon(true);
          //   setShowEvening(true);
          // }
          // if (
          //   DayTiming.isMondayMorning === true ||
          //   DayTiming.isSundayMorning === true ||

          //   DayTiming.isThursdayMorning === true ||
          //   DayTiming.isWednesdayMorning === true ||
          //   DayTiming.isFridayMorning === true ||
          //   DayTiming.isSaturdayMorning === true
          // ) {
          //   setShowMorning(true);
          // }
          // if (
          //   DayTiming.isMondayAfternoon === true ||
          //   DayTiming.isTuesdayAfternoon === true ||
          //   DayTiming.isWednesdayAfternoon === true ||
          //   DayTiming.isThursdayAfternoon === true ||
          //   DayTiming.isFridayAfternoon === true ||
          //   DayTiming.isSaturdayAfternoon === true ||
          //   DayTiming.isSundayAfternoon === true
          // ) {
          //   setShowAfternoon(true);
          // }
          // if (
          //   DayTiming.isMondayEvening === true ||
          //   DayTiming.isTuesdayEvening === true ||
          //   DayTiming.isWednesdayEvening === true ||
          //   DayTiming.isThursdayEvening === true ||
          //   DayTiming.isFridayEvening === true ||
          //   DayTiming.isSaturdayEvening === true ||
          //   DayTiming.isSundayEvening === true
          // ) {
          //   setShowEvening(true);
          // }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
          }
        });
    }
  };

  const columnsOfCurrentMedicine = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_schedule_id}</>,
      selector: (row) => (
        <>
          {row._schedule_Id === "" || row._schedule_Id === null
            ? "-"
            : row._schedule_Id}
        </>
      ),
    },
    {
      name: <>{text_brand_name}</>,
      selector: (row) => row.Companyname,
    },
    {
      name: <>{text_product_name}</>,
      selector: (row) => row.Brandname,
    },
    {
      name: <>{text_generic_name}</>,
      selector: (row) => row.Genericname,
      width: "185px",
    },
    {
      name: <>{text_strength}</>,
      selector: (row) => row.Strength,
      width: "150px",
    },
    {
      name: <>{text_buy_date}</>,
      selector: (row) => (
        <>
          {row.buydate === "" || row.buydate === null
            ? "-"
            : moment(row.buydate).format("MMM DD, yyyy")}
        </>
      ),
    },
    {
      name: <>{text_expiry_date}</>,
      selector: (row) =>
        row.medicine_expiry === "" || row.medicine_expiry === null
          ? "-"
          : moment(row.medicine_expiry).format("MMM DD, yyyy"),
    },
    {
      name: <>{text_start_date}</>,
      selector: (row) => (
        <>
          {row.startdate === "" ||
          row.startdate === null ||
          row.startdate === undefined
            ? "-"
            : moment(row.startdate).format("MMM DD, yyyy")}
        </>
      ),
    },
    {
      name: <>{text_Total_doses}</>,
      selector: (row) => row.Dose,
      width: "120px",
    },
    {
      name: <>{text_current_dose}</>,
      selector: (row) => <span className="text-danger">{row.doseleft}</span>,
      width: "120px",
    },

    {
      name: <>{Text_medicine_pair_with}</>,
      selector: (row) => (
        <>{row.paired_devicename === "" ? "-" : row.paired_devicename} </>
      ),
      width: "120px",
    },
    {
      name: <>{text_medicine_schedule}</>,
      width: "173px",
      selector: (row) => (
        <button
          className="viewschedulebtn"
          onClick={() => [
            getScheduledata(row._schedule_Id),
            setScheduleID(row._schedule_Id),
          ]}
        >
          {text_view_schedule}
        </button>
      ),
    },
  ];
  const filterDataOfCurrentMedicine = CurrentMedicineData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_schedule_id(
      xpath.select1("/resources/string[@name='text_schedule_id']", doc)
        .firstChild.data
    );
    setText_brand_name(
      xpath.select1("/resources/string[@name='text_brand_name']", doc)
        .firstChild.data
    );
    setText_product_name(
      xpath.select1("/resources/string[@name='text_product_name']", doc)
        .firstChild.data
    );
    setText_generic_name(
      xpath.select1("/resources/string[@name='text_generic_name']", doc)
        .firstChild.data
    );
    setText_strength(
      xpath.select1("/resources/string[@name='text_strength']", doc).firstChild
        .data
    );
    setText_doses(
      xpath.select1("/resources/string[@name='text_doses']", doc).firstChild
        .data
    );
    setText_expiry_date(
      xpath.select1("/resources/string[@name='text_expiry_date']", doc)
        .firstChild.data
    );
    setText_start_date(
      xpath.select1("/resources/string[@name='text_start_date']", doc)
        .firstChild.data
    );
    setText_end_date(
      xpath.select1("/resources/string[@name='text_end_date']", doc).firstChild
        .data
    );
    setText_view_schedule(
      xpath.select1("/resources/string[@name='text_view_schedule']", doc)
        .firstChild.data
    );
    setText_current_medicine(
      xpath.select1("/resources/string[@name='text_current_medicine']", doc)
        .firstChild.data
    );
    setText_prior_medicine(
      xpath.select1("/resources/string[@name='text_prior_medicine']", doc)
        .firstChild.data
    );
    setText_as_needed(
      xpath.select1("/resources/string[@name='text_as_needed']", doc).firstChild
        .data
    );
    setText_medicine_schedule(
      xpath.select1("/resources/string[@name='text_medicine_schedule']", doc)
        .firstChild.data
    );
    setText_morning(
      xpath.select1("/resources/string[@name='text_morning']", doc).firstChild
        .data
    );
    setText_afternoon(
      xpath.select1("/resources/string[@name='text_afternoon']", doc).firstChild
        .data
    );
    setText_evening(
      xpath.select1("/resources/string[@name='text_evening']", doc).firstChild
        .data
    );
    setText_monday(
      xpath.select1("/resources/string[@name='text_monday']", doc).firstChild
        .data
    );
    setText_tuesday(
      xpath.select1("/resources/string[@name='text_tuesday']", doc).firstChild
        .data
    );
    setText_wednesday(
      xpath.select1("/resources/string[@name='text_wednesday']", doc).firstChild
        .data
    );
    setText_thursday(
      xpath.select1("/resources/string[@name='text_thursday']", doc).firstChild
        .data
    );
    setText_friday(
      xpath.select1("/resources/string[@name='text_friday']", doc).firstChild
        .data
    );
    setText_saturday(
      xpath.select1("/resources/string[@name='text_saturday']", doc).firstChild
        .data
    );
    setText_sunday(
      xpath.select1("/resources/string[@name='text_sunday']", doc).firstChild
        .data
    );
    setText_close(
      xpath.select1("/resources/string[@name='text_close']", doc).firstChild
        .data
    );
    setText_buy_date(
      xpath.select1("/resources/string[@name='text_buy_date']", doc).firstChild
        .data
    );
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_warntoast(
      xpath.select1("/resources/string[@name='text_warntoast']", doc).firstChild
        .data
    );
    setText_current_dose(
      xpath.select1("/resources/string[@name='text_current_dose']", doc)
        .firstChild.data
    );
    setText_medicine_pair_with(
      xpath.select1("/resources/string[@name='Text_medicine_pair_with']", doc)
        .firstChild.data
    );
    setText_total_doses(
      xpath.select1("/resources/string[@name='text_Total_doses']", doc)
        .firstChild.data
    );
    setText_number_of_puff_dose(
      xpath.select1("/resources/string[@name='text_number_of_puff_dose']", doc)
        .firstChild.data
    );
    setText_timing(
      xpath.select1("/resources/string[@name='text_timing']", doc).firstChild
        .data
    );
    setText_viewusage(
      xpath.select1("/resources/string[@name='text_viewusage']", doc).firstChild
        .data
    );
    setText_night(
      xpath.select1("/resources/string[@name='text_night']", doc).firstChild
        .data
    );
    setText_schedule_name(
      xpath.select1("/resources/string[@name='text_schedule_name']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <Header />
      <div className={schedulePopup === true ? "mt-4 p-4 blurBg" : "mt-4 p-4"}>
        {IsLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader />
          </div>
        ) : (
          <>
            <h4 className="text-center">{text_current_medicine}</h4>
            <Divider />
            <div className="mt-4 px-4">
              <DataTable
                columns={columnsOfCurrentMedicine}
                className="current-medicine"
                data={filterDataOfCurrentMedicine}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
                customStyles={currentMedicineStyle}
              />
            </div>
            <br />
            <div className="mt-5">
              <h4 className="text-center">{text_prior_medicine}</h4>
              <Divider />
              <div className="mt-4 px-4">
                <DataTable
                  columns={columnsOfMedicine}
                  data={filterDataOfMedicine}
                  fixedHeader
                  pagination
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={customTableStyles}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {schedulePopup ? (
        <>
          {medicineScheduleAllData.length > 0 &&
            medicineScheduleAllData.map((val) => {
              return (
                <>
                  {val.doc0.Asneeded === true ? (
                    <>
                      <div className="main schedulepopup_asneeded">
                        <div className="text-end">
                          <h3 className="close mb-1 p-0" id="closeMob">
                            <CgCloseO onClick={() => setSchedulePopup(false)} />
                          </h3>
                          <center>
                            <h4 className="">{text_medicine_schedule}</h4>
                          </center>
                        </div>
                        <div className="scheduleboxpopup-asneeded mt-4">
                          <div className=" text-start mt-2 asneedchekstypopup ">
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                className="me-2"
                                checked={val.doc0.Asneeded}
                              />
                              <p className="me-2 mt-3 text-as-needed">
                                {text_as_needed}
                              </p>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="mt-4 cancelbtn"
                            onClick={() => setSchedulePopup(false)}
                          >
                            {text_close}
                          </button>
                        </center>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="main schedulepopup" id="medicinescroll">
                        <div className="text-end">
                          <h3 className="close mb-1 p-0" id="closeMob">
                            <CgCloseO onClick={() => setSchedulePopup(false)} />
                          </h3>
                          <center>
                            <h4 className="">{text_medicine_schedule}</h4>
                          </center>
                        </div>
                        <div className="scheduleboxpopup mt-4 ">
                          <div className="d-flex justify-content-center flex-wrap gap-4">
                            <div className="text-start">
                              <h6 className="mt-2 text-start">
                                {text_schedule_id}{" "}
                              </h6>
                              <input
                                type="text"
                                className="scheduleinputmymedicine"
                                value={scheduleID === null ? "" : scheduleID}
                              />
                            </div>
                            <div className="text-start">
                              <h6 className="mt-2 text-start">
                                {text_schedule_name}{" "}
                              </h6>
                              <input
                                type="text"
                                className="scheduleinputmymedicine"
                                value={
                                  val?.doc0?.schedule_name === null
                                    ? ""
                                    : val?.doc0?.schedule_name
                                }
                              />
                            </div>
                            <div className="mt-2 ">
                              <h6 className="text-start">{text_start_date}</h6>
                              <input
                                className="scheduleinputmymedicine"
                                value={moment(val?.doc0?.startdate).format(
                                  "MMM DD, yyyy"
                                )}
                              />
                            </div>
                            <div className=" mt-2">
                              <h6 className="text-start">{text_end_date}</h6>
                              <input
                                className="scheduleinputmymedicine"
                                value={moment(val?.doc0?.enddate).format(
                                  "MMM DD, yyyy"
                                )}
                              />
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="row justify-content-md-center  ">
                              <hr className="monhrfirst mob-hr-mymedicine" />
                              {/* ---------------------Monday------------------------- */}
                              {val?.doc0?.ismonday === true && (
                                <>
                                  {(val?.monday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.ismonday}
                                          />
                                          <label>{text_monday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isMondayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0?.isMondayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4 ">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.monday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75 "
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine ">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.mondayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isMondayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isMondayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.monday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4 ">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.monday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75  "
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isMondayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isMondayMorning ===
                                                true ||
                                              val?.doc0?.isMondayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.monday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.monday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75 "
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isMondayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isMondayMorning ===
                                                true ||
                                              val?.doc0?.isMondayAfternoon ===
                                                true ||
                                              val?.doc0?.isMondayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.monday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isMondayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.monday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.mondayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}

                              {/* ------------------------------------------------- */}

                              {/* ---------------------Tuesday----------------------------- */}

                              {val?.doc0?.istuesday === true && (
                                <>
                                  {val?.tuesday[0].length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.istuesday}
                                          />
                                          <label>{text_tuesday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isTuesdayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0
                                                        ?.isTuesdayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.tuesday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.tuesdayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isTuesdayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isTuesdayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.tuesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.tuesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isTuesdayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isTuesdayAfternoon ===
                                                true ||
                                              val?.doc0?.isTuesdayMorning ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.tuesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.tuesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isTuesdayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isTuesdayAfternoon ===
                                                true ||
                                              val?.doc0?.isTuesdayMorning ===
                                                true ||
                                              val?.doc0?.isTuesdayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.tuesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isTuesdayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.tuesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.tuesdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}
                              {/* -------------------------------------------- */}

                              {/* ------------Wednsday-------------- */}

                              {val?.doc0?.iswednesday === true && (
                                <>
                                  {(val?.wednesday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.iswednesday}
                                          />
                                          <label>{text_wednesday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isWednesdayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0
                                                        ?.isWednesdayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.wednesday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.wednesdayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isWednesdayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isWednesdayMorning ===
                                              true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.wednesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.wednesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isWednesdayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isWednesdayMorning ===
                                                true ||
                                              val?.doc0
                                                ?.isWednesdayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.wednesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.wednesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {val?.doc0?.isWednesdayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isWednesdayMorning ===
                                                true ||
                                              val?.doc0
                                                ?.isWednesdayAfternoon ===
                                                true ||
                                              val?.doc0?.isWednesdayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.wednesday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isWednesdayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.wednesday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.wednesdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}

                              {/* -------------------------- */}

                              {/* ------------Thursday--------------- */}
                              {val?.doc0?.isthursday === true && (
                                <>
                                  {(val?.thursday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.isthursday}
                                          />
                                          <label>{text_thursday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isThursdayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0
                                                        ?.isThursdayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.thursday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.thursdayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isThursdayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isThursdayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.thursday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.thursday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isThursdayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isThursdayMorning ===
                                                true ||
                                              val?.doc0?.isThursdayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.thursday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.thursday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {val?.doc0?.isThursdayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isThursdayMorning ===
                                                true ||
                                              val?.doc0?.isThursdayAfternoon ===
                                                true ||
                                              val?.doc0?.isThursdayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.thursday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isThursdayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.thursday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.thursdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}

                              {/* -------------------------- */}

                              {/* -------------Friday-------------- */}

                              {val?.doc0?.isfriday === true && (
                                <>
                                  {(val?.friday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.isfriday}
                                          />
                                          <label>{text_friday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isFridayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0?.isFridayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.friday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.fridayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isFridayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isFridayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.friday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.friday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isFridayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isFridayMorning ===
                                                true ||
                                              val?.doc0?.isFridayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.friday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.friday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {val?.doc0?.isFridayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isFridayMorning ===
                                                true ||
                                              val?.doc0?.isFridayAfternoon ===
                                                true ||
                                              val?.doc0?.isFridayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.friday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isFridayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.friday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.fridayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}

                              {/* -------------------------- */}

                              {/* ------------Saturday--------------- */}
                              {val?.doc0?.issaturday === true && (
                                <>
                                  {(val?.saturday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.issaturday}
                                          />
                                          <label>{text_saturday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isSaturdayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0
                                                        ?.isSaturdayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.saturday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.saturdayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isSaturdayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSaturdayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.saturday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.saturday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isSaturdayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSaturdayMorning ===
                                                true ||
                                              val?.doc0?.isSaturdayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.saturday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.saturday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {val?.doc0?.isSaturdayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSaturdayMorning ===
                                                true ||
                                              val?.doc0?.isSaturdayAfternoon ===
                                                true ||
                                              val?.doc0?.isSaturdayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.saturday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSaturdayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.saturday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.saturdayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                      <hr className="monhr mob-hr-mymedicine" />
                                    </>
                                  )}
                                </>
                              )}

                              {/* -------------------------- */}

                              {/* ----------------Sunday----------------- */}

                              {val?.doc0?.issunday === true && (
                                <>
                                  {(val?.sunday[0]).length > 0 && (
                                    <>
                                      <div className="dayDiv mt-2 d-flex justify-content-flex gap-4 flex-wrap">
                                        <div className="check-day-sty-medicine">
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={val?.doc0?.issunday}
                                          />
                                          <label>{text_sunday}</label>
                                        </div>
                                        <div className="selecttimeDiv_mymedicine ">
                                          {val?.doc0?.isSundayMorning ===
                                            true && (
                                            <>
                                              <div className="days-box-outer">
                                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      val?.doc0?.isSundayMorning
                                                    }
                                                  />
                                                  <h5 className="mt-4">
                                                    {text_morning}
                                                  </h5>
                                                </div>
                                                <div className="mx-3 days-box-inner ">
                                                  <div className="monarrinput mx-1">
                                                    <div>
                                                      <h6 className="dose-text">
                                                        {text_timing}
                                                      </h6>

                                                      {val?.sunday[0]
                                                        .filter(
                                                          (e) =>
                                                            e.slot === "morning"
                                                        )
                                                        .slice(0, 1)
                                                        .map((e) => {
                                                          return (
                                                            <>
                                                              <input
                                                                className="w-75"
                                                                id="timing-mob"
                                                                value={e.Time}
                                                              />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>

                                                  <br />
                                                  <div className="mobdose-dose_medicine-mymedicine">
                                                    <h6 className="dose-text">
                                                      {text_number_of_puff_dose}
                                                    </h6>

                                                    <input
                                                      className="select-doese_medicine mob-dose-sty"
                                                      value={
                                                        val?.doc0
                                                          ?.sundayMorningDoseCount
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isSundayAfternoon ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSundayMorning ===
                                              true ? (
                                                <>
                                                  <div className="d-flex flex-wrap gap-4">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayAfternoon
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.sunday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "afternoon"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayAfternoon
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_afternoon}
                                                      </h5>
                                                    </div>
                                                    <div className=" days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.sunday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "afternoon"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayAfternoonDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}

                                          <br />
                                          {val?.doc0?.isSundayEvening ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSundayMorning ===
                                                true ||
                                              val?.doc0?.isSundayAfternoon ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayEvening
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.sunday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot ===
                                                              "evening"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-evening"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer mob-days-box">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayEvening
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_evening}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div className="evening-timing-title">
                                                          <h6 className="dose-text">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.sunday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "evening"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayEveningDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {val?.doc0?.isSundayNight ===
                                            true && (
                                            <>
                                              {val?.doc0?.isSundayMorning ===
                                                true ||
                                              val?.doc0?.isSundayAfternoon ===
                                                true ||
                                              val?.doc0?.isSundayEvening ===
                                                true ? (
                                                <>
                                                  {" "}
                                                  <div className="d-flex flex-wrap gap-4 mob-evening-sty">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayNight
                                                        }
                                                      />
                                                      <h5 className="text-afternoon">
                                                        {" "}
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="d-flex gap-3 mob-div">
                                                      <div>
                                                        {val?.sunday[0]
                                                          .filter(
                                                            (e) =>
                                                              e.slot === "night"
                                                          )
                                                          .slice(0, 1)
                                                          .map((e) => {
                                                            return (
                                                              <>
                                                                {" "}
                                                                <input
                                                                  value={e.Time}
                                                                  className="input-timing-night"
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </div>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="dose-input"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="days-box-outer  mob-days-box night-days-sty">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2 mob-evening-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="evening-checkbox-mob"
                                                        checked={
                                                          val?.doc0
                                                            ?.isSundayNight
                                                        }
                                                      />
                                                      <h5 className="mt-4">
                                                        {text_night}
                                                      </h5>
                                                    </div>
                                                    <div className="mx-3 days-box-inner ">
                                                      <div className="monarrinput mx-1">
                                                        <div>
                                                          <h6 className="evening-timing-title">
                                                            {text_timing}
                                                          </h6>

                                                          {val?.sunday[0]
                                                            .filter(
                                                              (e) =>
                                                                e.slot ===
                                                                "night"
                                                            )
                                                            .slice(0, 1)
                                                            .map((e) => {
                                                              return (
                                                                <>
                                                                  <input
                                                                    className="w-75"
                                                                    id="timing-mob"
                                                                    value={
                                                                      e.Time
                                                                    }
                                                                  />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </div>

                                                      <br />
                                                      <div className="mobdose-dose_medicine-mymedicine">
                                                        <h6 className="dose-text">
                                                          {
                                                            text_number_of_puff_dose
                                                          }
                                                        </h6>

                                                        <input
                                                          className="select-doese_medicine mob-dose-sty"
                                                          value={
                                                            val?.doc0
                                                              ?.sundayNightDoseCount
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}

                              {/* --------------------------------- */}
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="mt-4 cancelbtn"
                            onClick={() => setSchedulePopup(false)}
                          >
                            {text_close}
                          </button>
                        </center>
                      </div>
                    </>
                  )}
                </>
              );
            })}
        </>
      ) : (
        ""
      )}

      <ToastContainer />
    </>
  );
}

export default MyMedicine;
