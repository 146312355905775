import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import GlobalMedicineReducer from "./GlobalMedicineSlice";
import DashboardPairDeviceReducer from "./DashboardSlice";
import DeviceListReducer from "./DeviceListSlice";
import RefillMedicineReducer from "./RefillMedicine";
import MyMedicineReducer from "./MyMedicineSlice";
import DoseTakenReducer from "./DoseTakenData";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  globalMedicine: GlobalMedicineReducer,
  dashboardpairdevice: DashboardPairDeviceReducer,
  deviceList: DeviceListReducer,
  refillmedicine : RefillMedicineReducer,
  mymedicineprior: MyMedicineReducer,
  doseTakengetData: DoseTakenReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
