import React, { useEffect, useState } from "react";
import "../Register/Register.css";
import loginImg from "../../assets/img/Login.png";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/Regex";
import { CountrySelector, GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import PasswordChecklist from "react-password-checklist";
import { GoDotFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Register() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  // Language Variables
  const [text_heading_para_register, setText_heading_para_register] = useState(
    "Please fill your detail to Create your account."
  );
  const [text_first_name, setText_first_name] = useState("First Name");
  const [text_ph_firstname, setText_ph_firstname] =
    useState("Enter First Name");
  const [text_last_name, setText_last_name] = useState("Last Name");
  const [text_ph_lastname, setText_ph_lastname] = useState("Enter Last Name");
  const [text_email, setText_email] = useState("Email");
  const [text_phone_no, setText_phone_no] = useState("Enter Phone Number");
  const [text_choose_country, setText_choose_country] =
    useState("Choose a country");
  const [text_select_country, setText_select_country] = useState(
    "Select Country First"
  );
  const [text_select_region, setText_select_region] = useState("Select Region");
  const [text_select_state, setText_select_state] = useState("Select State");
  const [text_province, setText_province] = useState("Province");
  const [text_region, setText_region] = useState("Region");
  const [text_password, setText_password] = useState("Password");
  const [text_ph_password, setText_ph_password] = useState("Enter Password");
  const [text_confirm_password, setText_confirm_password] =
    useState("Confirm Password");
  const [text_ph_confirm_pass, setText_ph_confirm_pass] = useState(
    "Enter Confirm Password"
  );
  const [text_agree, setText_agree] = useState("I agree to the");
  const [text_terms_condition, setText_terms_condition] =
    useState("Terms of Services");
  const [text_and, setText_and] = useState("and");
  const [text_privacy_policy, setText_privacy_policy] =
    useState("Privacy Policy.");
  const [btn_signup, setBtn_signup] = useState("Sign Up");
  const [text_already_account, setText_already_account] = useState(
    "Already have an account?"
  );
  const [btn_signin, setBtn_signin] = useState("Sign In");
  const [text_err_firstname, setText_err_firstname] = useState(
    "Please Enter First Name"
  );
  const [text_err_lastname, setText_err_lastname] = useState(
    "Please Enter Last Name"
  );
  const [text_err_mobileno, setText_err_mobileno] = useState(
    "Please Enter Phone Number"
  );
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_valid_email, setText_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_err_password, setText_err_password] = useState(
    "Please Enter Password"
  );
  const [text_err_strong_pass, setText_err_strong_pass] = useState(
    "Please Enter Strong Password"
  );
  const [text_err_confirm_pass, setText_err_confirm_pass] = useState(
    "Please Enter Confirm password"
  );
  const [text_notmatch_password, setText_notmatch_password] = useState(
    "Confirm Password Does Not Match With Password"
  );
  //variables
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPass, setConfirmPass] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [ErrEmail, setErrEmail] = useState("");
  const [ErrPassword, setErrPassword] = useState("");
  const [ErrMobileNo, setErrMobileNo] = useState("");
  const [ErrConfirmPass, setErrConfirmPass] = useState("");
  const [regErr, setRegErr] = useState("");
  const [regopen, setRegOpen] = useState(false);

  const [FirstName, setFirstName] = useState("");
  const [ErrFirstName, setErrFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [ErrLastName, setErrLastName] = useState("");
  const [CountryCode, setCountryCode] = useState("IN");
  const [Country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [RegisterEnter, setRegisterEnter] = useState(false);
  const [showRegPassword, setShowRegPassword] = useState(false);
  const handleClickShowRegPassword = () => setShowRegPassword(!showRegPassword);
  const [showRegConfirmPassword, setShowRegConfirmPassword] = useState(false);
  const handleClickShowRegCPassword = () =>
    setShowRegConfirmPassword(!showRegConfirmPassword);
  const [Countryvalue, setCountryvalue] = useState(null);

  const setCountyData = (d) => {
    var countryName = CountrySelector[d];
    setCountry(countryName);
    setCountryCode(d);
  };
  const handleClose = () => {
    setRegOpen(false);
  };
  //validation
  const registerValidation = () => {
    let formValid = true;

    if (FirstName === "") {
      formValid = false;
      setErrFirstName(<>*{text_err_firstname}!</>);
    }
    if (LastName === "") {
      formValid = false;
      setErrLastName(<>*{text_err_lastname}!</>);
    }
    if (Email === "" && MobileNo === "") {
      formValid = false;
      setErrMobileNo(<>*{text_err_mobileno}!</>);
      setErrEmail(<>*{text_err_email}!</>);
    }
    if (MobileNo !== "") {
      formValid = true;
      setErrEmail("");
    }
    if (Email !== "" && EMAIL_REGEX.test(Email) === false) {
      formValid = false;
      setErrEmail(<>*{text_valid_email}!</>);
      setErrMobileNo("");
    }
    if (EMAIL_REGEX.test(Email) === true) {
      formValid = true;
      setErrEmail("");
      setErrMobileNo("");
    }
    if (!PASSWORD_REGEX.test(Password) === true) {
      formValid = false;
      if (Password === "") {
        formValid = false;
        setErrPassword(<>*{text_err_password}!</>);
      } else {
        setErrPassword(<>*{text_err_strong_pass}!</>);
      }
    }
    if (ConfirmPass !== Password) {
      formValid = false;
      if (ConfirmPass === "") {
        formValid = false;
        setErrConfirmPass(<>*{text_err_confirm_pass}!</>);
      } else {
        setErrConfirmPass(<>*{text_notmatch_password}!</>);
      }
    }

    return formValid;
  };

  const RegisterHandler = () => {
    setRegisterEnter(true);
    if (registerValidation()) {
      var api_url =
        GlobalConstants.Cdomain + "/api/breatheasy/v1/user/createuser";
      var newUser_data = {
        email: Email,
        role: "role",
        password: ConfirmPass,
        firstname: FirstName,
        lastname: LastName,
        mobile: MobileNo === "" ? "" : "+" + MobileNo,
        ismobile: false,
        usertype: "self",
        country: Country,
      };

      axios
        .post(api_url, newUser_data)
        .then(function (response) {
          console.log(response);
          // successToast(response.data.message);
          if (response.status === 200) {
            setRegOpen(true);
          }
          // setTimeout(() => {
          //   navigate("/login");
          // }, 2000);
        })
        .catch(function (err) {
          // errorToast(err.response.data.message);
          setRegErr(err.response.data.message);
          setRegisterEnter(false);
        });
    }
  };

  //Enter Keypress
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (RegisterEnter) {
        return;
      }
      RegisterHandler();
      // setRegisterEnter(true);
    }
  };
  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_heading_para_register(
      xpath.select1(
        "/resources/string[@name='text_heading_para_register']",
        doc
      ).firstChild.data
    );
    setText_first_name(
      xpath.select1("/resources/string[@name='text_first_name']", doc)
        .firstChild.data
    );
    setText_ph_firstname(
      xpath.select1("/resources/string[@name='text_ph_firstname']", doc)
        .firstChild.data
    );
    setText_last_name(
      xpath.select1("/resources/string[@name='text_last_name']", doc).firstChild
        .data
    );
    setText_ph_lastname(
      xpath.select1("/resources/string[@name='text_ph_lastname']", doc)
        .firstChild.data
    );
    setText_email(
      xpath.select1("/resources/string[@name='text_email']", doc).firstChild
        .data
    );
    setText_phone_no(
      xpath.select1("/resources/string[@name='text_phone_no']", doc).firstChild
        .data
    );
    setText_choose_country(
      xpath.select1("/resources/string[@name='text_choose_country']", doc)
        .firstChild.data
    );
    setText_select_country(
      xpath.select1("/resources/string[@name='text_select_country']", doc)
        .firstChild.data
    );
    setText_select_region(
      xpath.select1("/resources/string[@name='text_select_region']", doc)
        .firstChild.data
    );
    setText_select_state(
      xpath.select1("/resources/string[@name='text_select_state']", doc)
        .firstChild.data
    );
    setText_province(
      xpath.select1("/resources/string[@name='text_province']", doc).firstChild
        .data
    );
    setText_region(
      xpath.select1("/resources/string[@name='text_region']", doc).firstChild
        .data
    );
    setText_password(
      xpath.select1("/resources/string[@name='text_password']", doc).firstChild
        .data
    );
    setText_ph_password(
      xpath.select1("/resources/string[@name='text_ph_password']", doc)
        .firstChild.data
    );
    setText_confirm_password(
      xpath.select1("/resources/string[@name='text_confirm_password']", doc)
        .firstChild.data
    );
    setText_ph_confirm_pass(
      xpath.select1("/resources/string[@name='text_ph_confirm_pass']", doc)
        .firstChild.data
    );
    setText_agree(
      xpath.select1("/resources/string[@name='text_agree']", doc).firstChild
        .data
    );
    setText_terms_condition(
      xpath.select1("/resources/string[@name='text_terms_condition']", doc)
        .firstChild.data
    );
    setText_and(
      xpath.select1("/resources/string[@name='text_and']", doc).firstChild.data
    );
    setText_privacy_policy(
      xpath.select1("/resources/string[@name='text_privacy_policy']", doc)
        .firstChild.data
    );
    setBtn_signup(
      xpath.select1("/resources/string[@name='btn_signup']", doc).firstChild
        .data
    );
    setText_already_account(
      xpath.select1("/resources/string[@name='text_already_account']", doc)
        .firstChild.data
    );
    setBtn_signin(
      xpath.select1("/resources/string[@name='btn_signin']", doc).firstChild
        .data
    );
    setText_err_firstname(
      xpath.select1("/resources/string[@name='text_err_firstname']", doc)
        .firstChild.data
    );
    setText_err_lastname(
      xpath.select1("/resources/string[@name='text_err_lastname']", doc)
        .firstChild.data
    );
    setText_err_mobileno(
      xpath.select1("/resources/string[@name='text_err_mobileno']", doc)
        .firstChild.data
    );
    setText_err_email(
      xpath.select1("/resources/string[@name='text_err_email']", doc).firstChild
        .data
    );
    setText_valid_email(
      xpath.select1("/resources/string[@name='text_valid_email']", doc)
        .firstChild.data
    );
    setText_err_password(
      xpath.select1("/resources/string[@name='text_valid_email']", doc)
        .firstChild.data
    );
    setText_err_strong_pass(
      xpath.select1("/resources/string[@name='text_valid_email']", doc)
        .firstChild.data
    );
    setText_err_confirm_pass(
      xpath.select1("/resources/string[@name='text_err_confirm_pass']", doc)
        .firstChild.data
    );
    setText_notmatch_password(
      xpath.select1("/resources/string[@name='text_notmatch_password']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <div className="main_bg" onKeyPress={keyHandler}>
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={regopen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <FcFeedback className="Email-icon" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <h3 className="text-center">Please Verify Your Account</h3>
                <h5 className="text-center">
                  We have sent you a link on your registered email or phone no.
                  to verify your Account.
                </h5>
                <h5 className="text-center">
                  Please check your Spam or Junk folder.
                </h5>
                <br />
                {/* <p className="text-center">
                  <Link to="/support" className="text_account">
                    <u>Resend verification link</u>
                  </Link>
                </p> */}
              </DialogContentText>
              <Divider />
            </DialogContent>
            <DialogActions>
              <button
                className="cancelbtn mx-4"
                onClick={() => navigate("/login")}
              >
                Ok
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="row ">
          <div className="col-md-7 ">
            <div className="registerBG" id="registerBGmob">
              <div className="RegisterBox">
                <div className="form-sty">
                  <div className="text-center registersmallbox">
                    <img src={LogoImg} alt="" className="LoginLogoSty mb-3" />
                    {/* <h2 className="HeadlineTitle"> Breatheasy</h2> */}
                    <p className="HeadlineText">{text_heading_para_register}</p>
                  </div>
                  <div className="input-group">
                    <div className="d-flex flex-wrap gap-4">
                      <div className="inputDivreg">
                        <TextField
                          id="outlined-textarea"
                          label={text_first_name}
                          type="text"
                          placeholder={text_ph_firstname}
                          className="header-signup-inputs"
                          sx={{ width: "271px" }}
                          onChange={(e) => [
                            setFirstName(e.target.value),
                            setErrFirstName(""),
                            setRegErr(""),
                          ]}
                        />
                        <span className="ErrMessage">{ErrFirstName}</span>
                      </div>
                      <div className="inputDivreg">
                        <TextField
                          id="outlined-textarea"
                          label={text_last_name}
                          type="text"
                          placeholder={text_ph_lastname}
                          sx={{ width: "271px" }}
                          className="header-signup-inputs"
                          onChange={(e) => [
                            setLastName(e.target.value),
                            setErrLastName(""),
                            setRegErr(""),
                          ]}
                        />
                        <span className="ErrMessage">{ErrLastName}</span>
                      </div>
                      <div className="inputDivreg">
                        <TextField
                          id="outlined-textarea"
                          label={text_email}
                          type="email"
                          sx={{ width: "271px" }}
                          placeholder="admin@breatheasy.com"
                          className="header-signup-inputs"
                          onChange={(e) => [
                            setEmail(e.target.value),
                            setErrEmail(""),
                            setRegErr(""),
                          ]}
                        />
                        <span className="ErrMessage">{ErrEmail}</span>
                      </div>
                      <div className="inputDivreg">
                        <PhoneInput
                          onChange={(e) => [
                            setMobileNo(e),
                            setErrMobileNo(""),
                            setRegErr(""),
                          ]}
                          inputStyle={{
                            background: "transparent",
                            height: "56px",
                            minWidth: "268px",
                            width: "220px",
                          }}
                          buttonStyle={{ background: "transparent" }}
                          inputclassName="Register-input"
                          placeholder={text_phone_no}
                          value={MobileNo}
                        />
                        <span className="ErrMessage">{ErrMobileNo}</span>
                      </div>
                      <div className="inputDivreg">
                        <Autocomplete
                          id="country-select-demo"
                          value={Countryvalue}
                          onChange={(event, newValue) => {
                            setCountryvalue(newValue);
                          }}
                          sx={{ width: "271px" }}
                          options={CountrySelector}
                          autoHighlight
                          defaultValue={{
                            code: "US",
                            label: "United States",
                            phone: "1",
                            suggested: true,
                          }}
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={text_choose_country}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: Countryvalue ? (
                                  <InputAdornment
                                    position="start"
                                    onClick={() => setCountyData(true)}
                                  >
                                    <img
                                      loading="lazy"
                                      width="20"
                                      src={`https://flagcdn.com/w20/${Countryvalue.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w40/${Countryvalue.code.toLowerCase()}.png 2x`}
                                      alt=""
                                    />
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="input-container">
                        <RegionDropdown
                          className="input-field inputSty"
                          type="text"
                          defaultOptionLabel={
                            Countryvalue === null
                              ? text_select_country
                              : text_select_region
                          }
                          country={
                            Countryvalue === null
                              ? text_select_region
                              : Countryvalue.label
                          }
                          onChange={(e) => [setState(e)]}
                          value={state}
                        />
                        <label for="input-field" className="input-label">
                          {text_select_state}/{text_province}/{text_region}
                        </label>
                      </div>
                      <div className="inputDivreg">
                        <TextField
                          id="outlined-textarea"
                          type={showRegPassword ? "text" : "password"}
                          label={text_password}
                          placeholder={text_ph_password}
                          className="header-signup-inputs "
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowRegPassword}
                                >
                                  {showRegPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => [
                            setPassword(e.target.value),
                            setErrPassword(""),
                            setRegErr(""),
                          ]}
                        />
                        <span className="ErrMessage">{ErrPassword}</span>
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                          ]}
                          minLength={8}
                          value={Password}
                          messages={{
                            minLength: "Password has at least 8 characters.",
                            specialChar: "Password has special characters.",
                            number: "Password has a number.",
                            capital: "Password has a capital letter.",
                          }}
                          // style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                          iconComponents={{
                            ValidIcon: (
                              <FaCheck
                                style={{
                                  color: "green",
                                  marginRight: "7px",
                                }}
                              />
                            ),
                            InvalidIcon: (
                              <GoDotFill style={{ marginRight: "7px" }} />
                            ),
                          }}
                          iconSize={30}
                        />
                      </div>
                      <div className="inputDivreg">
                        <TextField
                          id="outlined-textarea"
                          type={showRegConfirmPassword ? "text" : "password"}
                          label={text_confirm_password}
                          placeholder={text_ph_confirm_pass}
                          className="header-signup-inputs confirmpass"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowRegCPassword}
                                >
                                  {showRegConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ width: "121%" }}
                          onChange={(e) => [
                            setConfirmPass(e.target.value),
                            setErrConfirmPass(""),
                            setRegErr(""),
                          ]}
                        />
                        <span className="ErrMessage">{ErrConfirmPass}</span>
                      </div>
                    </div>
                  </div>
                  <p className="regErr-p mt-5">{regErr}</p>
                  <div className="mt-4 d-flex flex-wrap justify-content-center">
                    <p className="">
                      {" "}
                      {/* <input type="checkbox" className="me-2" /> */}
                      {text_agree}{" "}
                      <Link to={"/termsofservice"}>
                        <span className="registertext">
                          {text_terms_condition}
                        </span>
                      </Link>{" "}
                      {text_and}{" "}
                      <Link to={"/privacypolicy"}>
                        <span className="registertext">
                          {text_privacy_policy}
                        </span>
                      </Link>{" "}
                    </p>
                  </div>
                  <div className="registersmallbox">
                    <div className="d-flex flex-wrap justify-content-center">
                      <button className="SignInBtn" onClick={RegisterHandler}>
                        {btn_signup}
                      </button>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                      <p className="mt-3">
                        {text_already_account}
                        <Link to="/login" className="forgotpasstext">
                          {" "}
                          <b className="sign-in-link">{btn_signin}</b>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <img className="imgstyle" src={loginImg} alt="" id="mobilebg" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Register;
