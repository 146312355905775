import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import Cookie from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GlobalConstants } from "../../utils/GlobalConstants";
import moment from "moment";
import { BiEditAlt } from "react-icons/bi";
import img1 from "../../assets/img/Inhaler_img 1.png";
import { customTableStyles } from "../../utils/DataTableStyle";
import { ToastContainer } from "react-toastify";
import { CgCloseO } from "react-icons/cg";
import { Divider } from "@mui/material";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import DatePicker from "react-datepicker";
import Header from "../Header/Header";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Myhistory() {
  // Language Variables start
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_device_name, setText_device_name] = useState("Device Name");
  const [text_medicine_name, setText_medicine_name] = useState("Medicine Name");
  const [text_date, setText_date] = useState("Date");
  const [text_inhaler_position, setText_inhaler_position] =
    useState("Inhaler Position");
  const [text_temperature, setText_temperature] = useState("Temperature");
  const [text_shake_time, setText_shake_time] = useState("Shake Time");
  const [text_dose_taken, setText_dose_taken] = useState("Dose Taken");
  const [text_dose_left, setText_dose_left] = useState("Dose Left");
  const [text_select_dose, setText_select_dose] = useState("Select Dose Taken");

  const [text_time_taken, setText_time_taken] = useState("Time Taken");
  const [text_dose_override, setText_dose_override] = useState("Dose Override");
  const [text_edit_dose_manually, setText_edit_dose_manually] =
    useState("Edit Dose Manually");
  const [text_schedule_time, setText_schedule_time] = useState("Schedule Time");
  const [text_override_time, setText_override_time] = useState("Override Time");
  const [text_edit, setText_edit] = useState("Edit");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_update, setBtn_update] = useState("Update");
  const [text_ph_num_of_dose, setText_ph_num_of_dose] = useState(
    "Enter Number of Doses"
  );
  const [text_number_of_puff_dose, setText_number_of_puff_dose] = useState(
    "Number of Puff/Dose"
  );
  const [text_history, setText_history] = useState("history");
  // Language Variables end
  const navigate = useNavigate();
  const DeviceData = useLocation();
  const DeviceName = DeviceData?.state === "" ? "" : DeviceData?.state?.data;
  const [filterText, setFilterText] = useState("");
  const [doseTakenAllData, setDoseTakenAllData] = useState([]);
  const currentDeviceID = sessionStorage.getItem("deviceID");
  const [editdosetoken, setEditdosetoken] = useState(false);
  const [overrideTime, setOverrideTime] = useState("");
  const [editdoseoverride, setEditdoseoverride] = useState("1");
  const [edittabledose, setEdittabledose] = useState(false);
  const [editDoseID, setEditDoseId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [overrideDateTime, setOverrideDateTime] = useState(new Date());
  const [page, setPage] = useState(0);
  const [IsNextDisable, setIsNextDisable] = useState(false);
  const DoseFinalData = useMemo(() => {
    return doseTakenAllData.slice(page * 10, page * 10 + 10);
  }, [page, doseTakenAllData]);
  console.log(doseTakenAllData);
  const hasMoreDose = sessionStorage.getItem("hasMoreDatas");
  
  const getDoseTakenAllData = (newToken) => {
    if (currentDeviceID === null || currentDeviceID === "") {
      console.log(currentDeviceID);
    } else {
      if (newToken === null) {
        var api_url =
          GlobalConstants.Cdomain +
          `/api/breatheasy/v1/medicine/dosetaken/selectall?Device_ID=${currentDeviceID}&limit=10&nextToken=`;
      } else {
        var api_url =
          GlobalConstants.Cdomain +
          `/api/breatheasy/v1/medicine/dosetaken/selectall?Device_ID=${currentDeviceID}&limit=10&nextToken=${newToken}`;
      }

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          if (res.status === 200) {
            var data = res.data.data.dosedata;
            setDoseTakenAllData(data);
            var doseValue = res.data.data;
            var hasMoreDatas = doseValue.hasMoreDatas;
            var nextToken = doseValue.nextToken;
            var dosedataCount = doseValue.dosedataCount;
            sessionStorage.setItem("dosedatacount", dosedataCount);
            sessionStorage.setItem("DoseTaken", nextToken);
            sessionStorage.setItem("hasMoreDatas", hasMoreDatas);
            console.log(data);
          }
          console.log("all medicine res***", res);
          // setAllMedicinesInPopup(res?.data?.data);
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("all medicine err****", err);
          }
        });
    }
  };
  const filterDoseData = DoseFinalData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  useEffect(() => {
    getDoseTakenAllData(null);
  }, [doseTakenAllData.length]);
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_device_name}</>,
      selector: (row) => <>{DeviceName}</>,
    },
    {
      name: <>{text_medicine_name}</>,
      selector: (row) => <>{row.Genericname === "" ? "-" : row.Genericname}</>,
    },
    {
      name: <>{text_date}</>,
      selector: (row) => moment(row.dosetakendate).format("MMM DD,yyyy"),
    },
    {
      name: <>{text_inhaler_position}</>,
      selector: (row) => (
        <img
          src={img1}
          alt=""
          className={
            row.Inhale_Position === "Slightly wrong"
              ? "slightlypostion"
              : row.Inhale_Position === "Incorrect"
              ? "incorrectpostion"
              : "idealpostion"
          }
        />
      ),
      width: "180px",
    },
    {
      name: <>{text_temperature}</>,
      selector: (row) => <>{row.Temperature === "" ? "-" : row.Temperature}</>,
    },
    {
      name: <>{text_shake_time}</>,
      selector: (row) => <>{row.Shake === "" ? "-" : row.Shake}</>,
    },
    {
      name: <>{text_dose_taken}</>,
      selector: (row) => <>{row.isdosetaken === true ? "✅" : "❌"}</>,
    },
    {
      name: <>{text_dose_left}</>,
      selector: (row) => row.doseleft,
    },
    {
      name: <>{text_number_of_puff_dose}</>,
      selector: (row) => (
        <>
          {
            <span className={row.overridedose === "" ? "" : "text-danger"}>
              {row.overridedose === "" || row.overridedose===null? "-" : row.overridedose}
            </span>
          }
        </>
      ),
    },
    {
      name: <>{text_time_taken}</>,
      selector: (row) => (
        <>
          {row.dosetakentime === "" || row.dosetakentime === null
            ? "-"
            : row.dosetakentime}
        </>
      ),
    },
    {
      name: <>{text_schedule_time}</>,
      selector: (row) => (
        <>
          {row.scheduletime === "" || row.scheduletime === null
            ? "-"
            : row.scheduletime}
        </>
      ),
    },
    {
      name: <>{text_override_time}</>,
      selector: (row) => (
        <>
          {
            <sapn
              className={
                row.overridedosetakentime === "" ||
                row.overridedosetakentime === null
                  ? ""
                  : "text-danger"
              }
            >
              {row.overridedosetakentime === ""
                ? "-"
                : row.overridedosetakentime}
            </sapn>
          }
        </>
      ),
    },
    {
      name: <>{text_edit}</>,
      selector: (row) => (
        <div
          onClick={() => [
            handleOverdoseEdit(row),
            setEdittabledose(!edittabledose),
          ]}
        >
          <BiEditAlt className="editBtn" />
        </div>
      ),
    },
  ];
  const handleOverdoseEdit = (value) => {
    // setStartDate(value.dosetakendate);
    setEditdoseoverride(value.overridedose);
    setEditdosetoken(value.isdosetaken);
    setEditDoseId(value._id);

    var Time = value.overridedosetakentime;
    setOverrideTime(Time);
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_inhaler_position(
      xpath.select1("/resources/string[@name='text_inhaler_position']", doc)
        .firstChild.data
    );
    setText_temperature(
      xpath.select1("/resources/string[@name='text_temperature']", doc)
        .firstChild.data
    );
    setText_shake_time(
      xpath.select1("/resources/string[@name='text_shake_time']", doc)
        .firstChild.data
    );
    setText_dose_left(
      xpath.select1("/resources/string[@name='text_dose_left']", doc).firstChild
        .data
    );
    setText_schedule_time(
      xpath.select1("/resources/string[@name='text_schedule_time']", doc)
        .firstChild.data
    );
    setText_edit(
      xpath.select1("/resources/string[@name='text_edit']", doc).firstChild.data
    );
    setText_device_name(
      xpath.select1("/resources/string[@name='text_device_name']", doc)
        .firstChild.data
    );
    setText_medicine_name(
      xpath.select1("/resources/string[@name='text_medicine_name']", doc)
        .firstChild.data
    );
    setText_date(
      xpath.select1("/resources/string[@name='text_date']", doc).firstChild.data
    );
    setText_dose_taken(
      xpath.select1("/resources/string[@name='text_dose_taken']", doc)
        .firstChild.data
    );
    setText_time_taken(
      xpath.select1("/resources/string[@name='text_time_taken']", doc)
        .firstChild.data
    );
    setText_dose_override(
      xpath.select1("/resources/string[@name='text_dose_override']", doc)
        .firstChild.data
    );
    setText_override_time(
      xpath.select1("/resources/string[@name='text_override_time']", doc)
        .firstChild.data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_update(
      xpath.select1("/resources/string[@name='btn_update']", doc).firstChild
        .data
    );
    setText_ph_num_of_dose(
      xpath.select1("/resources/string[@name='text_ph_num_of_dose']", doc)
        .firstChild.data
    );
    setText_select_dose(
      xpath.select1("/resources/string[@name='text_select_dose']", doc)
        .firstChild.data
    );
    setText_edit_dose_manually(
      xpath.select1("/resources/string[@name='text_edit_dose_manually']", doc)
        .firstChild.data
    );
    setText_history(
      xpath.select1("/resources/string[@name='text_history']", doc).firstChild
        .data
    );
    setText_number_of_puff_dose(
      xpath.select1("/resources/string[@name='text_number_of_puff_dose']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const _setOverrideTime = (e) => {
    let chdate = e.toISOString();
    let d = moment(chdate).format("h:mm a");
    setOverrideTime(d);
  };
  const handleEditDoseTime = () => {
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/medicine/dosetaken";
    var doseOverRide = {
      _id: editDoseID,
      Device_ID: currentDeviceID,
      isdosetaken: editdosetoken,
      overridedose: editdoseoverride,
      overridedosetakentime:
        overrideTime === ""
          ? moment(new Date()).format("h:mm a")
          : overrideTime,
          isWeb: true,
    };
    

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, doseOverRide, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          getDoseTakenAllData(null);
          // getDoseLiveData();
          setEdittabledose(false);
          setEditdoseoverride("1");
        }
      })
      .catch(function (err) {
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          errorToast(err.response.data.message);
        }
      });
  };
  console.log(page);

  useEffect(() => {
    console.log(DoseFinalData.length);
    if (DoseFinalData.length < 10) {
      console.log(
        "useEffect if DahsboardFinalData***",
        DoseFinalData,
        DoseFinalData.length
      );
      setIsNextDisable(true);
    } else {
      setIsNextDisable(false);
      console.log(
        "useEffect else DahsboardFinalData***",
        DoseFinalData,
        DoseFinalData.length
      );
    }
  }, [doseTakenAllData.length]);
  const nextPage = () => {
    // var DoseTaken = new Date();
    console.log(hasMoreDose);
    // setIsLoading(true);
    var DoseTaken = sessionStorage.getItem("DoseTaken");
    if (hasMoreDose === "true") {
      getDoseTakenAllData(DoseTaken);
      if (doseTakenAllData.length >= (page * 10, page * 10 + 10)) {
        setPage((prev) => prev + 1);
      }
    } else {
      // setIsLoading(false);
      setPage((prev) => prev + 1);
    }
  };
  const prevPage = () => {
    setIsNextDisable(false);
    setPage((prev) => (prev > 0 ? prev - 1 : prev));
  };

  console.log(DeviceName);
  return (
    <>
      <Header />
      <div className="mt-5 p-3 mb-3">
        <h5 className="text-center ">
          <b>{DeviceName}</b> {text_history}
        </h5>
        <div className="mt-4">
          <DataTable
            columns={columns}
            // pagination
            data={filterDoseData}
            fixedHeader
            paginationDefaultPage={2}
            selectableRowsHighlight
            highlightOnHover
            customStyles={customTableStyles}
          />
        </div>
        <div
          className={
            page === 0 ? "text-end mt-2" : "d-flex justify-content-between mt-2"
          }
        >
          <button
            onClick={prevPage}
            className={page === 0 ? "d-none" : "cancelbtn me-3"}
          >
            previous
          </button>
          <button
            onClick={nextPage}
            className={IsNextDisable ? "d-none" : "savebtn"}
          >
            Next
          </button>
        </div>
      </div>
      {edittabledose ? (
        <>
          <div className="Popup addmedicinePopup2">
            <div className="text-end">
              <CgCloseO size={25} onClick={() => setEdittabledose(false)} />
              <h4 className="text-center">{text_edit_dose_manually}</h4>
              <Divider />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <h5>{text_date} : &nbsp;</h5>
              <h5>{moment(startDate).format("MMM DD, yyyy")}</h5>
            </div>
            <div className="row p-2 mt-2">
              <div className="col-md-4">
                <h5 className="mt-1">{text_dose_taken}</h5>
                <select
                  name=""
                  id=""
                  value={editdosetoken}
                  onChange={(e) => setEditdosetoken(e.target.value)}
                >
                  <option value="">{text_select_dose}</option>
                  <option value={true}>✅</option>
                  <option value={false}>❌</option>
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_number_of_puff_dose}</h5>
                {/* <input
                  type="number"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  min={0}
                  className="frequencyInput mt-1"
                  placeholder={text_ph_num_of_dose}
                  value={editdoseoverride}
                  onChange={(e) => setEditdoseoverride(e.target.value)}
                /> */}
                <select
                  className="NumberOfDose mt-2"
                  onChange={(e) => setEditdoseoverride(e.target.value)}
                  value={editdoseoverride}
                >
                  <option value="1" selected>
                    1
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_override_time}</h5>
                <DatePicker
                  className="frequencyInput"
                  showTimeSelectOnly
                  showTimeSelect
                  onChange={(date) => [
                    _setOverrideTime(date),
                    setOverrideDateTime(date),
                  ]}
                  dateFormat="hh:mm a"
                  // value={overrideTime}
                  selected={overrideDateTime}
                  // placeholderText={text_select_time}
                />
                {console.log(overrideTime)}
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                className="cancelbtn"
                onClick={() => setEdittabledose(false)}
              >
                {btn_cancel}
              </button>
              <button className="savebtn" onClick={handleEditDoseTime}>
                {btn_update}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </>
  );
}

export default Myhistory; 