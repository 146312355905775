import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";
import { errorToast } from "../utils/ToasterUtils";

const DoseTakenDataSlice = createSlice({
  name: "DoseTakenData",
  initialState: [],

  reducers: {
    setDoseTakenData(state, action) {
      return (state = action.payload);
    },
    setDoseTakenAllDataLogout(state, action) {
      return (state = action.payload);
    },
  },
});

const { setDoseTakenAllDataLogout, setDoseTakenData } =
  DoseTakenDataSlice.actions;
export default DoseTakenDataSlice.reducer;

export function getDoseTakenData(newToken) {
  return async function getDoseTakenDataThunk(dispatch, getState) {
    const currentDeviceID = sessionStorage.getItem("deviceID");
    try {
      console.log(newToken);
      var api_url =
        newToken === "NA"
          ? GlobalConstants.Cdomain +
            `/api/breatheasy/v1/medicine/dosetaken/selectall?Device_ID=${currentDeviceID}&limit=10&nextToken=`
          : GlobalConstants.Cdomain +
            `/api/breatheasy/v1/medicine/dosetaken/selectall?Device_ID=${currentDeviceID}&limit=10&nextToken=${newToken}`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };
      axios
        .get(api_url, headerConfig)
        .then((res) => {
          if (res.status === 200) {
            var data = res.data.data.dosedata;
            // setDoseTakenAllData(data);
            var doseValue = res.data.data;
            var hasMoreDatas = doseValue.hasMoreDatas;
            var nextToken = doseValue.nextToken;
            var dosedataCount = doseValue.dosedataCount;
            sessionStorage.setItem("dosedatacount", dosedataCount);
            sessionStorage.setItem("DoseTaken", nextToken);
            sessionStorage.setItem("hasMoreDatas", hasMoreDatas);
            console.log(data);
            dispatch(setDoseTakenData(data));
          }
        })
        .catch(function (err) {
          errorToast(err.response.data.message);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              window.location.replace("/");
            }, 2000);
          } else {
            console.log("dose Taken err******", err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}

export function doseTakenFunc() {
  return async function doseTakenFuncThunk(dispatch, getState) {
    try {
      dispatch(setDoseTakenAllDataLogout([]));
    } catch (err) {
      console.log(err);
    }
  };
}
