import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import loginImg from "../../assets/img/Login.png";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Tooltip from "../../assets/img/tooltip.png";
import Cookie from "js-cookie";
import { FcFeedback } from "react-icons/fc";
import { Number_REGEX } from "../../utils/Regex";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Login() {
  const navigate = useNavigate();
  // Language Variables
  const [text_heading_para_login, setText_heading_para_login] = useState(
    "Please fill your detail to access your account."
  );
  const [text_email_phone, setText_email_phone] = useState(
    "Email or Phone Number"
  );
  const [text_tootltip_message, setText_tooltip_message] = useState(
    "Enter Phone number add country code. For example"
  );
  const [text_tootltip_suggesion, setText_tootltip_suggesion] = useState(
    "Enter Phone number with country code. For example"
  );
  const [text_password, setText_password] = useState("Password");
  const [text_ph_password, setText_ph_password] = useState("Enter Password");
  const [text_remember_me, setText_remember_me] = useState("Remember Me");
  const [text_forgot_pass, setText_forgot_pass] = useState("Forgot Password?");
  const [btn_signin, setBtn_signin] = useState("Sign In");
  const [text_dont_account, setText_dont_account] = useState(
    "Don’t have an account?"
  );
  const [btn_signup, setBtn_signup] = useState("Sign Up");
  const [text_trouble, setText_trouble] = useState("Having Trouble sign in?");
  const [text_err_email_phone, setText_err_email_phone] = useState(
    "Please Enter Email or Phone Number"
  );
  const [text_err_password, setText_err_password] = useState(
    "Please Enter Password"
  );
  const [text_succ_login_successfully, setText_succ_login_successfully] =
    useState("Login Successfully!");

  //variables
  const [EmailorPhone, setEmailorPhone] = useState("");
  const [Password, setPassword] = useState("");
  const [ErrEmail, setErrEmail] = useState("");
  const [ErrPassword, setErrPassword] = useState("");
  const [errlogin, setErrlogin] = useState("");
  const [loginEnter, setloginEnter] = useState(false);
  const [showRegPassword, setShowRegPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [open, setOpen] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickShowRegPassword = () => setShowRegPassword(!showRegPassword);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    var value = e.target.value;
    if (Number_REGEX.test(value) === true) {
      setEmailorPhone("+" + e.target.value);
    } else {
      setEmailorPhone(e.target.value);
    }
  };
  // ----- remember me starts -----
  const setDataToSessionFromCookie = async () => {
    var tokenInCookie = Cookie.get("token");
    var user_emailInCookie = Cookie.get("user_email");
    var firstnameInCookie = Cookie.get("firstname");
    var lastnameInCookie = Cookie.get("lastname");
    var user_idInCookie = Cookie.get("user_id");

    sessionStorage.setItem("token", tokenInCookie);
    sessionStorage.setItem("user_email", user_emailInCookie);
    sessionStorage.setItem("firstname", firstnameInCookie);
    sessionStorage.setItem("lastname", lastnameInCookie);
    sessionStorage.setItem("user_id", user_idInCookie);
  };

  useEffect(() => {
    var tokenInCookie = Cookie.get("token");
    var token_start_timeInCookie = Cookie.get("token_start_time");

    if (tokenInCookie) {
      if (Date.now() - token_start_timeInCookie < 43200000) {
        var user_emailInCookie = Cookie.get("user_email");
        var firstnameInCookie = Cookie.get("firstname");
        var lastnameInCookie = Cookie.get("lastname");
        var user_idInCookie = Cookie.get("user_id");

        setDataToSessionFromCookie().then(() => {
          navigate("/register/device", {
            state: {
              tokenInCookie,
              user_emailInCookie,
              firstnameInCookie,
              lastnameInCookie,
              user_idInCookie,
            },
          });
        });
      } else {
        Cookie.remove("token");
        Cookie.remove("user_email");
        Cookie.remove("firstname");
        Cookie.remove("lastname");
        Cookie.remove("user_id");
        Cookie.remove("token_start_time");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
    }
  }, []);

  // ----- remember me ends -----

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });

    console.log("Latitude is:", lat)
    console.log("Longitude is:", long)
  }, [lat, long]);


  const getWetherData = (token)=>{
    if(lat!==""||long!==""){
    var api_url =
    GlobalConstants.Cdomain +
    "/api/breatheasy/v1/user/weatherdata";
  var WeatherData = {
    "lat": lat,
    "lon": long,
  };
  const headerConfig = {
    headers: {
      accept: "application/json",
      "auth-token": token,
    },
  };

  axios
    .post(api_url, WeatherData, headerConfig)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (err) {
      errorToast(err.response.data.message);
    });
  }
// }
}
  //validation
  const loginValidation = () => {
    let formIsValid = true;
    if (EmailorPhone === "") {
      formIsValid = false;
      setErrEmail(<>*{text_err_email_phone}!</>);
    }
    if (Password === "") {
      formIsValid = false;
      setErrPassword(<>*{text_err_password}!</>);
    }
    setloginEnter(false);
    return formIsValid;
  };

  const LoginHandler = () => {
    if (loginValidation()) {
      setloginEnter(true);
      setIsLoading(true);
      var api_url = GlobalConstants.Cdomain + "/api/breatheasy/v1/user/login";
      var newUser_data = {
        username: EmailorPhone.toLowerCase(),
        password: Password,
      };

      axios
        .post(api_url, newUser_data)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            setloginEnter(false);
            console.log(response.data);
            getWetherData(response.data.data.token);
            sessionStorage.setItem("token", response.data.data.token);
            getDevice(response.data.data.token, response.data.data.user_id);
            sessionStorage.setItem("user_email", EmailorPhone);
            sessionStorage.setItem("firstname", response.data.data.firstname);
            sessionStorage.setItem("lastname", response.data.data.lastname);
            sessionStorage.setItem("user_id", response.data.data.user_id);
            // successToast(response.data.message);
            // ----- for remembering the user starts -----
            if (rememberMe) {
              Cookie.set("token", response.data.data.token);
              Cookie.set("user_email", EmailorPhone);
              Cookie.set("firstname", response.data.data.firstname);
              Cookie.set("lastname", response.data.data.lastname);
              Cookie.set("user_id", response.data.data.user_id);
              Cookie.set("token_start_time", Date.now());
            }
            // ----- for remembering the user ends -----
          }
        })
        .catch(function (err) {
          console.log(err);
          setIsLoading(false);
          if (
            err.response.data.message === "Account inactive" ||
            err.response.data.message === "mobile number not verified" ||
            err.response.data.message === "email not verified"
          ) {
            setOpen(true);
          } else {
            setErrlogin(err.response.data.message);
            // errorToast(err.response.data.message);
          }
          // errorToast(err.response.data.message);
          setloginEnter(false);
        });
    }
  };

  const getDevice = (token, userID) => {
    console.log("---", token, "---");
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/isdeviceexist?_userId=" +
      userID;
    let headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };
    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          sessionStorage.setItem(
            "deviceexits",
            response.data.data._isdeviceexist
          );
          sessionStorage.setItem(
            "medicineexits",
            response.data.data._ismedicineexist
          );
          // successToast(<>{text_succ_login_successfully}</>);
          var data = response.data.data;
          if (data._isdeviceexist === true) {
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          } else {
            setTimeout(() => {
              navigate("/register/device");
            }, 1000);
          }
          if (
            data._ismedicineexist === false &&
            data._isdeviceexist === false
          ) {
            setTimeout(() => {
              navigate("/register/device");
            }, 1000);
          } else {
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          }
        }
      })
      .catch(function (err) {
        sessionStorage.clear();
        localStorage.clear();
        console.log("Error! Try Again.");
        setIsLoading(false);
        errorToast(err);
        setloginEnter(false);
      });
  };
  //Enter Keypress
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (loginEnter) {
        return;
      }
      LoginHandler();
      // setloginEnter(true);
    }
  };
  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_heading_para_login(
      xpath.select1("/resources/string[@name='text_heading_para_login']", doc)
        .firstChild.data
    );
    setText_email_phone(
      xpath.select1("/resources/string[@name='text_email_phone']", doc)
        .firstChild.data
    );
    setText_tooltip_message(
      xpath.select1("/resources/string[@name='text_tootltip_message']", doc)
        .firstChild.data
    );
    setText_password(
      xpath.select1("/resources/string[@name='text_password']", doc).firstChild
        .data
    );
    setText_ph_password(
      xpath.select1("/resources/string[@name='text_ph_password']", doc)
        .firstChild.data
    );
    setText_remember_me(
      xpath.select1("/resources/string[@name='text_remember_me']", doc)
        .firstChild.data
    );
    setText_forgot_pass(
      xpath.select1("/resources/string[@name='text_remember_me']", doc)
        .firstChild.data
    );
    setBtn_signin(
      xpath.select1("/resources/string[@name='btn_signin']", doc).firstChild
        .data
    );
    setText_dont_account(
      xpath.select1("/resources/string[@name='text_dont_account']", doc)
        .firstChild.data
    );
    setBtn_signup(
      xpath.select1("/resources/string[@name='btn_signup']", doc).firstChild
        .data
    );
    setText_trouble(
      xpath.select1("/resources/string[@name='text_trouble']", doc).firstChild
        .data
    );
    setText_err_email_phone(
      xpath.select1("/resources/string[@name='text_err_email_phone']", doc)
        .firstChild.data
    );
    setText_err_password(
      xpath.select1("/resources/string[@name='text_err_password']", doc)
        .firstChild.data
    );
    setText_succ_login_successfully(
      xpath.select1(
        "/resources/string[@name='text_succ_login_successfully']",
        doc
      ).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {/* {isLoading && "Loading..."} */}
      <div className="row loginMain">
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <FcFeedback className="Email-icon" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <h3 className="text-center">Please Verify Your Account</h3>
                <h5 className="text-center">
                  We have sent you a link on your registered email or phone no.
                  to verify your Account.
                </h5>
                <h5 className="text-center">
                  Please check your Spam or Junk folder.
                </h5>
                <br />
                <p className="text-center">
                  <Link to="/support" className="text_account">
                    <u>Resend verification link</u>
                  </Link>
                </p>
              </DialogContentText>
              <Divider />
            </DialogContent>
            <DialogActions>
              <button className="cancelbtn mx-4" onClick={handleClose}>
                Close
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="col-md-6" id="mobilebg">
          <img className="imgstyle" src={loginImg} alt="" />
        </div>
        <div className="col-md-6">
          <div className="LoginHalfBG">
            <div className="LoginBox">
              <center>
                <img src={LogoImg} alt="" className="LoginLogoSty mb-3" />
                {/* <h2 className="HeadlineTitle"> Breatheasy</h2> */}
                <p className="HeadlineText">{text_heading_para_login}</p>
              </center>
              <div className="LoginForm " onKeyPress={keyHandler}>
                <div className="form-input-login mt-4">
                  <TextField
                    id="outlined-textarea"
                    label={text_email_phone}
                    // type="text"
                    placeholder="abc@xyz.com or +1 xxxxxxxxx"
                    className="header-signup-inputs login-input"
                    onChange={(e) => [handleChange(e), setErrEmail(""),setErrlogin("")]}
                    value={EmailorPhone}
                  />
                  <span className="ErrMessage err_login">{ErrEmail}</span>
                  <div className="d-flex err_login mt-1">
                    <img className="tooltipSty" alt="" src={Tooltip} />
                    &nbsp;{" "}
                    <p className="tooltipinfo">
                      {text_tootltip_suggesion}, +1xxxxxxxxx{" "}
                    </p>
                  </div>
                  <div className="d-flex flex-row align-items-baseline mt-4">
                    <TextField
                      id="outlined-textarea"
                      type={showRegPassword ? "text" : "password"}
                      label={text_password}
                      placeholder={text_ph_password}
                      className="header-signup-inputs login-input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRegPassword}
                            >
                              {showRegPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => [
                        setPassword(e.target.value),
                        setErrPassword(""),
                        setErrlogin("")
                      ]}
                    />
                  </div>
                  <span className="ErrMessage err_login">{ErrPassword}</span>
                  <div className="mt-3  dummy login-input">
                    <p>
                      {" "}
                      <input
                        type="checkbox"
                        className="me-2 "
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setRememberMe(e.target.checked)}
                      />
                      {text_remember_me}
                    </p>
                    <p>
                      {" "}
                      <Link to="/forgotpassword" className="forgotpasstext">
                        {text_forgot_pass}
                      </Link>
                    </p>
                  </div>
                </div>
                <center>
                <p className="regErr-p  mb-4">{errlogin}</p>
                  <button
                    className="SignInBtn"
                    disabled={loginEnter}
                    onClick={LoginHandler}
                  >
                    {btn_signin}
                  </button>
                  <p className="mt-3">
                    {text_dont_account}{" "}
                    <Link to="/register" className="signup_link">
                      <b>{btn_signup}</b>
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link to="/support">
                      <u className="troubletext"> {text_trouble}</u>
                    </Link>
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
