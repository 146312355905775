import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import loginImg from "../../assets/img/Login.png";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GlobalConstants } from "../../utils/GlobalConstants";
import "../Login/Login.css";
import axios from "axios";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import { FcFeedback } from "react-icons/fc";
import { Number_REGEX } from "../../utils/Regex";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Helppage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Language Variables
  const [text_help_you, setText_help_you] = useState("How Can We Help You?");
  const [text_resend_link, setText_resend_link] = useState(
    "Resend Verification Link"
  );
  const [text_contact_us, setText_contact_us] = useState("Contact Us");
  const [text_contact_at, setText_contact_at] = useState("Contact us at");
  const [text_more_help, setText_more_help] = useState("for more help");
  const [text_verify_account, setText_verify_account] = useState(
    "Verfiy Your Account"
  );
  const [text_enter_email_phone, setText_enter_email_phone] = useState(
    "Enter Email or Phone Number"
  );
  const [text_err_email_phone, setText_err_email_phone] = useState(
    "Please Enter Email or Phone Number"
  );

  const [value, setValue] = useState("ResendLink");
  const [ResendEmail, setResendEmail] = useState("");
  const [errResendEmail, setErrResendEmail] = useState("");
  const [openresend, setOpenresend] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const [helperr, setHelperr] = useState("");

  const handleChange = (e) => {
    var value = e.target.value;
    if (Number_REGEX.test(value) === true) {
      setResendEmail("+" + e.target.value);
    } else {
      setResendEmail(e.target.value);
    }
  };
  console.log(value);
  function handleClick(event) {
    setValue(event.target.value);
  }
  const sendVerifyEmail = () => {
    if (ResendEmail === "") {
      setErrResendEmail(<>*{text_err_email_phone}!</>);
    } else {
      var API_URL =
        GlobalConstants.Cdomain + "/api/breatheasy/v1/user/resendverifylink";
      var data = {
        username: ResendEmail,
      };
      axios
        .post(API_URL, data)
        .then(function (response) {
          if (response.status) {
            // successToast(response.data.message);
            setSuccessMsg(response.data.message);
            setOpenresend(true);
          }
        })
        .catch(function (error) {
          // errorToast(error.response.data.message);
          setHelperr(error.response.data.message);
        });
    }
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_help_you(
      xpath.select1("/resources/string[@name='text_help_you']", doc).firstChild
        .data
    );
    setText_resend_link(
      xpath.select1("/resources/string[@name='text_resend_link']", doc)
        .firstChild.data
    );
    setText_contact_us(
      xpath.select1("/resources/string[@name='text_contact_us']", doc)
        .firstChild.data
    );
    setText_contact_at(
      xpath.select1("/resources/string[@name='text_contact_at']", doc)
        .firstChild.data
    );
    setText_more_help(
      xpath.select1("/resources/string[@name='text_more_help']", doc).firstChild
        .data
    );
    setText_verify_account(
      xpath.select1("/resources/string[@name='text_verify_account']", doc)
        .firstChild.data
    );
    setText_enter_email_phone(
      xpath.select1("/resources/string[@name='text_enter_email_phone']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handleCloseresend = () => {
    setOpenresend(false);
    navigate("/login");
  };
  return (
    <>
      <div className="row loginMain">
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={openresend}
            onClose={handleCloseresend}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <FcFeedback className="Email-icon" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p className="forgotsuccess  mb-4">{successmsg}</p>
              </DialogContentText>
              <Divider />
            </DialogContent>
            <DialogActions>
              <button
                className="Cancelbtnforgot mx-4 w-25"
                onClick={handleCloseresend}
              >
                Ok
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="col-md-6" id="mobilebg">
          <img className="imgstyle" src={loginImg} alt="" />
        </div>
        <div className="col-md-6">
          <div className="LoginHalfBG">
            <div className="LoginBox">
              <center>
                <img src={LogoImg} alt="" className="LoginLogoSty mb-3" />
                <h3 className="help_text mt-1">{text_help_you}</h3>
                <h4 className="HeadlineText">{text_verify_account}</h4>
                <div>
                  <Divider />
                  <div>
                    <TextField
                      id="outlined-textarea"
                      label={text_enter_email_phone}
                      type="text"
                      placeholder="abc@xyz.com or +1 xxxxxxxxx"
                      className="header-signup-inputs verify-input mt-4"
                      value={ResendEmail}
                      onChange={(e) => [handleChange(e), setErrResendEmail(""),setHelperr("")]}
                    />
                    <br />
                    <span className="ErrMessage">{errResendEmail}</span>
                    <p className="regErr-p  mb-4 mt-3">{helperr}</p>
                    <div className="d-flex flex-wrap justify-content-center gap-4  mt-4">
                      <button
                        className="Cancelbtnforgot w-25"
                        onClick={() => navigate("/login")}
                      >
                        Cancel
                      </button>
                      <button
                        className="SignInBtn w-25"
                        onClick={sendVerifyEmail}
                      >
                        Send Link
                      </button>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </center>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Helppage;
