import DataTable from "react-data-table-component";
import { BsShare } from "react-icons/bs";
import { customTableStyles } from "../../utils/DataTableStyle";
import { useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import DatePicker from "react-datepicker";
import { BiEditAlt } from "react-icons/bi";
import Cookie from "js-cookie";
import Loader from "../../utils/Loader";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { Link, useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import moment from "moment";
import { EMAIL_REGEX, Number_REGEX } from "../../utils/Regex";
import { Divider } from "@mui/material";
import { getAllDevicesInPopup } from "../../redux/DeviceListSlice";
import { useDispatch, useSelector } from "react-redux";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function SharedTo() {
  const dispatch = useDispatch();
  const DeviceList = useSelector((state) => state.deviceList);
  //language variable start
  const [text_share_your_data, setText_share_your_data] =
    useState("Share Your Data");
  const [text_share_your_data_to, setText_share_your_data_to] =
    useState("Share Your Data to");
  const [text_email_phone, setText_email_phone] = useState(
    "Email or Phone Number"
  );
  const [saveDisable, setSaveDisable] = useState(false);
  const [text_nick_name, setText_nick_name] = useState("Nick Name");
  const [text_access_validity, setText_access_validity] =
    useState("Access Validity");
  const [text_from_date, setText_from_date] = useState("From Date");
  const [text_data_period, setText_data_period] = useState("Data Period");
  const [text_till_date, setText_till_date] = useState("Till Date");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_share, setBtn_share] = useState("Share");
  const [text_accessvalidity_date, setText_accessvalidity_date] = useState(
    "Access Validity from-till Date"
  );
  const [text_data_period_date, setText_data_period_date] = useState(
    "Data Period from-till Date"
  );
  const [text_status, setText_status] = useState("Status");
  const [text_disable_access, setText_disable_access] =
    useState("Disable Access");
  const [text_disabled, setText_disabled] = useState("Disabled");
  const [text_disable, setText_disable] = useState("Disable");
  const [text_err_access_date, setText_err_access_date] = useState(
    "Access Till Date Should Be Greater Than From Date"
  );
  const [text_err_email_phone, setText_err_email_phone] = useState(
    "Please Enter Email or Phone Number"
  );
  const [text_valid_email_phone, setText_valid_email_phone] = useState(
    "Please Enter Valid Email or Phone Number"
  );
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_share_to, setText_share_to] = useState("Data Share To");
  const [text_edit, setText_edit] = useState("Edit");
  const [btn_update, setBtn_update] = useState("Update");
  const [text_assign_nick_name, setText_assign_nick_name] =
    useState("Assign Nick Name");
  const [text_select_device, setText_select_device] = useState("Select Device");
  const [text_accessfromdate, setTextaccessfromdate] =
    useState("Access From Date");
  const [text_accesstilldate, setTexttillfromdate] =
    useState("Access Till Date");
  const [text_dataperiod, setText_dataperiod] = useState(
    "Data Period From Date"
  );
  const [text_dataperiodtill, setText_dataperiodtill] = useState(
    "Data Period Till Date"
  );
  const [text_access, setText_access] = useState("Access");
  const [text_on, setText_on] = useState("on");
  const [text_off, setText_off] = useState("off");
  //language variable end

  const navigate = useNavigate();
  const [SharedDataPopup, setSharedDataPopup] = useState(false);
  const [accessFromDate, setAccessFromDate] = useState(new Date());
  const FinaltillDate = new Date();
  const [accesstillDate, setAccesstillDate] = useState(
    FinaltillDate.setDate(FinaltillDate.getDate() + 1)
  );
  const [DataPeriodtillDate, setDataPeriodtillDate] = useState(new Date());
  const [DataPeriodFromDate, setDataPeriodFromDate] = useState(new Date());
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [nickName, setNickName] = useState("");
  const [sharedDataList, setSharedDataList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [editable, setEditable] = useState(false);
  const [accesstillDateupadte, setAccesstillDateupdate] = useState(new Date());
  const [dataPeriodTillDateUpdate, setDataPeriodTillDateUpdate] = useState(
    new Date()
  );
  const [editDataId, setEditDataId] = useState("");
  const [errEmailOrPhone, setErrEmailOrPhone] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [errAcessDate, setErrAcessDate] = useState("");
  const [errAddDevice, setErrAddDevice] = useState("");
  const [noDevice, setNoDevice] = useState(false);
  const [SelecteddeviceID, setSelecteddeviceID] = useState("All");
  const [AllDeviceData, setAllDeviceData] = useState([]);
  const [disableAccess, setDisableAccess] = useState(false);
  const [disableInput, setDisableInput] = useState(true);

  useEffect(() => {
    if (SharedDataPopup) {
      document.getElementById("MainDiv").style.pointerEvents = "none";
      document.getElementById("DashboardMainPage").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage2").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage3").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage").style.pointerEvents = "none";
      document.getElementById("DashboardMainPage2").style.pointerEvents =
        "none";
      document.getElementById("DashboardMainPage3").style.pointerEvents =
        "none";
    } else {
      document.getElementById("DashboardMainPage").style.filter = "blur(0px)";
      document.getElementById("DashboardMainPage2").style.filter = "blur(0px)";
      document.getElementById("DashboardMainPage3").style.filter = "blur(0px)";
      document.getElementById("MainDiv").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage2").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage3").style.pointerEvents = "all";
    }
  }, [SharedDataPopup]);

  const disableBtnFunc = (index) => {
    document.getElementById(index).disabled = false;
  };
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_share_to}</>,
      selector: (row) =>
        row.nickname === "" || row.nickname === null
          ? row.emailorphone
          : row.nickname,
      width: "190px",
    },
    {
      name: <>{text_accessfromdate}</>,
      selector: (row) =>
        moment(row.accesstill_startdate).format("MMM DD, yyyy"),
      width: "240px",
    },
    {
      name: <>{text_accesstilldate}</>,
      selector: (row, index) => (
        <>
          {editDataId !== row._id || disableInput ? (
            moment(row.accesstill_enddate).format("MMM DD, yyyy")
          ) : (
            <>
              <DatePicker
                portalId="root-portal"
                className="DataShareToDpStyle"
                selected={accesstillDateupadte}
                onChange={(date) => [
                  setAccesstillDateupdate(date),
                  disableBtnFunc(index),
                ]}
                dateFormat="MMM dd, yyyy"
                minDate={new Date(row.accesstill_startdate).setDate(
                  new Date(row.accesstill_startdate).getDate() + 1
                )}
                id={"AccesstillDate" + index}
                key={index}
                showMonthDropdown
                showYearDropdown
              />
            </>
          )}
        </>
      ),
      width: "250px",
    },
    {
      name: <>{text_dataperiod}</>,
      selector: (row) =>
        moment(row.dataperiod_startdate).format("MMM DD, yyyy"),
      width: "270px",
    },
    {
      name: <>{text_dataperiodtill}</>,
      selector: (row, index) => (
        <>
          {editDataId !== row._id || disableInput ? (
            <>{moment(row.dataperiod_enddate).format("MMM DD, yyyy")}</>
          ) : (
            <>
              <DatePicker
                portalId="root-portal"
                className="DataShareToDpStyle"
                selected={dataPeriodTillDateUpdate}
                onChange={(date) => [
                  setDataPeriodTillDateUpdate(date),
                  disableBtnFunc(index),
                ]}
                dateFormat="MMM dd, yyyy"
                id={"AccesstillDate" + index}
                key={index}
                showMonthDropdown
                showYearDropdown
              />
            </>
          )}
        </>
      ),
      width: "250px",
    },
    {
      name: <>{text_status}</>,
      selector: (row) => (
        <span
          className={
            row.requeststatus === "Rejected"
              ? "text-danger"
              : row.requeststatus === "Accepted"
              ? "text-success"
              : "text-pending"
          }
        >
          {row.requeststatus}
        </span>
      ),
      width: "170px",
    },
    {
      name: <>{text_access}</>,
      selector: (row, i) => (
        <>
          {row.isdisable === false ? (
            <>
              <div className="offbtn-sty-div-1">
                <button
                  className="onbtn-sty"
                  onClick={() => handleDisableData(row)}
                  disabled={disableAccess}
                >
                  {text_on}
                </button>
                <button
                  className="offbtn-sty"
                  onClick={() => handleDisableData(row)}
                  disabled={disableAccess}
                >
                  {text_off}
                </button>
              </div>
            </>
          ) : (
            <>
              {Date.parse(row?.accesstill_enddate) >= Date.parse(new Date()) ? (
                <>
                  <div className="offbtn-sty-div">
                    <button
                      className="disable-btn"
                      onClick={() => handleDisableData(row)}
                      disabled={disableAccess}
                    >
                      {text_on}
                    </button>
                    <button
                      className="enable-btn"
                      onClick={() => handleDisableData(row)}
                      disabled={disableAccess}
                    >
                      {text_off}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="offbtn-sty-div">
                    <button
                      className="disable-btn"
                      onClick={() => handleDisableData(row)}
                      disabled
                    >
                      {text_on}
                    </button>
                    <button
                      className="enable-btn"
                      onClick={() => handleDisableData(row)}
                      disabled
                    >
                      {text_off}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ),
      width: "200px",
    },
    {
      name: <>{text_edit}</>,
      selector: (row, index) => (
        <>
          {row.isdisable === true ? (
            <>
              {" "}
              <button className="edit_btn_sty" disabled>
                {" "}
                <BiEditAlt className="editBtn" />
              </button>
            </>
          ) : (
            <>
              {" "}
              {editDataId !== row._id || disableInput ? (
                <>
                  <button
                    className="edit_btn_sty"
                    onClick={(i) => [
                      setEditable(!editable),
                      disableBtnFunc(index),
                      setEditDataId(row._id),
                      setAccesstillDateupdate(new Date(row.accesstill_enddate)),
                      setDataPeriodTillDateUpdate(
                        new Date(row.dataperiod_enddate)
                      ),
                      setDisableInput(false),
                      console.log(index, row),
                    ]}
                    id={"" + index}
                    key={index}
                  >
                    {" "}
                    <BiEditAlt className="editBtn" />
                  </button>
                </>
              ) : (
                <button
                  className="savebtn"
                  onClick={() => [
                    handleUpdateshareData(row, index),
                    disableBtnFunc(index),
                  ]}
                  id={index}
                  key={index}
                >
                  {btn_update}
                </button>
              )}
            </>
          )}
        </>
      ),
      width: "150px",
    },
  ];

  const handleUpdateshareData = (updateData, index) => {
    document.getElementById(index).disabled = true;

    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/sharedatato?type=update";
    var UpdateshareData = {
      _id: updateData._id,
      Isalldevice: updateData.Isalldevice,
      deviceList: updateData.deviceList,
      requestername: updateData.requestername,
      receivername: updateData.receivername,
      emailorphone: updateData.emailorphone,
      nickname: updateData.nickname,
      isdisable: updateData.isdisable,
      accesstill_startdate: moment(updateData.accesstill_startdate).format(
        "YYYY-MM-DD"
      ),
      accesstill_enddate: moment(accesstillDateupadte).format("YYYY-MM-DD"),
      dataperiod_startdate: moment(updateData.dataperiod_startdate).format(
        "YYYY-MM-DD"
      ),
      dataperiod_enddate: moment(dataPeriodTillDateUpdate).format("YYYY-MM-DD"),
    };
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, UpdateshareData, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          successToast(response.data.message);
          const $DateUpdate = document.getElementById("AccesstillDate" + index);
          $DateUpdate.value = new Date();
          setEditDataId("");
          getShareToData();
        }
      })
      .catch(function (err) {
        console.log(err);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };

  const ShareDataValidation = () => {
    let shareDataPopupValidation = true;
    // if (emailOrPhone === "") {
    //   shareDataPopupValidation = false;
    //   setErrEmailOrPhone(<>*{text_err_email_phone}!</>);
    // }
    // if (emailOrPhone === "+") {
    //   shareDataPopupValidation = false;

    //   if (!EMAIL_REGEX.test(emailOrPhone) === true) {
    //     shareDataPopupValidation = false;
    if (emailOrPhone === "") {
      shareDataPopupValidation = false;
      setErrEmailOrPhone(<>*{text_err_email_phone}!</>);
    }
    
    if (
      moment(accessFromDate).format("MMM DD, yyyy") ===
      moment(accesstillDate).format("MMM DD, yyyy")
    ) {
      shareDataPopupValidation = false;
      setErrAcessDate(<>*{text_err_access_date}!</>);
    }
    setSaveDisable(false);
    return shareDataPopupValidation;
  };

  const handleChange = (e) => {
    var value = e.target.value;
    if (Number_REGEX.test(value) === true) {
      setEmailOrPhone("+" + e.target.value);
    } else {
      setEmailOrPhone(e.target.value);
    }
  };
  const handleShareData = () => {
    if (ShareDataValidation()) {
      setSaveDisable(true);
      var SingleDeviceData = [];
      SingleDeviceData.push(SelecteddeviceID);
      var firstName = sessionStorage.getItem("firstname");
      var lastName = sessionStorage.getItem("lastname");
      var requesterName = firstName + " " + lastName;
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/device/sharedatato?type=insert";
      var shareData = {
        _id: "",
        Isalldevice: SelecteddeviceID === "All" ? true : false,
        deviceList:
          SelecteddeviceID === "All" ? AllDeviceData : SingleDeviceData,
        requestername: requesterName,
        receivername: nickName,
        emailorphone: emailOrPhone,
        nickname: nickName,
        isdisable: false,
        accesstill_startdate: moment(accessFromDate).format("YYYY-MM-DD"),
        accesstill_enddate: moment(accesstillDate).format("YYYY-MM-DD"),
        dataperiod_startdate: moment(DataPeriodFromDate).format("YYYY-MM-DD"),
        dataperiod_enddate: moment(DataPeriodtillDate).format("YYYY-MM-DD"),
      };

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .post(api_url, shareData, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            successToast(response.data.message);
            setSaveDisable(false);
            setSharedDataPopup(false);
            setNickName("");
            setEmailOrPhone("");
            setAccessFromDate(new Date());
            setSelecteddeviceID("All");
            setAllDeviceData([]);
            const FinaltillDate = new Date();

            setAccesstillDate(
              FinaltillDate.setDate(FinaltillDate.getDate() + 1)
            );
            setDataPeriodFromDate(new Date());
            setDataPeriodtillDate(new Date());
            getShareToData();
          }
        })
        .catch(function (err) {
          setSaveDisable(false);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            if (
              err.response.data.message ===
              "You can not give access to your own email/phone"
            ) {
              setErrEmailOrPhone(<>*{err.response.data.message}!</>);
            }
            if (
              err.response.data.message === "access time greater then 1 hour"
            ) {
              setErrAcessDate(<>*{err.response.data.message}!</>);
            } else {
              console.log("Error! Try Again.", err.response.data.message);
              // errorToast(err.response.data.message);
              setErrEmailOrPhone(<>*{err.response.data.message}!</>);
            }
          }
        });
    }
  };

  const getShareToData = () => {
    // setIsLoading(true);
    var userID = sessionStorage.getItem("user_id");
    var DeviceID = sessionStorage.getItem("deviceID");
    if (DeviceID === null || DeviceID === "") {
      setNoDevice(true);
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/device/sharedatato/selectall?_userId=${userID}&_deviceId=${DeviceID}`;
      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          console.log("all devices res***", res.data.data);
          var data = res.data.data;
          setIsLoading(false);
          if (data !== null) {
            setSharedDataList(data);
          }
        })
        .catch(function (err) {
          //  errorToast(err.response.data.message);
          if (err.response.status === 427) {
            setIsLoading(false);
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
            errorToast(err.response.data.message);
          }
        });
    }
  };
  useEffect(() => {
    getShareToData();
  }, [1000]);

  const handleDisableData = (data) => {
    setDisableAccess(true);
    var UserID = sessionStorage.getItem("user_id");
    var AccessDisable = !data.isdisable;
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/sharedatato/disable?type=disable";

    var disableData = {
      _id: data._id,
      _userId: UserID,
      isdisable: AccessDisable,
      RequestSratus: data.RequestSratus,
      emailorphone: data.emailorphone,
    };
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, disableData, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          setEditable(false);
          setDisableInput(true);
          setTimeout(() => {
            setDisableAccess(false);
          }, 2000);
          getShareToData();
        }
      })
      .catch(function (err) {
        setDisableAccess(false);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");

          errorToast(err.response.data.message);
        }
      });
  };

  const filterSharedata = sharedDataList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const closePopup = () => {
    setSharedDataPopup(false);
    setErrEmailOrPhone("");
    setNickName("");
    setEmailOrPhone("");
    setAccessFromDate(new Date());
    const FinaltillDate = new Date();
    setAccesstillDate(FinaltillDate.setDate(FinaltillDate.getDate() + 1));
    setDataPeriodFromDate(new Date());
    setDataPeriodtillDate(new Date());
    setErrAcessDate("");
    setSelecteddeviceID("All");
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_share_your_data(
      xpath.select1("/resources/string[@name='text_share_your_data']", doc)
        .firstChild.data
    );
    setText_share_your_data_to(
      xpath.select1("/resources/string[@name='text_share_your_data_to']", doc)
        .firstChild.data
    );
    setText_email_phone(
      xpath.select1("/resources/string[@name='text_email_phone']", doc)
        .firstChild.data
    );
    setText_nick_name(
      xpath.select1("/resources/string[@name='text_nick_name']", doc).firstChild
        .data
    );
    setText_access_validity(
      xpath.select1("/resources/string[@name='text_access_validity']", doc)
        .firstChild.data
    );
    setText_from_date(
      xpath.select1("/resources/string[@name='text_from_date']", doc).firstChild
        .data
    );
    setText_data_period(
      xpath.select1("/resources/string[@name='text_data_period']", doc)
        .firstChild.data
    );
    setText_till_date(
      xpath.select1("/resources/string[@name='text_till_date']", doc).firstChild
        .data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_share(
      xpath.select1("/resources/string[@name='btn_share']", doc).firstChild.data
    );
    setText_accessvalidity_date(
      xpath.select1("/resources/string[@name='text_accessvalidity_date']", doc)
        .firstChild.data
    );
    setText_data_period_date(
      xpath.select1("/resources/string[@name='text_data_period_date']", doc)
        .firstChild.data
    );
    setText_status(
      xpath.select1("/resources/string[@name='text_status']", doc).firstChild
        .data
    );
    setText_disable_access(
      xpath.select1("/resources/string[@name='text_disable_access']", doc)
        .firstChild.data
    );
    setText_disabled(
      xpath.select1("/resources/string[@name='text_disabled']", doc).firstChild
        .data
    );
    setText_disable(
      xpath.select1("/resources/string[@name='text_disable']", doc).firstChild
        .data
    );
    setText_err_access_date(
      xpath.select1("/resources/string[@name='text_err_access_date']", doc)
        .firstChild.data
    );
    setText_err_email_phone(
      xpath.select1("/resources/string[@name='text_err_email_phone']", doc)
        .firstChild.data
    );
    setText_valid_email_phone(
      xpath.select1("/resources/string[@name='text_valid_email_phone']", doc)
        .firstChild.data
    );
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_share_to(
      xpath.select1("/resources/string[@name='text_share_to']", doc).firstChild
        .data
    );
    setText_edit(
      xpath.select1("/resources/string[@name='text_edit']", doc).firstChild.data
    );
    setText_select_device(
      xpath.select1("/resources/string[@name='text_select_device']", doc)
        .firstChild.data
    );
    setText_assign_nick_name(
      xpath.select1("/resources/string[@name='text_assign_nick_name']", doc)
        .firstChild.data
    );
    setBtn_update(
      xpath.select1("/resources/string[@name='btn_update']", doc).firstChild
        .data
    );
    setTextaccessfromdate(
      xpath.select1("/resources/string[@name='text_accessfromdate']", doc)
        .firstChild.data
    );
    setTexttillfromdate(
      xpath.select1("/resources/string[@name='text_accesstilldate']", doc)
        .firstChild.data
    );
    setText_dataperiod(
      xpath.select1("/resources/string[@name='text_dataperiod']", doc)
        .firstChild.data
    );
    setText_dataperiodtill(
      xpath.select1("/resources/string[@name='text_dataperiodtill']", doc)
        .firstChild.data
    );
    setText_access(
      xpath.select1("/resources/string[@name='text_access']", doc).firstChild
        .data
    );
    setText_on(
      xpath.select1("/resources/string[@name='text_on']", doc).firstChild.data
    );
    setText_off(
      xpath.select1("/resources/string[@name='text_off']", doc).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    var filterDeviceID = DeviceList.filter((e) => e._id);
    if (filterDeviceID.length !== 0) {
      setAllDeviceData(filterDeviceID);
    }
  }, [SharedDataPopup]);
  return (
    <>
      <div className={SharedDataPopup ? "blurBg" : ""} id="MainDiv">
        <div className="mt-3 d-flex justify-content-end">
          <button
            className="roundedBtn"
            onClick={() => [
              setSharedDataPopup(!SharedDataPopup),
              dispatch(getAllDevicesInPopup()),
            ]}
          >
            {" "}
            <BsShare />
            &nbsp; {text_share_your_data}
          </button>
        </div>
        {IsLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader />
          </div>
        ) : (
          <>
            <div className="mt-4 px-5 pe-5 pagination-select">
              <DataTable
                columns={columns}
                data={filterSharedata}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
                pagination
                customStyles={customTableStyles}
              />
            </div>
          </>
        )}
      </div>

      {SharedDataPopup && (
        <div className="Popup addmedicinePopup sharedtopopup">
          <div className="text-end">
            <CgCloseO size={25} onClick={() => closePopup()} />
          </div>
          <h5 className="text-center">{text_share_your_data_to}</h5>
          <Divider />
          <div className="main-div-sty mt-5">
            <h5 className="">{text_select_device}</h5>
            <div className="select-device-sty">
              <select
                className="select-device  "
                onChange={(e) => setSelecteddeviceID(e.target.value)}
                value={SelecteddeviceID}
              >
                <option value="All" selected>
                  All{" "}
                </option>
                {DeviceList.map((val) => {
                  return (
                    <option value={val._id}>{val.Devicecustomname}</option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="main-div-sty mt-5">
            <h5 className="">
              {text_email_phone}
              <span className="text-danger">*</span>
            </h5>
            <div className="email-phone-div">
              <input
                type="text"
                className="nickname-input-sty  "
                placeholder={text_email_phone}
                onChange={(e) => [handleChange(e), setErrEmailOrPhone("")]}
                value={emailOrPhone}
              />
            </div>
          </div>
          <span className="ErrMessage access-data-error">
            {errEmailOrPhone}
          </span>
          <div className="main-div-sty mt-4">
            <h5 className="">{text_assign_nick_name}</h5>
            <div className="nickname-div">
              <input
                type="text"
                className="nickname-input-sty  "
                placeholder={text_nick_name}
                onChange={(e) => setNickName(e.target.value)}
                value={nickName}
              />
            </div>
          </div>

          <div className="email-div mt-4">
            <h5 className="mt-4">{text_access_validity}</h5>
            <div className="d-flex gap-2 from-tilldate-sty">
              <div className="sharetodataDatepicker fromdate-sty">
                <h6>{text_from_date}</h6>
                <DatePicker
                  className=" mob-shareto-date"
                  selected={accessFromDate}
                  onChange={(date) => [
                    setAccessFromDate(date),
                    setErrAcessDate(""),
                  ]}
                  dropdownMode="select"
                  dateFormat="dd MMM, yyyy"
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="sharetodataDatepicker">
                <h6>{text_till_date}</h6>
                <DatePicker
                  className=" mob-shareto-date"
                  dropdownMode="select"
                  selected={accesstillDate}
                  onChange={(date) => [
                    setAccesstillDate(date),
                    setErrAcessDate(""),
                  ]}
                  dateFormat="dd MMM, yyyy"
                  minDate={accessFromDate}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <span className="ErrMessage text-center access-data-error">
            {errAcessDate}
          </span>

          <div className="email-div mt-3">
            <h5 className="mt-4">{text_data_period}</h5>
            <div className="d-flex gap-2 from-tilldate-sty" id="from-till-data">
              <div className="sharetodataDatepicker dataperiod-fromdate">
                <h6>{text_from_date}</h6>
                <DatePicker
                  className="mob-shareto-date"
                  selected={DataPeriodFromDate}
                  dropdownMode="select"
                  onChange={(date) => setDataPeriodFromDate(date)}
                  dateFormat="dd MMM, yyyy"
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="sharetodataDatepicker">
                <h6>{text_till_date}</h6>
                <DatePicker
                  className="mob-shareto-date"
                  selected={DataPeriodtillDate}
                  dropdownMode="select"
                  onChange={(date) => setDataPeriodtillDate(date)}
                  dateFormat="dd MMM, yyyy"
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <div>
            <p className="ErrMessage text-center">{errAddDevice}</p>
          </div>
          <div className="d-flex gap-3 justify-content-center ">
            <button className="cancelbtn" onClick={() => closePopup()}>
              {btn_cancel}
            </button>
            <button
              className="savebtn shareBTN"
              onClick={handleShareData}
              disabled={saveDisable}
            >
              {btn_share}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SharedTo;
