import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import "./Weather.css";
import WeatherImg1 from "../../assets/img/Weather_bg_1.png";
import WeatherImg2 from "../../assets/img/Weather_bg_2.png";
import WeatherImg3 from "../../assets/img/Weather_bg_3.png";
import Cookie from "js-cookie";

import { MdOutlineWbSunny } from "react-icons/md";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { WiDaySunnyOvercast } from "react-icons/wi";
import { WiHumidity } from "react-icons/wi";
import { MdOutlineWindPower } from "react-icons/md";
import { WiDayRainWind } from "react-icons/wi";
import { WiNightAltCloudyWindy } from "react-icons/wi";
import { WiWindy } from "react-icons/wi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { errorToast, warnToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Weather() {
  const navigate = useNavigate();
  const Curr_Temp = 44;
  //language Variable
  const [text_humidity, setText_humidity] = useState("Humidity");
  const [text_wind_speed, setText_wind_speed] = useState("Wind Speed");
  const [text_air_quality, setText_air_quality] = useState("Air Quality");
  const [text_monthly_weather, setText_monthly_weather] =
    useState("Monthly Weather");
  const [text_temperature, setText_temperature] = useState("Temperature");
  const [text_location, setText_location] = useState(
    "Please Enable Location Permission From Your Browser."
  );

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [wetherData, setWetherData] = useState([]);
  const [humidity, setHumidity] = useState("");
  const [airQuality, setAirQuality] = useState("");
  const [windSpeed, setwindSpeed] = useState("");
  const [locationName, setLocationName] = useState("");
  const [temperater, setTemperature] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [monthlyWetherData, setMonthlyWetherData] = useState("");
  const [permission, setPermission] = useState(false);
  console.log(navigator);
  // navigator.geolocation.watchPosition(function(position) {
  //   console.log("i'm tracking you!");
  // },
  // function(error) {
  //   if (error.code == error.PERMISSION_DENIED)
  //     console.log("you denied me :-(");
  //   warnToast("you denied me");

  // });
  useEffect(() => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      errorToast("in if condition");
    } else {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPermission(false);
          setLat(position.coords.latitude);
          setLong(position.coords.longitude);
          console.log(position.coords);
          console.log(navigator);
        },
        function (error) {
          console.log(error.code);
          if (error.code === error.PERMISSION_DENIED)
            console.log("you denied me :-(");
          setPermission(true);
          setIsLoading(false);
        }
      );

      if (lat !== "" && long !== "") {
        setIsLoading(true);
        setPermission(false);
        var api_url =
          GlobalConstants.Cdomain +
          `/api/breatheasy/v1/user/weatherdata?lat=${lat}&lon=${long}`;
        const headerConfig = {
          headers: {
            accept: "application/json",
            "auth-token": sessionStorage.getItem("token"),
          },
        };

        axios
          .get(api_url, headerConfig)
          .then((res) => {
            console.log(res.data.data);
            var weatherData = res.data.data;
            var value = weatherData.resdata.setData;
            setWetherData(value);
            setIsLoading(false);
            setHumidity(value.main.humidity);
            setwindSpeed(value.wind.speed);
            setLocationName(value.name);
            setMonthlyWetherData(weatherData.resdata);
            setAirQuality(weatherData.airpollution.list[0].main.aqi);
            setTemperature(value.main.temp);
          })
          .catch(function (err) {
            if (err.response.status === 427) {
              setIsLoading(false);
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("token");
              Cookie.remove("user_email");
              Cookie.remove("firstname");
              Cookie.remove("lastname");
              Cookie.remove("user_id");
              Cookie.remove("token_start_time");
              setTimeout(() => {
                navigate("/");
              }, 2000);
            } else {
              console.log("Error! Try Again.");
              errorToast(err.response.data.message);
            }
          });
      }
    }
  }, [lat, long, permission]);

  const WEATHER_DATA = [
    {
      icon: <WiHumidity size={"45px"} />,
      value: humidity,
      lable: <>{text_humidity}</>,
      mark: "%",
    },
    {
      icon: <MdOutlineWindPower size={"45px"} />,
      value: windSpeed,
      lable: <>{text_wind_speed}</>,
      mark: "m/s",
    },
    {
      icon: <WiWindy size={"45px"} />,
      value: airQuality,
      lable: <>{text_air_quality}</>,
      mark: "AQI",
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: text_monthly_weather,
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: text_temperature,
        data: [
          monthlyWetherData.January,
          monthlyWetherData.February,
          monthlyWetherData.March,
          monthlyWetherData.April,
          monthlyWetherData.May,
          monthlyWetherData.June,
          monthlyWetherData.July,
          monthlyWetherData.August,
          monthlyWetherData.September,
          monthlyWetherData.October,
          monthlyWetherData.November,
          monthlyWetherData.December,
        ],
        fill: false,
        borderColor: "#0D334D",
        tension: 0,
      },
    ],
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_humidity(
      xpath.select1("/resources/string[@name='text_humidity']", doc).firstChild
        .data
    );
    setText_wind_speed(
      xpath.select1("/resources/string[@name='text_wind_speed']", doc)
        .firstChild.data
    );
    setText_air_quality(
      xpath.select1("/resources/string[@name='text_air_quality']", doc)
        .firstChild.data
    );
    setText_monthly_weather(
      xpath.select1("/resources/string[@name='text_monthly_weather']", doc)
        .firstChild.data
    );
    setText_temperature(
      xpath.select1("/resources/string[@name='text_temperature']", doc)
        .firstChild.data
    );
    setText_location(
      xpath.select1("/resources/string[@name='text_location']", doc).firstChild
        .data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <Header />
      <main className="px-5 py-3">
        {IsLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader />
          </div>
        ) : (
          <>
            {permission === true ? (
              <>
                <div className="mt-5">
                  <h4 className="text-danger text-center mt-5">
                    {text_location}
                  </h4>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div
                  className="weather-hero-box"
                  style={{
                    backgroundImage:
                      temperater < 30
                        ? `url(${WeatherImg2})`
                        : `url(${WeatherImg1})`,
                  }}
                >
                  <div className="weather-box-content">
                    <MdOutlineWbSunny color="#fff" size={"80px"} />
                    <h1 className="current-temp-text">{temperater}°C</h1>
                    <p className="current-location-text">
                      <em>{locationName}</em>
                    </p>
                  </div>
                </div>
                <div className="weather-details-box mt-5">
                  <div className="weather-data">
                    {WEATHER_DATA.map((Data, index) => (
                      <div
                        className="weather-type-data d-flex gap-5 align-items-center mt-3"
                        key={index}
                      >
                        <div>{Data.icon}</div>
                        <div>
                          <h4>{Data.lable}</h4>
                          <lable>
                            <em>
                              {Data.value}&nbsp;
                              {Data.mark}
                            </em>
                          </lable>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="weather-graph">
                    <Line options={options} data={data} />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </main>
      <ToastContainer />
    </>
  );
}

export default Weather;
