import moment from "moment";
import React, { useState, useEffect } from "react";
import img1 from "../../assets/img/Inhaler_img 1.png";
import DataTable from "react-data-table-component";
import { customTableStyles } from "../../utils/DataTableStyle";
import Header from "../Header/Header";
import Cookie from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import Loader from "../../utils/Loader";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function PriorMedicineHistory() {
  const navigate = useNavigate();

  //language start
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_inhaler_position, setText_inhaler_position] =
    useState("Inhaler Position");
  const [text_temperature, setText_temperature] = useState("Temperature");
  const [text_shake_time, setText_shake_time] = useState("Shake Time");
  const [text_dose_left, setText_dose_left] = useState("Dose Left");
  const [text_schedule_time, setText_schedule_time] = useState("Schedule Time");
  const [text_medicine_name, setText_medicine_name] = useState("Medicine Name");
  const [text_device_name, setText_device_name] = useState("Device Name");
  const [text_dose_taken, setText_dose_taken] = useState("Dose Taken");
  const [text_override_time, setText_override_time] = useState("Override Time");
  const [text_date, setText_date] = useState("Date");
  const [text_dose_override, setText_dose_override] = useState("Dose Override");
  const [text_time_taken, setText_time_taken] = useState("Time Taken");
  const [text_number_of_puff_dose, setText_number_of_puff_dose] = useState(
    "Number of Puff/Dose"
  );
  //language end
  const data = useLocation();
  const [IsLoading, setIsLoading] = useState(true);
  const [medicineFinalData, setMedicineFinalData] = useState([]);
  const MedicineData = data.state.data;
  const Medicinename = data.state.MedicineName;
  const DeviceName = data.state.deviceName;
  //   console.log(MedicineData.state);
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_device_name}</>,
      selector: (row) => DeviceName,
    },
    {
      name: <>{text_medicine_name}</>,
      selector: (row) => <>{row.Genericname === "" ? "-" : row.Genericname}</>,
    },
    {
      name: <>{text_date}</>,
      selector: (row) => moment(row.dosetakendate).format("MMM DD, yyyy"),
    },
    {
      name: <>{text_inhaler_position}</>,
      selector: (row) => (
        <img
          src={img1}
          alt=""
          className={
            row.Inhale_Position === "Slightly wrong"
              ? "slightlypostion"
              : row.Inhale_Position === "Incorrect"
              ? "incorrectpostion"
              : "idealpostion"
          }
        />
      ),
      width: "180px",
    },
    {
      name: <>{text_temperature}</>,
      selector: (row) => <>{row.Temperature === "" ? "-" : row.Temperature}</>,
    },
    {
      name: <>{text_shake_time}</>,
      selector: (row) => <>{row.Shake === "" ? "-" : row.Shake}</>,
    },
    {
      name: <>{text_dose_taken}</>,
      selector: (row) => <>{row.isdosetaken === true ? "✅" : "❌"}</>,
    },
    {
      name: <>{text_dose_left}</>,
      selector: (row) => row.doseleft,
    },
    {
      name: <>{text_number_of_puff_dose}</>,
      selector: (row) => (
        <>
          {
            <span
              className={
                row.overridedose === "" || row.overridedose === null
                  ? ""
                  : "text-danger"
              }
            >
              {row.overridedose === "" || row.overridedose === null
                ? "-"
                : row.overridedose}
            </span>
          }
        </>
      ),
    },
    {
      name: <>{text_time_taken}</>,
      selector: (row) => (
        <>
          {row.dosetakentime === "" || row.dosetakentime === null
            ? "-"
            : row.dosetakentime}
        </>
      ),
    },
    {
      name: <>{text_schedule_time}</>,
      selector: (row) => (
        <>
          {row.scheduletime === "" || row.scheduletime === null
            ? "-"
            : row.scheduletime}
        </>
      ),
    },
    {
      name: <>{text_override_time}</>,
      selector: (row) => (
        <>
          {
            <sapn
              className={
                row.overridedosetakentime === "" ||
                row.overridedosetakentime === null
                  ? ""
                  : "text-danger"
              }
            >
              {row.overridedosetakentime === ""
                ? "-"
                : row.overridedosetakentime}
            </sapn>
          }
        </>
      ),
    },
    // {
    //   name: <>{text_edit}</>,
    //   selector: (row) => (
    //     <div
    //       onClick={() => [
    //         handleOverdoseEdit(row),
    //         setEdittabledose(!edittabledose),
    //       ]}
    //     >
    //       <BiEditAlt className="editBtn" />
    //     </div>
    //   ),
    // },
  ];

  const getMedicineHistory = () => {
    var medicineID = sessionStorage.getItem("MedicineID");
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/medicine/priordosedata?_medicineId=${MedicineData}`;
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        //   var FinalData = [];
        var value = res.data.data;
        //   FinalData.push(value);
        console.log(value);
        setMedicineFinalData(value);
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
        }
      });
  };

  useEffect(() => {
    getMedicineHistory();
  }, []);

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_inhaler_position(
      xpath.select1("/resources/string[@name='text_inhaler_position']", doc)
        .firstChild.data
    );
    setText_temperature(
      xpath.select1("/resources/string[@name='text_inhaler_position']", doc)
        .firstChild.data
    );
    setText_shake_time(
      xpath.select1("/resources/string[@name='text_shake_time']", doc)
        .firstChild.data
    );
    setText_dose_left(
      xpath.select1("/resources/string[@name='text_dose_left']", doc).firstChild
        .data
    );
    setText_schedule_time(
      xpath.select1("/resources/string[@name='text_schedule_time']", doc)
        .firstChild.data
    );
    setText_medicine_name(
      xpath.select1("/resources/string[@name='text_medicine_name']", doc)
        .firstChild.data
    );
    setText_device_name(
      xpath.select1("/resources/string[@name='text_device_name']", doc)
        .firstChild.data
    );
    setText_dose_taken(
      xpath.select1("/resources/string[@name='text_dose_taken']", doc)
        .firstChild.data
    );
    setText_override_time(
      xpath.select1("/resources/string[@name='text_override_time']", doc)
        .firstChild.data
    );
    setText_date(
      xpath.select1("/resources/string[@name='text_date']", doc).firstChild.data
    );
    setText_time_taken(
      xpath.select1("/resources/string[@name='text_time_taken']", doc)
        .firstChild.data
    );
    setText_number_of_puff_dose(
      xpath.select1("/resources/string[@name='text_number_of_puff_dose']", doc)
        .firstChild.data
    );

    setText_dose_override(
      xpath.select1("/resources/string[@name='text_dose_override']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <Header />
      <div className="p-4 mt-5">
        <h4 className="text-center mb-3">{Medicinename}'s history</h4>
        <DataTable
          columns={columns}
          pagination
          data={medicineFinalData}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
          customStyles={customTableStyles}
        />
      </div>
    </>
  );
}

export default PriorMedicineHistory;
