import React, { useState } from "react";
import Header from "../Header/Header";
import DataTable from "react-data-table-component";
import Loader from "../../utils/Loader";
import { customTableStyles } from "../../utils/DataTableStyle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Dashboard/Dashboard.css";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import img1 from "../../assets/img/Inhaler_img 1.png";
import moment from "moment";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function ShareDataDashboard() {
  // Language Variables
  const [text_portal, setText_portal] = useState("Portal");
  const [text_is_paired, setText_is_paired] = useState("is paired with");
  const [text_btn_search, setText_btn_search] = useState("Search");
  const [text_select_startdate, setText_select_startdate] =
    useState("Select Start Date");
  const [text_select_enddate, setText_select_enddate] =
    useState("Select End Date");
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_date, setText_date] = useState("Date");
  const [text_medicine_brand_name, setText_medicine_brand_name] = useState(
    "Medicine Brand name"
  );
  const [text_medicine_generic_name, setText_medicine_generic_name] = useState(
    "Medicine Generic name"
  );
  const [text_inhaler_position, setText_inhaler_position] =
    useState("Inhaler Position");
  const [text_temperature, setText_temperature] = useState("Temperature");
  const [text_shake_time, setText_shake_time] = useState("Shake Time");
  const [text_dose_taken, setText_dose_taken] = useState("Dose Taken");
  const [text_dose_left, setText_dose_left] = useState("Dose Left");
  const [text_dose_override, setText_dose_override] = useState("Dose Override");
  const [text_time_taken, setText_time_taken] = useState("Time Taken");
  const [text_schedule_time, setText_schedule_time] = useState("Schedule Time");
  const [text_override_time, setText_override_time] = useState("Override Time");

  var datastartDate = sessionStorage.getItem("dataperiodStartdate");
  var DataEndDate = sessionStorage.getItem("dataperiodEnddate");

  const [startDate, setStartDate] = useState(new Date(datastartDate));
  const [endDate, setEndDate] = useState(new Date(DataEndDate));
  const [shareDataList, setshareDataList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [NoData, setNoData] = useState("");
  const [finalData, setFinalData] = useState("");
  const deviceName = sessionStorage.getItem("senderDevicename");
  const [IsLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_date}</>,
      selector: (row) => moment(row.dosetakendate).format("MMM DD, yyyy"),
      width: "200px",
    },
    {
      name: <>{text_medicine_brand_name}</>,
      selector: (row) => row.Brandname,
      width: "250px",
    },
    {
      name: <>{text_medicine_generic_name}</>,
      selector: (row) => row.Genericname,
    },
    {
      name: <>{text_inhaler_position}</>,
      selector: (row) => (
        <img
          src={img1}
          alt=""
          className={
            row.Inhale_Position >30
              ? "slightlypostion"
              : row.Inhale_Position >90
              ? "incorrectpostion"
              : "idealpostion"
          }
        />
      ),
      width: "120px",
    },
    {
      name: <>{text_temperature}</>,
      selector: (row) => <>{row.Temperature === "" ? "-" : row.Temperature}</>,
      width: "150px",
    },
    {
      name: <>{text_shake_time}</>,
      selector: (row) => <>{row.Shake === "" ? "-" : row.Shake}</>,
      width: "150px",
    },
    {
      name: <>{text_dose_taken}</>,
      selector: (row) => (
        <>
          {row.dosetakentime === "" || row.dosetakentime === null
            ? "-"
            : row.dosetakentime}
        </>
      ),
      width: "150px",
    },
    {
      name: <>{text_dose_left}</>,
      selector: (row) => row.doseleft,
      width: "120px",
    },
    {
      name: <>{text_dose_override}</>,
      selector: (row) => (
        <>
          {row.overridedose === "" || row.overridedose === null
            ? "-"
            : row.overridedose}
        </>
      ),
      width: "160px",
    },
    {
      name: <>{text_time_taken}</>,
      selector: (row) => (
        <>{row.dosetakentime === "" ? "-" : row.dosetakentime}</>
      ),
      width: "130px",
    },
    {
      name: <>{text_schedule_time}</>,
      selector: (row) => (
        <>
          {row.scheduletime === "" || row.scheduletime === null
            ? "-"
            :row.scheduletime}
        </>
      ),
      width: "160px",
    },
    {
      name: <>{text_override_time}</>,
      selector: (row) => (
        <>
          {row.overridedosetakentime === "" ||
          row.overridedosetakentime === null
            ? "-"
            : row.overridedosetakentime}
        </>
      ),
      width: "150px",
    },
  ];
  const getShareDeviceData = () => {
    var FinalStartDate = moment(startDate).format("YYYY-MM-DD");
    var FinalEndDate = moment(endDate).format("YYYY-MM-DD");
    setIsLoading(true);
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/device/sharedatafrom/selectall?startdate=${FinalStartDate}&enddate=${FinalEndDate}`;
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
        token_for_access: sessionStorage.getItem("token-for-access"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        console.log("all devices res***", res.data.data);
        var value = res.data.data;
        setFinalData(value);
        if (value === null) {
          setIsLoading(false);
          setNoData(res.data.message);
        } else {
          setIsLoading(false);
          setshareDataList(value);
        }
        console.log(value);
      })
      .catch(function (err) {
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          setIsLoading(false);
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getShareDeviceData();
  }, [1000]);

  const filterShareData = shareDataList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const filterDataByDate = () => {
    if (startDate !== null) {
      var DateData = Date.parse(startDate) + Date.parse(endDate);
      var FinalData = shareDataList.filter((val) => {
        return Date.parse();
      });
      console.log(DateData);
    }
  };
  useEffect(() => {
    filterDataByDate();
  }, []);

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_portal(
      xpath.select1("/resources/string[@name='text_portal']", doc).firstChild
        .data
    );
    setText_is_paired(
      xpath.select1("/resources/string[@name='text_is_paired']", doc).firstChild
        .data
    );
    setText_btn_search(
      xpath.select1("/resources/string[@name='text_btn_search']", doc)
        .firstChild.data
    );
    setText_select_startdate(
      xpath.select1("/resources/string[@name='text_select_startdate']", doc)
        .firstChild.data
    );
    setText_select_enddate(
      xpath.select1("/resources/string[@name='text_select_enddate']", doc)
        .firstChild.data
    );
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_date(
      xpath.select1("/resources/string[@name='text_date']", doc).firstChild.data
    );
    setText_medicine_brand_name(
      xpath.select1("/resources/string[@name='text_medicine_brand_name']", doc)
        .firstChild.data
    );
    setText_medicine_generic_name(
      xpath.select1(
        "/resources/string[@name='text_medicine_generic_name']",
        doc
      ).firstChild.data
    );
    setText_inhaler_position(
      xpath.select1("/resources/string[@name='text_inhaler_position']", doc)
        .firstChild.data
    );
    setText_temperature(
      xpath.select1("/resources/string[@name='text_temperature']", doc)
        .firstChild.data
    );
    setText_shake_time(
      xpath.select1("/resources/string[@name='text_shake_time']", doc)
        .firstChild.data
    );
    setText_dose_taken(
      xpath.select1("/resources/string[@name='text_dose_taken']", doc)
        .firstChild.data
    );
    setText_dose_left(
      xpath.select1("/resources/string[@name='text_dose_left']", doc).firstChild
        .data
    );
    setText_dose_override(
      xpath.select1("/resources/string[@name='text_dose_override']", doc)
        .firstChild.data
    );
    setText_time_taken(
      xpath.select1("/resources/string[@name='text_time_taken']", doc)
        .firstChild.data
    );
    setText_schedule_time(
      xpath.select1("/resources/string[@name='text_schedule_time']", doc)
        .firstChild.data
    );
    setText_override_time(
      xpath.select1("/resources/string[@name='text_override_time']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="p-4">
        <div>
          <h4 className="text-center">
            {sessionStorage.getItem("senderName") !== null
              ? sessionStorage.getItem("senderName")
              : ""}{" "}
            {text_portal}
          </h4>
          <h5 className="text-center">
            {" "}
            <b>
              {deviceName === null || deviceName === "" ? "Device" : deviceName}
            </b>{" "}
            Data
          </h5>
          {/* <center> */}
            <div
              className="d-flex mt-4 sharedatedashboard flex-wrap justify-content-center align-self-center"
              // style={{ width: "32%" }}
            >
              <div>
              <DatePicker
                placeholderText={text_select_startdate}
                className="mob-select-start-date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd MMM, yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <DatePicker
                selected={endDate}
                className="mob-select-start-date enddate-shareview"
                dateFormat="dd MMM, yyyy"
                placeholderText={text_select_enddate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <button className="roundedBtn mx-2 enddate-shareview" onClick={getShareDeviceData}>
                {text_btn_search}
              </button>
              </div>
            </div>
          {/* </center> */}
          {finalData === null ? (
            
            <>
            
              <h4 className="mt-5 searchText text-center">{NoData}</h4>
            </>
          ) : (
            <>
             {IsLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader/>
          </div>
        ) : (
          <>
              <div className="p-4 mt-4">
                
                <DataTable
                  columns={columns}
                  pagination
                  data={filterShareData}
                  fixedHeader
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={customTableStyles}
                />
              </div>
            </>
          )}
          </>
          )}
        </div>
      </div>
      <ToastContainer />
      </>
  );
}

export default ShareDataDashboard;
