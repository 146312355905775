import React, { useState } from 'react';
import "./ErrorPage.css";
import NotFoundImg from "../../assets/img/ErrorImg.png"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from "react-router-dom";

const ErrorPage = () => {
const[errorpage,serTexterropage] = useState("The page you are looking for might have been removed, had its namechanged, or is temporarily unavailable.");

  const navigate = useNavigate();

  const goToHomeHandler = () => {}

  return (
    <div className="notfound-main">
      <div className="notfound-img-div">
        <img src={NotFoundImg} alt="" />
      </div>
      <div className="notfound-text-div">
        <p>
        <>{errorpage}</>
        </p>
      </div>
      <div className="notfound-btn-div">
        <button className="notfound-btn-1" onClick={()=>navigate(-1)}><span><KeyboardBackspaceIcon/></span><span>Go Back</span></button>
        <button className="notfound-btn-2" onClick={goToHomeHandler}><span><HomeIcon/></span><span>Go Home</span></button>
      </div>
    </div>
  )
}

export default ErrorPage