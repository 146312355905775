import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";
import { errorToast } from "../utils/ToasterUtils";

const MyMedicineSlice = createSlice({
  name: "MyMedicine",
  initialState: [],
  reducers: {
    setPriorMedicineData(state, action) {
      return (state = action.payload);
    },
    setMyMedicineLogout(state, action) {
      return (state = action.payload);
    },
  },
});
const { setPriorMedicineData, setMyMedicineLogout } = MyMedicineSlice.actions;
export default MyMedicineSlice.reducer;

export function getPriorMedicine() {
  return async function getPriorMedicineThunk(dispatch, getState) {
    try {
      var UserID = sessionStorage.getItem("user_id");
      var MedicineID = sessionStorage.getItem("medicineID");
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/medicine/priormedicine/selectall?_userId=${UserID}&currentMedicineId=${MedicineID}`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            const medicineID = sessionStorage.getItem("medicineID");
            const data = response.data.data;
            var FinalData = data;
           
            console.log("FinalData slice***", FinalData)
            // console.log("FilterMedicine slice***", FilterMedicine)
            dispatch(setPriorMedicineData(FinalData));
          }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
                window.location.replace("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}

export function MyMedicineFunc() {
  return async function MyMedicineFuncThunk(dispatch, getState) {
    try {
      dispatch(setMyMedicineLogout([]));
    } catch (err) {
      console.log(err);
    }
  };
}
