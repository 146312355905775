import React,{useState, useEffect, infoToast} from "react";
import "../ContactUs/ContactUs.css";
import { GlobalConstants } from "../../utils/GlobalConstants";
import Header from "../Header/Header";
import axios from "axios";
import { EMAIL_REGEX } from "../../utils/Regex";
import Cookie from "js-cookie";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function ContactUs() {
  const navigate = useNavigate();

  //language variable start
  const [text_contact_us, setText_contact_us] = useState("Contact Us");
  const [text_first_name, setText_first_name] = useState("First Name");
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_valid_email, setText_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_last_name, setText_last_name] = useState("Last Name");
  const [text_email, setText_email] = useState("Email");
  const [text_submit, setText_submit] = useState("Submit");
  const[text_Your_message,setText_your_message] = useState("Your message...");
  const [text_err_firstname, setText_err_firstname] = useState(
    "Please Enter First Name"
  );
  const [errEmail, setErrEmail] = useState("");
  const[ErrFirstName, setErrFirstName] = useState("");
  const [saveDisable, setSaveDisable] = useState(false);
    //language variable end

    const [firstName,setFirstName] = useState("");
    const [lastname,setLastName] = useState("");
    const [email,setEmail] = useState("");
    const [sentMessage,setSentMessage] = useState("");
    const ContactUsValidation = () =>{
      let ContactuspageValidation = true;
      if (firstName === "") {
        ContactuspageValidation = false;
        setErrFirstName(<>*{text_err_firstname}!</>);
      }
      if (!EMAIL_REGEX.test(email) === true) {
        ContactuspageValidation = false;
      if (email === "" ) {
        ContactuspageValidation = false;
        setErrEmail(<>*{text_err_email}!</>);
      }else {
        setErrEmail(<>*{text_valid_email}!</>);
      }
    }
      setSaveDisable(false);
      return ContactuspageValidation;
    };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_first_name(
      xpath.select1("/resources/string[@name='text_first_name']", doc).firstChild
        .data
    );
    setText_last_name(
      xpath.select1("/resources/string[@name='text_last_name']", doc).firstChild
        .data
    );
    setText_email(
      xpath.select1("/resources/string[@name='text_email']", doc).firstChild
        .data
    );
    setText_contact_us(
      xpath.select1("/resources/string[@name='text_contact_us']", doc).firstChild
        .data
    );
    setText_submit(
      xpath.select1("/resources/string[@name='text_submit']", doc).firstChild
        .data
    );
    setText_your_message(
      xpath.select1("/resources/string[@name='text_Your_message']", doc).firstChild
        .data
    );
    setText_err_email(
      xpath.select1("/resources/string[@name='text_err_email']", doc).firstChild
        .data
    );
    setText_valid_email(
      xpath.select1("/resources/string[@name='text_valid_email']", doc).firstChild
        .data
    );
    setText_err_firstname(
      xpath.select1("/resources/string[@name='text_err_firstname']", doc).firstChild
        .data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleContactUs=()=>{
    if (ContactUsValidation()) {
      setSaveDisable(true);
    var apiUrl =
    GlobalConstants.Cdomain + "/api/breatheasy/v1/contact/contactus";
  const headerConfig = {
    headers: {
      accept: "application/json",
      authorization: "NA",
    },
  };

  const formData = new FormData();
  formData.append("firstname", firstName);
  formData.append("lastname", lastname);
  formData.append("email", email);
  formData.append("msg", sentMessage);
  axios
  .post(apiUrl, formData, headerConfig)
  .then(function (response) {
    var res = response.data;
    console.log(res);
    if(response.status===200){
      setFirstName("");
      setLastName("");
      setEmail("");
      setSentMessage("");
      successToast(res.message);
    }
  })
  .catch(function (err) {
    setSaveDisable(false);
    errorToast(err.response.data.message);
    if (err.response.status === 427) {
      sessionStorage.clear();
      localStorage.clear();
      Cookie.remove("token");
      Cookie.remove("user_email");
      Cookie.remove("firstname");
      Cookie.remove("lastname");
      Cookie.remove("user_id");
      Cookie.remove("token_start_time");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  });
};
  }
  return (
    <>
      <Header />
      <div className="container contactbox">
        <div className="row ">
          <div className="col-md-6 offset-md-3">
            <form className="awesome-form">
              <h2 className="text-center">{text_contact_us}</h2>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder={text_first_name}
                  onChange={(e)=>[setFirstName(e.target.value),setErrFirstName("")]}
                  value={firstName}
                />
                 <span className="ErrMessage">{ErrFirstName}</span>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder={text_last_name}
                  onChange={(e)=>setLastName(e.target.value)}
                  value={lastname}
                />
                
              </div>
              <br />
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder={text_email}
                  onChange={(e)=>[setEmail(e.target.value),setErrEmail("")]}
                  value={email}
                />
                 <span className="ErrMessage">{errEmail}</span>
              </div>
              {/* <br />
              <div className="form-group">
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                />
              </div> */}
              <br />
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="message"
                  rows="3"
                  placeholder={text_Your_message}
                  onChange={(e)=>setSentMessage(e.target.value)}
                  value={sentMessage}
                ></textarea>
              </div>
              <div className="d-flex justify-content-center flex-wrap gap-3 mt-5">
                {/* <button type="button" className="cancelbtn">
                  Cancel
                </button> */}
                <button type="button" className="savebtn" onClick={handleContactUs} disabled={saveDisable}>
                  <>{text_submit}</>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}

export default ContactUs;
