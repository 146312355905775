import React, { useState } from "react";
import "../AddMedicine/AddMedicine.css";
import { BiPlusMedical } from "react-icons/bi";
import { ImMinus } from "react-icons/im";
import Header from "../Header/Header";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

function AddMedicine() {
  const navigate = useNavigate();

  const [increaseDose, setIncreaseDose] = useState(0);
  const [SelectDay1, setSelectDay1] = useState(false);
  const [SelectDay2, setSelectDay2] = useState(false);
  const [SelectDay3, setSelectDay3] = useState(false);
  const [SelectDay4, setSelectDay4] = useState(false);
  const [SelectDay5, setSelectDay5] = useState(false);
  const [SelectDay6, setSelectDay6] = useState(false);
  const [SelectDay7, setSelectDay7] = useState(false);
  const [SelectTime, setSelectTime] = useState(new Date());
  const [selectedBrand, setSelectedBrand] = useState("");
  const [AddDoses, setAddDoses] = useState(1);

  const handleIncrement = () => {
    setIncreaseDose(increaseDose + 1);
  };
  const handleDecrement = () => {
    if (increaseDose >= 1) {
      setIncreaseDose(increaseDose - 1);
    }
  };

  const handleIncrementDose = () => {
    setAddDoses(AddDoses + 1);
  };
  const handleClick = () => {
    setSelectDay1((current) => !current);
  };
  const handleClick1 = () => {
    setSelectDay2((current) => !current);
  };
  const handleClick2 = () => {
    setSelectDay3((current) => !current);
  };
  const handleClick3 = () => {
    setSelectDay4((current) => !current);
  };
  const handleClick4 = () => {
    setSelectDay5((current) => !current);
  };
  const handleClick5 = () => {
    setSelectDay6((current) => !current);
  };
  const handleClick6 = () => {
    setSelectDay7((current) => !current);
  };

  const handleAlldaySelect = () => {
    setSelectDay1((current) => !current);
    setSelectDay2((current) => !current);
    setSelectDay3((current) => !current);
    setSelectDay4((current) => !current);
    setSelectDay5((current) => !current);
    setSelectDay6((current) => !current);
    setSelectDay7((current) => !current);
  };

  const dummyMedicine1 = [
    { name: "Tiotropium Bromide Inhaler", strength: "9 mcg / dose" },
    { name: "Ipratropium Bromide Inhaler", strength: "20 mcg / dose" },
  ];
  const dummyMedicine2 = [
    { name: "Salbutamol Inhaler (Alcoholic)", strength: "100 mcg / dose" },
    { name: "Salbutamol Inhaler (Non-alcoholic)", strength: "100 mcg / dose" },
    { name: "Levosalbutamol Inhaler", strength: "50 mcg / dose" },
  ];
  const dummyMedicine3 = [
    { name: "Budesonide Inhaler", strength: "100 mcg / dose" },
    { name: "Budesonide Inhaler", strength: "200 mcg / dose" },
    { name: "Fluticasone Propionate Inhaler", strength: "50 mcg / dose" },
    { name: "Fluticasone Propionate Inhaler", strength: "125 mcg / dose" },
  ];
  return (
    <>
      <Header />
      <div className="p-5 container ">
        <div className="addmedicineBox p-3">
          <h3 className="text-center">
            <u>Add New Medicine</u>
          </h3>
          <div className="box-size">
            <div className="row ">
              <div className="col-md-4 mt-5">
                <h5>Brand Name</h5>
                <select
                  className="medicine-select"
                  onChange={(e) => setSelectedBrand(e.target.value)}
                  value={selectedBrand}
                >
                  <option disabled selected value="">
                    Select Brand
                  </option>
                  <option value="Bronchodilators34">
                    Bronchodilators(Anti-cholinergic)
                  </option>
                  <option value="AntiInflammatory">
                    Anti inflammatory Agents Corticosteroids
                  </option>
                  <option value="Bronchodilators">
                    Bronchodilators (ß-Agonist)
                  </option>
                </select>
              </div>

              <div className="col-md-4 mt-5">
                <h5>Medicine Name</h5>
                <select className="medicine-select">
                  <option disabled selected value="">
                    Select Medicine
                  </option>
                  {selectedBrand === "Bronchodilators" ? (
                    <>
                      {dummyMedicine2.map((data) => {
                        return <option value="">{data.name}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  {selectedBrand === "AntiInflammatory" ? (
                    <>
                      {dummyMedicine3.map((data) => {
                        return <option value="">{data.name}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  {selectedBrand === "Bronchodilators34" ? (
                    <>
                      {dummyMedicine1.map((data) => {
                        return <option value="">{data.name}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </div>

              <div className="col-md-4 mt-5">
                <h5>Strength</h5>
                <select className="medicine-select">
                  <option disabled selected value="">
                    Select Strength
                  </option>
                  {selectedBrand === "Bronchodilators" ? (
                    <>
                      {dummyMedicine2.map((data) => {
                        return <option value="">{data.strength}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  {selectedBrand === "AntiInflammatory" ? (
                    <>
                      {dummyMedicine3.map((data) => {
                        return <option value="">{data.strength}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  {selectedBrand === "Bronchodilators34" ? (
                    <>
                      {dummyMedicine1.map((data) => {
                        return <option value="">{data.strength}</option>;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </div>
            </div>
            <div className="row">
              <h5 className="mt-5">Dose Per can</h5>
              <div className="col-md-6">
                <div className="d-flex">
                  <button onClick={handleDecrement} className="incres_decres">
                    <ImMinus />
                  </button>
                  <h5 className="dosecount">{increaseDose}</h5>
                  <button onClick={handleIncrement} className="incres_decres">
                    <BiPlusMedical />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 reminder">
                  <div className="d-flex">
                    <h5>In App Reminder&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
                    <div className="toggle mx-3 mt-1">
                      <input type="checkbox" />
                      <label></label>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <h5>On Sensor Reminder</h5>
                    <div className="toggle mx-3 mt-1">
                      <input type="checkbox" />
                      <label></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-4 mt-5 flex-wrap">
              <button
                className="week_btn"
                onClick={handleClick}
                style={{
                  background: SelectDay1 ? "#66D878" : "",
                }}
              >
                MON
              </button>
              <button
                className="week_btn"
                onClick={handleClick1}
                style={{
                  background: SelectDay2 ? "#66D878" : "",
                }}
              >
                TUE
              </button>
              <button
                className="week_btn"
                onClick={handleClick2}
                style={{
                  background: SelectDay3 ? "#66D878" : "",
                }}
              >
                WED
              </button>
              <button
                className="week_btn"
                onClick={handleClick3}
                style={{
                  background: SelectDay4 ? "#66D878" : "",
                }}
              >
                THU
              </button>
              <button
                className="week_btn"
                onClick={handleClick4}
                style={{
                  background: SelectDay5 ? "#66D878" : "",
                }}
              >
                FRI
              </button>
              <button
                className="week_btn"
                onClick={handleClick5}
                style={{
                  background: SelectDay6 ? "#66D878" : "",
                }}
              >
                SAT
              </button>
              <button
                className="week_btn week-btn-deactive"
                onClick={handleClick6}
                style={{
                  background: SelectDay7 ? "#66D878" : "",
                }}
              >
                SUN
              </button>
            </div>
            <input
              type="checkbox"
              className="mt-4"
              onChange={handleAlldaySelect}
            />{" "}
            &nbsp;&nbsp;
            <span className="fw-light fs-5">Select Every Day</span>
            <div className="doseadd d-flex w-25 mt-5">
              <h5>{AddDoses} dose</h5>
              <button className="AddDose" onClick={handleIncrementDose}>
                + Add Dose
              </button>
            </div>
            <div className="row mt-5 w-75">
              <div className="col-md-6">
                <h5>Dose Time</h5>
                <ReactDatePicker
                  selected={SelectTime}
                  onChange={(date) => setSelectTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
              <div className="col-md-6">
                <h5>Dose Quantity</h5>
                <input type="number" placeholder="Add Dose Quantity" />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-5 b-4 mt-4">
            <button className="cancelbtn" onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button className="savebtn">Save</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMedicine;
