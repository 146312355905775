import React, { useEffect, useState } from "react";
import "../MedicineDashboard/MedicineDashboard.css";
import moment from "moment/moment";
import { ToastContainer } from "react-toastify";
function MedicineDashboard() {
  const [timegone, setTimegone] = useState("");
  const currentdate = new Date();

  useEffect(() => {
    var today = new Date();
    var t = today.getTime();
    var time = moment(t).format("hh:mm:ss A");
    setTimegone(time);
    // today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() +today.();
    // setStartDate(time);
    console.log(time);
    for (let index = 0; index < MedicineData?.length; index++) {
      console.log("infor", time, MedicineData[index]?.time);
      if (time > MedicineData[index]?.time) {
        console.log("less");
        // setTimegone(time);
      } else {
        console.log("truee");
      }
    }
  }, []);
  const MedicineData = [
    {
      id: 1,
      medicine: "Medicine-1",
      bordercolor: "10px solid #FF0000",
      allborder: "1px solid #68b984",
      time: "09:11:52 AM",
    },
    {
      id: 2,
      medicine: "Medicine-2",
      bordercolor: "10px solid #4B89AC",
      time: "06:00:00 PM",
    },
    {
      id: 3,
      medicine: "Medicine-2",
      bordercolor: "10px solid #4B89AC",
      time: "03:29:00 PM",
    },
    {
      id: 4,
      medicine: "Medicine-3",
      bordercolor: "10px solid #4B89AC",
      time: "04:29:00 PM",
    },
  ];

  return (
    <>
      <div className="p-5">
        <h4>{moment(currentdate).format("DD MMM YYYY, dddd")}</h4>
        <div className="   ">
          {MedicineData?.map((data, idx) => (
            <>
              <div
                className={
                  timegone >= data.time
                    ? "row sidebox2 justify-content-between flex-wrap gap-2"
                    : "row sidebox justify-content-between flex-wrap gap-2"
                }
                key={idx}
                // style={{ borderLeft: data.bordercolor }}
              >
                <div className="col-md-8">
                  <h6>{data.time}</h6>
                  <h5 className="presentText">{data.medicine}</h5>
                </div>
                <div className="col-md-3 mt-3">
                  <button
                    className="takenBtn"
                    disabled={timegone >= data.time ? true : false}
                  >
                    Taken
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default MedicineDashboard;
