import React, { useEffect, useState } from "react";
import loginImg from "../../assets/img/Login.png";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import "../Forgotpassword/ForgotPassword.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { GlobalConstants } from "../../utils/GlobalConstants";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { Number_REGEX } from "../../utils/Regex";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ForgotPassword() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Language Variables
  const [text_heading_para_login, setText_heading_para_login] = useState(
    "Please fill your detail to access your account."
  );
  const [text_email_phone, setText_email_phone] = useState(
    "Email or Phone Number"
  );
  const [text_fp_err_emailphone, setText_fp_err_emailphone] = useState(
    "Please Enter Registered Email or Phone Number"
  );
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_send, setBtn_send] = useState("Send");
  const [text_enter_emailorphone, setText_enter_emailorphone] = useState(
    "Enter your Registered Email or Phone number"
  );
  const [text_forgot_pass, setText_forgot_pass] = useState("Forgot Password");
  //variable
  const [EmailorPhone, setEmailorPhone] = useState("");
  const [errEmailorPhone, setErrEmailorPhone] = useState("");
  const [forgotClick, setforgotClick] = useState(false);
  const [openforgot, setOpenforgot] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const [forgoterr, setForgoterr] = useState("");

  const handleChange = (e) => {
    var value = e.target.value;
    if (Number_REGEX.test(value) === true) {
      setEmailorPhone("+" + e.target.value);
    } else {
      setEmailorPhone(e.target.value);
    }
  };
  const NewPasswordHandler = () => {
    if (EmailorPhone !== "") {
      setforgotClick(true);
      var apiUrl =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/user/sendforgotpasswordlink";
      var data = {
        username: EmailorPhone,
      };

      axios
        .post(apiUrl, data)
        .then(function (response) {
          if (response.status === 200) {
            setforgotClick(false);
            // successToast(response.data.message);
            setOpenforgot(true);
            setSuccessMsg(response.data.message);
            //   setTimeout(() => {
            //     navigate("/login");
            //   }, 3000);
            //   setEmailorPhone("");
          }
        })
        .catch(function (error) {
          setforgotClick(false);
          // errorToast(error.response.data.message);
          setForgoterr(error.response.data.message);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        });
    } else {
      setErrEmailorPhone(<>*{text_fp_err_emailphone}!</>);
    }
  };
  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_heading_para_login(
      xpath.select1("/resources/string[@name='text_heading_para_login']", doc)
        .firstChild.data
    );
    setText_email_phone(
      xpath.select1("/resources/string[@name='text_email_phone']", doc)
        .firstChild.data
    );
    setText_fp_err_emailphone(
      xpath.select1("/resources/string[@name='text_fp_err_emailphone']", doc)
        .firstChild.data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_send(
      xpath.select1("/resources/string[@name='btn_send']", doc).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handleCloseforgot = () => {
    setOpenforgot(false);
    navigate("/login");
  };

  return (
    <>
      <div className="row loginMain">
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={openforgot}
            onClose={handleCloseforgot}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <FcFeedback className="Email-icon" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p className="forgotsuccess  mb-4">{successmsg}</p>
              </DialogContentText>
              <Divider />
            </DialogContent>
            <DialogActions>
              <button
                className="Cancelbtnforgot mx-4 w-25"
                onClick={handleCloseforgot}
              >
                Ok
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="col-md-6" id="mobilebg">
          <img className="imgstyle" src={loginImg} alt="" />
        </div>
        <div className="col-md-6">
          <div className="LoginHalfBG">
            <div className="LoginBox forgotBox">
              <center>
                <img src={LogoImg} alt="" className="LoginLogoSty mb-3" />
                <h4 className="help_text">{text_forgot_pass}</h4>
                <p className="HeadlineText">{text_enter_emailorphone}</p>
              </center>
              <div className="LoginForm">
                <div className="form-input-login mt-4">
                  <TextField
                    id="outlined-textarea"
                    label={text_email_phone}
                    placeholder="abc@xyz.com or +1 xxxxxxxxx"
                    className="header-signup-inputs login-input"
                    onChange={(e) => [handleChange(e), setErrEmailorPhone(""),setForgoterr("")]}
                    value={EmailorPhone}
                  />
                  <span className="ErrMessage err_login">
                    {errEmailorPhone}
                  </span>
                  <p className="regErr-p  mb-4 mt-3">{forgoterr}</p>
                  <div className="d-flex flex-wrap justify-content-center gap-4  mt-4">
                    <button
                      className="Cancelbtnforgot w-25"
                      onClick={() => navigate("/login")}
                    >
                      {btn_cancel}
                    </button>
                    <button
                      className="SignInBtn w-25"
                      onClick={NewPasswordHandler}
                    >
                      {btn_send}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ForgotPassword;
