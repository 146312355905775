import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/Login/Login";
import GuardedRoutes from "./AuthGard";
import Register from "./component/Register/Register";
import Dashboard from "./component/Dashboard/Dashboard";
import Weather from "./component/Weather/Weather";
import Report from "./component/Report/Report";
import AddMedicine from "./component/AddMedicine/AddMedicine";
import RegisterDeviceMedicine from "./component/RegisterDeviceMedicine/RegisterDeviceMedicine";
import MedicineDashboard from "./component/MedicineDashboard/MedicineDashboard";
import ForgotPassword from "./component/Forgotpassword/ForgotPassword";
import ScheduleMedicine from "./component/Medicine Schedule/ScheduleMedicine";
import Helppage from "./component/Helppage/Helppage";
import ErrorPage from "./component/ErrorPage/ErrorPage";
import MyMedicine from "./component/Mymedicine/MyMedicine";
import ShareDataDashboard from "./component/Dashboard/ShareDataDashboard";
import HomePage from "./component/HomePage/HomePage";
import Myhistory from "./component/MyHistory/Myhistory";
import ContactUs from "./component/ContactUs/ContactUs";
import VerifyPage from "./component/VerifyPage/VerifyPage";
import TermsofServices from "./component/TermsandPrivacyPolicy/TermsofServices";
import PrivacyPolicy from "./component/TermsandPrivacyPolicy/PrivacyPolicy";
import ConsumerPrivacy from "./component/TermsandPrivacyPolicy/ConsumerPrivacy";
import PriorMedicineHistory from "./component/Mymedicine/PriorMedicineHistory";
// ----- write login useeffect on every page -----
// ErrorPage.jsx --> Home button
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="support" element={<Helppage />} />
        <Route path="termsofservice" element={<TermsofServices />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="consumerprivacy" element={<ConsumerPrivacy />} />
        <Route path="verify" element={<VerifyPage />} />
        <Route
          path="dashboard"
          element={<GuardedRoutes Component={Dashboard} />}
        />
        <Route
          path="current/weather"
          element={<GuardedRoutes Component={Weather} />}
        />
        <Route path="report" element={<GuardedRoutes Component={Report} />} />
        <Route
          path="contactus"
          element={<GuardedRoutes Component={ContactUs} />}
        />
        <Route
          path="addmedicine"
          element={<GuardedRoutes Component={AddMedicine} />}
        />
        <Route
          path="register/device"
          element={<GuardedRoutes Component={RegisterDeviceMedicine} />}
        />{" "}
        <Route
          path="medicinedashboard"
          element={<GuardedRoutes Component={MedicineDashboard} />}
        />
        <Route
          path="schedule-medicine"
          element={<GuardedRoutes Component={ScheduleMedicine} />}
        />
        <Route
          path="mymedicine"
          element={<GuardedRoutes Component={MyMedicine} />}
        />
        <Route
          path="sharedata/dashboard"
          element={<GuardedRoutes Component={ShareDataDashboard} />}
        />
        <Route
          path="mydeviceMedicine/history"
          element={<GuardedRoutes Component={Myhistory} />}
        />
        <Route
          path="priormedicine/history"
          element={<GuardedRoutes Component={PriorMedicineHistory} />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
