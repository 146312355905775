import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GlobalConstants } from "../utils/GlobalConstants";
import Cookie from "js-cookie";
import { errorToast } from "../utils/ToasterUtils";

const RefillMedicineSlice = createSlice({
  name: "RefillMedicine",
  initialState: [],
  reducers: {
    setRefillMedicineData(state, action) {
      return (state = action.payload);
    },
    setrefillMedicineLogout(state, action) {
      return (state = action.payload);
    },
  },
});
// const navigate = useNavigate();

const { setRefillMedicineData, setrefillMedicineLogout } =
  RefillMedicineSlice.actions;
export default RefillMedicineSlice.reducer;

export function getrefillData() {
  return async function getrefillDataThunk(dispatch, getState) {
    try {
      var UserID = sessionStorage.getItem("user_id");
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/medicine/medicinerefillhistory/selectall?_userId=${UserID}`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data.data;
            console.log(data);
            dispatch(setRefillMedicineData(data));
          }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
                window.location.replace("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
            errorToast(err.response.data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}
export function RefillMedicineFunc() {
  return async function RefillMedicineFuncThunk(dispatch, getState) {
    try {
      dispatch(setrefillMedicineLogout([]));
    } catch (err) {
      console.log(err);
    }
  };
}
