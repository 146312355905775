import Header from "../Header/Header";
import "./Dashboard.css";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { AppBar, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import Mydevice from "./Mydevice";
import SharedTo from "./SharedTo";
import SharedWithme from "./SharedWithme";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { AiOutlineSwap } from "react-icons/ai";
import { CgCloseO } from "react-icons/cg";
import Cookie from "js-cookie";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAllMedicineInPopup } from "../../redux/DashboardSlice";
import { getAllDevicesInPopup } from "../../redux/DeviceListSlice";
import { useCallback } from "react";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Dashboard() {
  const userId = sessionStorage.getItem("user_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboardpairdevice); //get device,medicine popup
  const devicepopup = useSelector((state) => state.deviceList);
  const DashboardTabData = useLocation();
  const dataValue =
    DashboardTabData.state !== null ? DashboardTabData.state.TabValue : 0;
  const [selectedTab, setSelectedTab] = useState(dataValue);

  const [CreateNewPairPopup, setCraetenewPairPopup] = useState(false);
  const PairedDevicePopup = useCallback((res) => setCraetenewPairPopup(res));
  const [selectDisable, setSelectDisable] = useState("");
  const RefillmedicineID = useCallback((res) => [
    setMedicineData(res),
    setSelectDisable(res),
  ]);
  const [MedicineName, setMedicineName] = useState("");
  const [DeviceName, setDeviceName] = useState("");
  const [DeviceData, setDeviceData] = useState("");
  const [MedicineData, setMedicineData] = useState("");
  const [medicineID, setMedicineID] = useState("");
  const [deviceID, setDeviceID] = useState("");
  const [errDevice, setErrDevice] = useState("");
  const [errMedicine, setErrMedicine] = useState("");
  const [alreadyPaired, setAlreadyPaired] = useState("");

  // Language Variables
  const [text_hello, setText_hello] = useState("Hello");
  const [text_wc_braetheasy, setText_wc_braetheasy] = useState(
    "Welcome to BreathEasy"
  );
  const [text_current_device, setText_current_device] =
    useState("My Current Device");
  const [text_current_medicine, setText_current_medicine] = useState(
    "My Current Medicine"
  );
  const [text_current_pair, setText_current_pair] = useState("My Current Pair");
  const [text_create_pair, setText_create_pair] = useState("Create New Pair");
  const [text_my_device, setText_my_device] = useState("My Device");
  const [text_share_to, setText_share_to] = useState("Data Share To");
  const [text_share_with, setText_share_with] = useState("Data Share With Me");
  const [text_select_device, setText_select_device] = useState("Select Device");
  const [text_my_medicine, setText_my_medicine] = useState("My Medicine");
  const [text_select_medicine, setText_select_medicine] =
    useState("Select Medicine");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_create_pair, setBtn_create_pair] = useState("Create Pair");
  const [
    text_warn_select_medicine_device,
    setText_warn_select_medicine_device,
  ] = useState("Please Select Device & Medicine");
  const [text_no_medicine_added, setText_no_medicine_added] =
    useState("No Medicine Added");
  const [text_no_device_added, setText_no_device_added] =
    useState("No Device Added");
  const [text_err_select_device, setText_err_select_device] = useState(
    "Please Select Device"
  );
  const [text_err_please_select_medicine, setText_err_please_select_medicine] =
    useState("Please Select Medicine");
  const [text_create_or_change_pair, setText_create_or_change_pair] = useState(
    "Create Or Change Pairing"
  );
  const handleChange = (event, newValue) => {
    if (dataValue !== null) {
      setSelectedTab(newValue);
    } else {
      setSelectedTab(dataValue);
    }
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("DashboardPopupPair"));
  }, sessionStorage);
  const filterDevice = devicepopup.filter((e) => e.Havedevice === true);
  const filterMedicine = dashboard.filter((e) => e.AddmedicineLater === false);

  //validation
  const PairingValidation = () => {
    let PairValid = true;
    if (DeviceData === "" || DeviceData === "NA") {
      PairValid = false;
      setErrDevice(<>*{text_err_select_device}!</>);
    }
    if (MedicineData === "" || MedicineData === "NA") {
      PairValid = false;
      setErrMedicine(<>*{text_err_please_select_medicine}!</>);
    }
    return PairValid;
  };

  const handleCreatePair = () => {
    if (PairingValidation()) {
      var api_url =
        GlobalConstants.Cdomain + "/api/breatheasy/v1/device/pairdevice";
      var PairingData = {
        _deviceId: DeviceData,
        _medicineId: MedicineData,
      };

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .post(api_url, PairingData, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            var res = response.data;
            if (res.message === "Device-Medicine Relation Already Added") {
              setAlreadyPaired(res.message);
            } else {
              successToast(response.data.message);
              setCraetenewPairPopup(false);
              setAlreadyPaired("");
              GetPairDevice();
              setDeviceData("");
              setMedicineData("");
              setErrDevice("");
              setErrMedicine("");
              setSelectDisable("");
              if (
                sessionStorage.getItem("selectedDeviceID") !== "" ||
                sessionStorage.getItem("selectedDeviceID") !== null ||
                sessionStorage.getItem("selectedDeviceID") !== undefined
              ) {
                sessionStorage.removeItem("selectedDeviceID");
              }
            }
          }
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            navigate("/");
          } else {
            console.log("all devices err***", err);
          }
        });
    }
  };

  const GetPairDevice = () => {
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/pairdevice?_userId=" +
      userId;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data.data;
          if (data.paireddevice.length !== 0) {
            var DevicePair = data.paireddevice[0];
            var PairDeviceName = DevicePair.Devicecustomname;

            setDeviceName(PairDeviceName);
            setDeviceID(DevicePair._id);
            var pairMedicineToDevice = DevicePair.paired_medicineId;
            var MedicinePair = data.pairedmedicine;
            var activeMedicine = MedicinePair.filter(
              (e) => e._id === pairMedicineToDevice
            );
            if (activeMedicine.length !== 0) {
              setMedicineName(activeMedicine[0].Genericname);
              setMedicineID(activeMedicine[0]._id);
              sessionStorage.setItem("medicineID", activeMedicine[0]._id);
            }
            sessionStorage.setItem("deviceID", DevicePair._id);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };

  const closePopup = () => {
    setCraetenewPairPopup(false);
    setDeviceData("");
    setMedicineData("");
    setErrDevice("");
    setErrMedicine("");
    setAlreadyPaired("");
  };
  useEffect(() => {
    GetPairDevice();
  }, [DeviceName, MedicineName]);

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_hello(
      xpath.select1("/resources/string[@name='text_hello']", doc).firstChild
        .data
    );
    setText_current_device(
      xpath.select1("/resources/string[@name='text_current_device']", doc)
        .firstChild.data
    );
    setText_wc_braetheasy(
      xpath.select1("/resources/string[@name='text_wc_braetheasy']", doc)
        .firstChild.data
    );
    setText_current_medicine(
      xpath.select1("/resources/string[@name='text_current_medicine']", doc)
        .firstChild.data
    );
    setText_current_pair(
      xpath.select1("/resources/string[@name='text_current_pair']", doc)
        .firstChild.data
    );
    setText_create_pair(
      xpath.select1("/resources/string[@name='text_create_pair']", doc)
        .firstChild.data
    );
    setText_my_device(
      xpath.select1("/resources/string[@name='text_my_device']", doc).firstChild
        .data
    );
    setText_share_to(
      xpath.select1("/resources/string[@name='text_share_to']", doc).firstChild
        .data
    );
    setText_share_with(
      xpath.select1("/resources/string[@name='text_share_with']", doc)
        .firstChild.data
    );
    setText_select_device(
      xpath.select1("/resources/string[@name='text_select_device']", doc)
        .firstChild.data
    );
    setText_my_medicine(
      xpath.select1("/resources/string[@name='text_my_medicine']", doc)
        .firstChild.data
    );
    setText_select_medicine(
      xpath.select1("/resources/string[@name='text_select_medicine']", doc)
        .firstChild.data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_create_pair(
      xpath.select1("/resources/string[@name='btn_create_pair']", doc)
        .firstChild.data
    );
    setText_create_or_change_pair(
      xpath.select1(
        "/resources/string[@name='text_create_or_change_pair']",
        doc
      ).firstChild.data
    );
    setText_warn_select_medicine_device(
      xpath.select1(
        "/resources/string[@name='text_warn_select_medicine_device']",
        doc
      ).firstChild.data
    );
    setText_no_medicine_added(
      xpath.select1("/resources/string[@name='text_no_medicine_added']", doc)
        .firstChild.data
    );
    setText_no_device_added(
      xpath.select1("/resources/string[@name='text_no_device_added']", doc)
        .firstChild.data
    );
    setText_err_select_device(
      xpath.select1("/resources/string[@name='text_err_select_device']", doc)
        .firstChild.data
    );
    setText_err_please_select_medicine(
      xpath.select1(
        "/resources/string[@name='text_err_please_select_medicine']",
        doc
      ).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <main >
        <Header />
        <div className={CreateNewPairPopup ? "px-4 py-2 blurBg" : "px-4 py-2"} >
          <nav className="mt-2 text-center" id="DashboardMainPage">
            <h3>
              {text_hello}👋,
              {sessionStorage.getItem("firstname") === null
                ? "User"
                : sessionStorage.getItem("firstname").charAt(0).toUpperCase() +
                  sessionStorage.getItem("firstname").slice(1) +
                  " " +
                  sessionStorage.getItem("lastname").charAt(0).toUpperCase() +
                  sessionStorage.getItem("lastname").slice(1)}
            </h3>
            <p>{text_wc_braetheasy}</p>
          </nav>
          <div className="pairBoxDashboard container" id="DashboardMainPage3">
            <div className="pairFlexSty">
              <div>
                <h5>{text_current_device}</h5>
                <input
                  className="frequencyInput"
                  type="text"
                  disabled
                  value={
                    deviceID === "" || deviceID === null
                      ? text_no_device_added
                      : DeviceName
                  }
                />
              </div>
              <div>
                <h5>{text_current_medicine}</h5>
                <input
                  className="frequencyInput"
                  type="text"
                  disabled
                  value={
                    MedicineName === null ||
                    MedicineName === "" ||
                    MedicineName === undefined
                      ? text_no_medicine_added
                      : MedicineName
                  }
                />
              </div>
              <div className="updown">
                <AiOutlineSwap className="pairarrow" />
              </div>
              <div className="pairBtnBox mt-4">
                <button className="roundedBtn">{text_current_pair}</button>
              </div>
            </div>

            <button
              className="createpair mt-3"
              onClick={() => [
                setCraetenewPairPopup(!CreateNewPairPopup),
                dispatch(getAllDevicesInPopup()),
                dispatch(getAllMedicineInPopup()),
              ]}
            >
              <u>{text_create_or_change_pair}</u>
            </button>
          </div>
          <AppBar
            position="static"
            className="MainAppBar mt-2"
            id="DashboardMainPage2"
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
            >
              <Tab label={text_my_device} className="AppBarText" />
              <Tab
                label={text_share_to}
                iconPosition="end"
                icon={<FaArrowUp className="icon_uparrow" />}
                className="AppBarText"
              />
              <Tab
                label={text_share_with}
                iconPosition="end"
                icon={<FaArrowDown className="icon_uparrow" />}
                className="AppBarText"
              />
            </Tabs>
          </AppBar>
          {selectedTab === 0 && (
            <Mydevice
              devicedata={DeviceName}
              Medicinedata={MedicineName}
              DeviceID={deviceID}
              medicineID={medicineID}
              RefillPopup={PairedDevicePopup}
              RefillmedicineId={RefillmedicineID}
            />
          )}
          {selectedTab === 1 && <SharedTo />}
          {selectedTab === 2 && <SharedWithme />}
        </div>
        {CreateNewPairPopup ? (
          <>
            <div className="Popup">
              <div className="text-end">
                <CgCloseO size={25} onClick={closePopup} />
              </div>
              <h4 className="text-center">{text_create_or_change_pair}</h4>
              <Divider />
              <div className="d-flex justify-content-between gap-4 flex-wrap mt-4 p-4">
                <div>
                  <h5>{text_my_device}</h5>
                  <select
                    type="text"
                    onChange={(e) => [
                      setDeviceData(e.target.value),
                      setErrDevice(""),
                    ]}
                    value={DeviceData}
                  >
                    <option selected value="NA">
                      {text_select_device}
                    </option>
                    {filterDevice?.map((v, i) => {
                      return (
                        <option value={v?._id}>{v?.Devicecustomname}</option>
                      );
                    })}
                  </select>
                  <p className="ErrMessage">{errDevice}</p>
                </div>
                <div>
                  <h5>{text_my_medicine}</h5>
                  <select
                    type="text"
                    onChange={(e) => [
                      setMedicineData(e.target.value),
                      setErrMedicine(""),
                    ]}
                    value={MedicineData}
                    disabled={
                      selectDisable === "" ||
                      selectDisable === null ||
                      selectDisable === undefined
                        ? false
                        : true
                    }
                  >
                    <option selected value="NA">
                      {text_select_medicine}
                    </option>
                    {filterMedicine?.map((v, i) => {
                      return <option value={v?._id}>{v?.Genericname}</option>;
                    })}
                  </select>
                  <p className="ErrMessage">{errMedicine}</p>
                </div>
              </div>
              <p className="text-danger text-center mb-3 alreadyPaired">
                {alreadyPaired}
              </p>
              <div className="pairBtnBox mt-4 d-flex justify-content-center gap-3">
                <button className="cancelbtn" onClick={closePopup}>
                  {btn_cancel}
                </button>
                <button
                  className="roundedBtn popupPairBtn"
                  onClick={handleCreatePair}
                >
                  {btn_create_pair}
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </main>
      <ToastContainer />
    </>
  );
}

export default Dashboard;
