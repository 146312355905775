import React, { useEffect, useState } from "react";
import "./Report.css";
import Header from "../Header/Header";
import Progressbar from "react-js-progressbar";
import { AiOutlineDownload } from "react-icons/ai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Loader from "../../utils/Loader";
import Cookie from "js-cookie";
import { Bar } from "react-chartjs-2";
import { GlobalConstants } from "../../utils/GlobalConstants";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { errorToast } from "../../utils/ToasterUtils";
import { ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function Report() {
  const navigate = useNavigate();
  const DeviceID = sessionStorage.getItem("deviceID");
  //language Variable
  const [text_inhaler_dose, setText_inhaler_dose] = useState("Inhaler Dose");
  const [text_cuurent_week, setText_cuurent_week] = useState("Current Week");
  const [text_last_week, setText_last_week] = useState("Last Week");
  const [text_last_month, setText_last_month] = useState("Last Month");
  const [text_download_report, setText_download_report] =
    useState("Download Reports");
  const [text_year, setText_year] = useState("Year");
  const [text_monthly_dose_usage, setText_monthly_dose_usage] =
    useState("Monthly Dose Usage");
  const [text_dose_usage, setText_dose_usage] = useState("Doses Usage");
  const [text_total_dose_count,setText_total_dose_count] = useState("Total Dose Taken");
  const [text_close, setText_close] = useState("Close");

  const [isLoading, setIsLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setPopupOpen(false);
    navigate("/dashboard");
  };
  //variable
  const [reportFinalData, setReportFinalData] = useState("");
  const getReportData = () => {
    setIsLoading(true);
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/user/reports?Device_ID=${DeviceID}`;
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        console.log(res.data.data);
        var value = res.data.data;
        setReportFinalData(value);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
        if (err.response.status === 400) {
          setIsLoading(false);
          setPopupOpen(true);
          setResponseMessage(err.response.data.message);
        } else {
          if (err.response.status === 427) {
            // setIsLoading(false);
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.", err.response.data.message);
            errorToast(err.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    getReportData();
  }, []);
  const INHALER_DATA = [
    {
      title: <>{text_total_dose_count}</>,
      value: sessionStorage.getItem("totalTakenDose"),
      outof: sessionStorage.getItem("totalDose"),
      backgroundColor: "#FFEAD2",
    },
    // {
    //   title: <>{text_last_week}</>,
    //   value: reportFinalData.lastweekdosecount,
    //   backgroundColor: "#FEFF86",
    // },
    // {
    //   title: <>{text_last_month}</>,
    //   value: reportFinalData.lastmonthdosecount,
    //   backgroundColor: "#FF6969",
    // },
  ];
  const options = {
   
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: text_monthly_dose_usage,
      },
    },
    
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: text_dose_usage,
        data: [
          reportFinalData.Januarycount,
          reportFinalData.Februarycount,
          reportFinalData.Marchcount,
          reportFinalData.Aprilcount,
          reportFinalData.Maycount,
          reportFinalData.Junecount,
          reportFinalData.Julycount,
          reportFinalData.Augustcount,
          reportFinalData.Septembercount,
          reportFinalData.Octobercount,
          reportFinalData.Novembercount,
          reportFinalData.Decembercount,
        ],
        fill: false,
        backgroundColor: "#AFD3E2",
        tension: 0,
      },
    ],
   
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_inhaler_dose(
      xpath.select1("/resources/string[@name='text_inhaler_dose']", doc)
        .firstChild.data
    );
    setText_cuurent_week(
      xpath.select1("/resources/string[@name='text_cuurent_week']", doc)
        .firstChild.data
    );
    setText_last_week(
      xpath.select1("/resources/string[@name='text_last_week']", doc).firstChild
        .data
    );
    setText_last_month(
      xpath.select1("/resources/string[@name='text_last_month']", doc)
        .firstChild.data
    );
    setText_download_report(
      xpath.select1("/resources/string[@name='text_download_report']", doc)
        .firstChild.data
    );
    setText_year(
      xpath.select1("/resources/string[@name='text_year']", doc).firstChild.data
    );
    setText_monthly_dose_usage(
      xpath.select1("/resources/string[@name='text_monthly_dose_usage']", doc)
        .firstChild.data
    );
    setText_dose_usage(
      xpath.select1("/resources/string[@name='text_dose_usage']", doc)
        .firstChild.data
    );
    setText_total_dose_count(
      xpath.select1("/resources/string[@name='text_total_dose_count']", doc)
        .firstChild.data
    );
    setText_close(
      xpath.select1("/resources/string[@name='text_close']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <Header />
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={popupOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" className="text-center">
            {/* <FcFeedback className="Email-icon" /> */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <h3 className="text-center">{responseMessage}</h3>
              {/* <Divider /> */}
              {/* <h5 className="text-center mt-4">Please pair your device first !</h5> */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="cancelbtn mx-4" onClick={handleClose}>
              {text_close}
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <main className="px-4 report-main">
        <h4 className="mt-3 text-center">
          <b>{text_inhaler_dose}</b>
        </h4>
        <hr />
        {isLoading === true ? (
          <div className="dashboard-table-loader">
            <Loader />
          </div>
        ) : (
          <>
            {popupOpen === false ? (
              <>
                {" "}
                <div className="card-main">
                  {INHALER_DATA.map((value, index) => (
                    <>
                      <div
                        className="report-card-box text-center p-4"
                        key={index}
                      >
                        <h4 className="mb-3">{value.title}</h4>
                        <Progressbar
                          input={(value.value/value.outof)*100}
                          pathWidth={10}
                          // cx={10}
                          pathColor={
                           (value.value /value?.outof) * 100 < 70
                              ? "red"
                              : (value.value /value?.outof) * 100  > 30
                              ? "orange"
                              : "green"
                          }
                          trailWidth={10}
                          trailColor="#F6F5F5"
                          backgroundColor={"#FFF"}
                          textStyle={{ fill: "black", fontSize: "38px"}}
                          size={130}
                          customText={
                            value?.outof !== 0
                              ? `${value?.value}/${value?.outof}`
                              : `${value?.value}`
                          }
                        />
                      </div>
                    </>
                  ))}
                </div>
                {/* <hr /> */}
                <div>
                  <div className="d-flex justify-content-between mt-5">
                    <h5>
                      {text_year} {reportFinalData.year}
                    </h5>
                    <button className="download-report-btn">
                      <AiOutlineDownload />
                      &nbsp; {text_download_report}
                    </button>
                  </div>
                  <div className="report-chart mt-4 p-2">
                    <Bar options={options} data={data} />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </main>
      <ToastContainer />
    </>
  );
}

export default Report;
