import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { errorToast, successToast } from "../../utils/ToasterUtils";
import axios from "axios";
import { GlobalConstants } from "../../utils/GlobalConstants";
import { useEffect } from "react";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Loader from "../../utils/Loader";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function SharedWithme() {
  //language Variable start
  const [text_search_here, setText_search_here] = useState("Search here");
  const [text_btn_search, setText_btn_search] = useState("Search");
  const [text_pending_request, setText_pending_request] =
    useState("Pending Request");
  const [text_current_data, setText_current_data] = useState("Current Data");
  const [text_old_data, setText_old_data] = useState("Old Data");
  const [text_decline, setText_decline] = useState("Decline");
  const [text_accept, setText_accept] = useState("Accept");
  const [text_view, setText_view] = useState("View");
  const [text_nodatafound, setText_nodatafound] = useState("No Data Found");
  //language Variable end

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(2);

  const [acceptedData, setAcceptedData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [oldData, setOldData] = useState([]);

  const [pendingFilterText, setPendingFilterText] = useState("");
  const [acceptFilterText, setAcceptFilterText] = useState("");
  const [oldDataFilterText, setOldDataFilterText] = useState("");
  const [IsLoading, setIsLoading] = useState(true);

  const getSharependingData = () => {
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/device/sharedatafromlist/selectall`;
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        console.log("all devices res***", res.data.data);
        var value = res.data.data;
        var NotdisableData = value.filter((e) => e.isdisable === false);
        var DisableData = value.filter((e) => e.isdisable === true);
        console.log("DisableData", DisableData);
        console.log("NotdisableData", NotdisableData);
        if (NotdisableData.length !== 0) {
          setIsLoading(false);
          console.log("in if");
          const acceparr = [];
          const penarr = [];
          const rejarr = [];

          for (let i = 0; i < NotdisableData.length; i++) {
            console.log("in for");
            if (NotdisableData[i].requeststatus === "Accepted") {
              console.log("Accepted")
              acceparr.push(NotdisableData[i]);
              setAcceptedData([...acceptedData, acceparr]);
            }
            if (NotdisableData[i].requeststatus === "Rejected") {
              rejarr.push(NotdisableData[i]);
              setOldData([...rejectedData, rejarr]);
            }
            if (NotdisableData[i].requeststatus === "Pending") {
              penarr.push(NotdisableData[i]);
              setPendingData([...pendingData, penarr]);
            }
          }
          setAcceptedData(acceparr);
          setOldData(rejarr);
          setPendingData(penarr);
        } else {
          setIsLoading(false);
        }
        if (DisableData.length !== 0) {
          setIsLoading(false);
          console.log("DisableData", DisableData);
          setOldData(DisableData);
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          setIsLoading(false);
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          setIsLoading(false);
          errorToast(err.response.data.message);
        }
      });
  };
  console.log(oldData);
  const filteredPendingItems = pendingData?.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(pendingFilterText.toLowerCase()) !== -1
  );

  const filteredAcceptItems = acceptedData?.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(acceptFilterText.toLowerCase()) !== -1
  );
  const filteredOldDataItems = oldData?.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(oldDataFilterText.toLowerCase()) !== -1
  );
  // console.log(pendingRequest);
  console.log(
    "acceptAll ",
    acceptedData,
    "rejecteAll",
    oldData,
    "pendingAll",
    pendingData
  );

  useEffect(() => {
    getSharependingData();
  }, []);

  const handleRequestStatus = (status, requestID) => {
    var UserId = sessionStorage.getItem("user_id");
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/sharedatato/disable?type=requeststatus";

    var requestStatus = {
      _id: requestID,
      _userId: UserId,
      requeststatus: status,
    };
    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, requestStatus, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          getSharependingData();
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_search_here(
      xpath.select1("/resources/string[@name='text_search_here']", doc)
        .firstChild.data
    );
    setText_btn_search(
      xpath.select1("/resources/string[@name='text_btn_search']", doc)
        .firstChild.data
    );
    setText_pending_request(
      xpath.select1("/resources/string[@name='text_pending_request']", doc)
        .firstChild.data
    );
    setText_current_data(
      xpath.select1("/resources/string[@name='text_current_data']", doc)
        .firstChild.data
    );
    setText_old_data(
      xpath.select1("/resources/string[@name='text_old_data']", doc).firstChild
        .data
    );
    setText_decline(
      xpath.select1("/resources/string[@name='text_decline']", doc).firstChild
        .data
    );
    setText_accept(
      xpath.select1("/resources/string[@name='text_accept']", doc).firstChild
        .data
    );
    setText_view(
      xpath.select1("/resources/string[@name='text_view']", doc).firstChild.data
    );
    setText_nodatafound(
      xpath.select1("/resources/string[@name='text_nodatafound']", doc)
        .firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <main className="px-3 py-2">
      <div className="shareWithMeSearchBox ">
        {selectedTab === 1 && (
          <input
            placeholder={text_search_here + "..."}
            onChange={(e) => setPendingFilterText(e.target.value)}
            value={pendingFilterText}
          />
        )}
        {selectedTab === 2 && (
          <input
            placeholder={text_search_here + "..."}
            onChange={(e) => setAcceptFilterText(e.target.value)}
            value={acceptFilterText}
          />
        )}
        {selectedTab === 3 && (
          <input
            placeholder={text_search_here + "..."}
            onChange={(e) => setOldDataFilterText(e.target.value)}
            value={oldDataFilterText}
          />
        )}

        <button className="searchBtn">
          <AiOutlineSearch />
          {text_btn_search}
        </button>
      </div>
      {IsLoading === true ? (
        <>
          <div className="sharedwithme-loader">
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="withmebox">
            <div
              className="sideboxsharewith bd-highlight mb-3 "
              id="sharewithmemobile"
              style={{}}
            >
              <div className="">
                <div
                  className={
                    selectedTab === 1
                      ? "p-2 bd-highlight navbgcolor"
                      : "p-2 bd-highlight "
                  }
                  style={{
                    cursor: "grab",
                    borderRight: "1px solid black",
                    width: "187px",
                    fontSize: "20px",
                  }}
                  onClick={() => [
                    setSelectedTab(1),
                    setPendingFilterText(""),
                    setOldDataFilterText(""),
                  ]}
                >
                  {" "}
                  {text_pending_request}
                </div>
              </div>
              <div className="">
                <div
                  className={
                    selectedTab === 2
                      ? "p-2 bd-highlight navbgcolor"
                      : "p-2 bd-highlight "
                  }
                  style={{
                    cursor: "grab",
                    borderRight: "1px solid black",
                    width: "187px",
                    fontSize: "20px",
                  }}
                  onClick={() => [
                    setSelectedTab(2),
                    setAcceptFilterText(""),
                    setOldDataFilterText(""),
                  ]}
                >
                  {" "}
                  {text_current_data}
                </div>
              </div>
              <div className="">
                <div
                  className={
                    selectedTab === 3
                      ? "p-2 bd-highlight navbgcolor"
                      : "p-2 bd-highlight  "
                  }
                  style={{
                    cursor: "grab",
                    borderRight: "1px solid black",
                    width: "187px",
                    fontSize: "20px",
                  }}
                  onClick={() => [
                    setSelectedTab(3),
                    setAcceptFilterText(""),
                    setPendingFilterText(""),
                  ]}
                >
                  {" "}
                  {text_old_data}
                </div>
              </div>
            </div>
            <div className="shareBoxPart">
              {selectedTab === 1 ? (
                <>
                  <div className=" shareWithMeProfileBox flex-wrap  gap-4">
                    {filteredPendingItems.length > 0 &&
                      filteredPendingItems.map((data, idx) => (
                        <>
                          {Date.parse(data?.accesstill_enddate) >
                            Date.parse(new Date()) && (
                            <>
                              <div className="ShareWithBox">
                                <h6 className="mt-3">{data.requestername}</h6>
                                <h6>
                                  <i>{data?.senderemailorphone}</i>
                                </h6>
                                <h6>
                                  {moment(data?.accesstill_startdate).format(
                                    "MMM DD, yyyy"
                                  )}{" "}
                                  -{" "}
                                  {moment(data?.accesstill_enddate).format(
                                    "MMM DD, yyyy"
                                  )}{" "}
                                </h6>
                                <div className="d-flex gap-3 mt-3">
                                  <button
                                    className="declineBtn"
                                    onClick={() =>
                                      handleRequestStatus("Rejected", data._id)
                                    }
                                  >
                                    {text_decline}
                                  </button>
                                  <button
                                    className="acceptBtn"
                                    onClick={() =>
                                      handleRequestStatus("Accepted", data._id)
                                    }
                                  >
                                    {text_accept}
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                  </div>{" "}
                  {filteredPendingItems.length === 0 && (
                    <>
                      {/* <div className="no-data-sty"> */}
                      <h4 className="text-center mt-5 w-75">
                        <>{text_nodatafound}</>
                      </h4>
                      {/* </div> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {selectedTab === 2 ? (
                <>
                  <div className=" shareWithMeProfileBox flex-wrap  gap-4">
                    {filteredAcceptItems.length > 0 &&
                      filteredAcceptItems?.map((data, idx) => (
                        <>
                          {" "}
                          {Date.parse(data?.accesstill_enddate) >
                            Date.parse(new Date()) && (
                            <>
                              <div key={idx} className="ShareWithBox">
                                <h6 className="mt-3">{data.requestername}</h6>
                                <h6>
                                  <i>{data?.senderemailorphone}</i>
                                </h6>
                                <h6>
                                  {moment(data?.accesstill_startdate).format(
                                    "MMM DD, yyyy"
                                  )}{" "}
                                  -{" "}
                                  {moment(data?.accesstill_enddate).format(
                                    "MMM DD, yyyy"
                                  )}{" "}
                                </h6>
                                <Link to={"/sharedata/dashboard"}>
                                  <button
                                    className="viewOtherBtn mt-3"
                                    onClick={() => [
                                      sessionStorage.setItem(
                                        "token-for-access",
                                        data.token_for_access
                                      ),
                                      sessionStorage.setItem(
                                        "senderName",
                                        data.requestername
                                      ),
                                      sessionStorage.setItem(
                                        "senderDevicename",
                                        data.Devicecustomname
                                      ),
                                      sessionStorage.setItem(
                                        "dataperiodStartdate",
                                        data.dataperiod_startdate
                                      ),
                                      sessionStorage.setItem(
                                        "dataperiodEnddate",
                                        data.dataperiod_enddate
                                      ),
                                    ]}
                                  >
                                    {text_view}
                                  </button>
                                </Link>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                  </div>{" "}
                  {filteredAcceptItems.length === 0 && (
                    <>
                      {/* <div className="no-data-sty"> */}
                      <h4 className="text-center mt-5 w-75">
                        {text_nodatafound}
                      </h4>
                      {/* </div> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {selectedTab === 3 ? (
                <>
                  <div className="main-width">
                    <div className=" shareWithMeProfileBox flex-wrap  gap-4">
                      {filteredOldDataItems.length > 0 &&
                        filteredOldDataItems?.map((data, idx) => (
                          <>
                            <div key={idx} className="ShareWithBox">
                              <h6 className="mt-5">{data?.requestername}</h6>
                              <h6>
                                <i>{data?.senderemailorphone}</i>
                              </h6>
                              <h6>
                                {moment(data?.accesstill_startdate).format(
                                  "MMM DD, yyyy"
                                )}{" "}
                                -{" "}
                                {moment(data?.accesstill_enddate).format(
                                  "MMM DD, yyyy"
                                )}{" "}
                              </h6>
                            </div>
                          </>
                        ))}
                    </div>{" "}
                  </div>
                  {filteredOldDataItems.length === 0 && (
                    <>
                      {/* <div className="no-data-sty"> */}
                      <h4 className="text-center mt-5 w-75">
                        {text_nodatafound}
                      </h4>
                      {/* </div> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </main>
  );
}

export default SharedWithme;
