export const customTableStyles = {
  rows: {
    style: {
      fontSize: "16px",
      paddingInlineStart: "5px",
      cursor: "pointer",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "uppercase",
      backgroundColor: "#bfd8ef",
      color: "#303847",
    },
  },
  title: {
    style: {
      overflow: "none",
    },
  },
};
